import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Input, Spin, Modal, message, Button, Popover } from 'antd'
import { CameraOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons'
import Upload from '../Upload'
import listApi from '../../api/listApi'
import { strEncrypt, getDecryptParams } from '../../utils/utils'
import { merchantid } from '../ResInterceptors'
import styles from './index.module.scss'

import MainContext from '../../stores/stores'
import orderApi from '../../api/orderApi'
import accountApi from '../../api/accountApi'
import { Supplement } from '../../pages/Mine/Company'
import Carsearch from '@/pages/Carsearch'
import vinApi from '@/api/epcApi'

interface VinInputProps {
  width?: number | string;
  enterButtonStyle?: React.CSSProperties;
  enterButton?: React.ReactNode;
  cameraButtonStyle?: React.CSSProperties;
  freeVinButtonStyle?: React.CSSProperties;
  size?: 'small' | 'middle' | 'large' | undefined;
  style?: React.CSSProperties;
  classNames?: any;
  placeholder?: string;
  hasFreeVin?: boolean;
  value?: string;
}

const VinInput: React.FC<VinInputProps> = (props) => {
  let intl = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const { value = '', width = 600, style = {}, enterButton = <Button type="primary" icon={<SearchOutlined/>}></Button>, enterButtonStyle = {}, cameraButtonStyle = {}, freeVinButtonStyle = {}, size = "middle", placeholder = intl.formatMessage({id: 'VIN/品牌'}), hasFreeVin = false, classNames = {} } = props

  const { commonStore, setCommonStore } = useContext(MainContext)
  const { isWomala, host, pageType, open_new } = commonStore
  
  const [market, setMarket] = useState<number>(1)
  const [keyword, setKeyword] = useState<string>('')
  const [ocrShow, setOcrShow] = useState<boolean>(false)
  const [historyShow, setHistoryShow] = useState<boolean>(false)
  const [ocrImg, setOcrImg] = useState<string>('')
  const [ocrKey, setOcrKey] = useState<string>('')
  const [uploadLoading, setUploadLoading] = useState<boolean>(false)
  const [vinHistory, setVinHistory] = useState<any[]>([])
  const [moreVinShow, setMoreVinShow] = useState<boolean>(false)
  const [moreVinList, setMoreVinList] = useState<any[]>([])

  const [supplementOpen, setSupplementOpen] = useState<boolean>(false)
  const [isModalConfirm, setIsModalConfirm] = useState<boolean>(false)
  const [companyInfo, setCompanyInfo] = useState<any>({})

  const [brandList, setBrandList] = useState<any[]>([])
  const [brandOpen, setBrandOpen] = useState<boolean>(false)
  const [carInfo, setCarInfo] = useState<any>({})

  const [freeCjhList, setFreeCjhList] = useState<any[]>([])
  const [isGetFreeCjh, setIsGetFreeCjh] = useState<boolean>(false)
  

  useEffect(() => {
    if(localStorage.getItem("token")) {
      getVinHistory()
    }
    getIsCst()
    getBrandList()
  }, [])

  useEffect(() => {
    if(commonStore.userInfo && commonStore.userInfo.repair_shop_id) {
      getCompanyInfo()
    }
  }, [commonStore.userInfo])

  useEffect(() => {
    if(commonStore.pageType === '5' && pathname !== '/cjh') {
      setKeyword('')
    }
  }, [commonStore.pageType, pathname])

  useEffect(() => {
    if(value) {
      setKeyword(value)
    }
  }, [value])

  useEffect(() => {
    getFreeCjhList()
  }, [commonStore.host])

  const getFreeCjhList = () => {
    let domain = window.location.host
    const lang: any  = window.localStorage.getItem('lang')
    accountApi.getHomeDataThree({ 
      domain: commonStore.host || domain,
      lang 
    }).then((res: any) => {
      if(res.code === 1) {
        let data = res.data ? res.data : {}
        let dataVin = data.vin ? data.vin : []
        setFreeCjhList(dataVin)
      }
      setIsGetFreeCjh(true)
    })
  }

  const getIsCst = () => {
    accountApi.getIsCst().then((res: any) => {
      if(res.code === 1) {
        let _market = res.data && res.data.market ? res.data.market : 1
        setMarket(_market)
      }
    })
  }

  const getBrandList = () => {
      accountApi.getSelectBrandList().then((res: any) => {
        if(res.code === 1) {
          let _brandList: any = []
          res.data.cars_brands.all_brands.brands.forEach((code: any) => {
            code.brands.forEach((item: any) => {
              _brandList.push({
                ...item,
                img: item.brand_img
              })
            })
          })
          setBrandList(_brandList)
        }
      })
  }

  const getCompanyInfo = () => {
    orderApi.getCompanyInfo({
      repair_shop_id: commonStore.userInfo && commonStore.userInfo.repair_shop_id ? commonStore.userInfo.repair_shop_id : '',
    }).then((res: any) => {
      if(res.code === 1) {
        setCompanyInfo(res.data)
      }
    })
  }
  const getUserInfo = () => {
    accountApi.getUserInfo().then((res: any) => {
      if(res.code === 1) {
        res.data.company = res.data.reg_company_name
        setCommonStore({value: res.data, type: 'userInfo'})
      }
    })
  }

  
  const getVinHistory = () => {
    listApi.getVinHistory().then((res: any) => {
      if(res.code === 1) {
        setVinHistory(res.data)
      }
    })
  }
  const inputChange = (e: any) => {
    let value = e.target.value
    // value = value.toUpperCase()
    setKeyword(value)
    if(value.length > 0) {
      setHistoryShow(false)
    }
  }
  const searchInput = (value: string) => {
    if(!value) {
      setSearchParams({
        params: ''
      })
      return false
    }
    let isVin = /^[a-zA-Z0-9]{17}$/.test(value)
    let index = brandList.findIndex((ele: any) => (ele.brand_name).toUpperCase() === value.toUpperCase() || (ele.brandCode).toUpperCase() === value.toUpperCase())
    let isBrand = index > -1 ? true : false
    let isPlate = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕青藏宁琼使领][A-Z]([A-Z0-9]{5})$/.test(value)
    setKeyword(value)
    if(value.length === 17 && isVin) {
      let index = freeCjhList.findIndex((ele: any) => ele.vin === value)
      if(index === -1) {
        listApi.searchVinCars({
          vin: value
        }).then((res: any) => {
          if(res.code === 1) {
            setOcrShow(false)
            if(res.data.length) {
              if(res.data.length > 1) {
                setMoreVinList(res.data)
                setMoreVinShow(true)
              }else if(res.data.length === 1) {
                let params = res.data[0]
                let title: any[] = []
                let titleKey = ['brand', 'manufacturers', 'models', 'chassis_code', 'produced_year', 'displacement', 'engine_model', 'transmission_description', 'drive_mode', 'fuel_type']
                titleKey.forEach((ele: string) => {
                  if(params[ele]) {
                    title.push(params[ele])
                  }
                })
                let brandCode = ''
                let brand_img = ''
                if(params.brand) {
                  let index = brandList.findIndex((ele: any) => ele.brand_name === params.brand)
                  if(index !== -1) {
                    let brand = brandList[index]
                    brandCode = brand.brandCode
                    brand_img = brand.brand_img
                  }
                }
                params.market_name = title.join(">")
                if(checkCanRead()) {
                  return true
                }
                let _vinInfo = {
                  ...params,
                  brandCode,
                  brand_img,
                  brand_logo: brand_img,
                  vin: value
                }
                vinApi.setHistory({
                  brandCode: brandCode,
                  query_type: 'vin_query',
                  keyword: `${value},${params.brand}-${params.manufacturers}-${params.models}-${params.produced_year}`
                }).then()
                let _searchParams = strEncrypt(_vinInfo)
                navigate(`/cjh?params=${_searchParams}&vinkey=${Math.random()}`)
              }
            }
          }
          if(res && res.code === 1001) {
            if(!supplementOpen) {
              setSupplementOpen(true)
            }
          }
          if(res && res.code === 1004) {
            if(!isModalConfirm) {
              setIsModalConfirm(true)
              Modal.info({
                title: intl.formatMessage({ id: '正在等待所在公司的审核！' }),
                content: intl.formatMessage({ id: '审核通过后可获得更多商品查询次数' }),
                okText: intl.formatMessage({ id: '知道了' }),
                onOk: () => {
    
                }
              })
            }
          }
        })
      }else {
        let _vinInfo = freeCjhList[index]
        let _title: any[] = []
        let titleKey = ['brand', 'manufacturers', 'models', 'chassis_code', 'produced_year', 'displacement', 'engine_model', 'transmission_description', 'drive_mode', 'fuel_type']
        titleKey.forEach((ele: string) => {
          if(_vinInfo[ele]) {
            _title.push(_vinInfo[ele])
          }
        })
        let brandCode = ''
        let brand_img = ''
        if(_vinInfo.brand) {
          let index = brandList.findIndex((ele: any) => ele.brand_name === _vinInfo.brand)
          if(index !== -1) {
            let brand = brandList[index]
            brandCode = brand.brandCode
            brand_img = brand.brand_img
          }
        }
        _vinInfo = {
          ..._vinInfo,
          market_name: _title.join(">"),
          brandCode,
          brand_img,
          brand_logo: brand_img,
        }
        let _searchParams = strEncrypt(_vinInfo)
        navigate(`/cjh?params=${_searchParams}&vinkey=${Math.random()}`)
      }
    }else if(isBrand) {
      let brandItem = brandList[index]
      let _carInfo = {
        brand: brandItem.brand_name,
        brand_img: brandItem.brand_img,
        brand_logo: brandItem.brand_img,
        brandCode: brandItem.brandCode,
      }
      setCarInfo(_carInfo)
      setBrandOpen(true)
    }else if(isPlate) {

    }else {
      if(market === 2) {
        listApi.getEnPlate({
          vin: value
        }).then((res: any) => {
          if(res.code === 1) {
            if(res.data.length) {
              if(res.data.length > 1) {
                // setMoreVinList(res.data)
                // setMoreVinShow(true)
  
              }else {
                let _data = res.data && res.data[0] ? res.data[0] : {}
                let brandCode = ''
                let brand_img = ''
                let brandName = ''
                if(_data.brand) {
                  let index = brandList.findIndex((ele: any) => ele.brand_name.toUpperCase() === _data.brand.toUpperCase())
                  if(index !== -1) {
                    let brand = brandList[index]
                    brandCode = brand.brandCode
                    brand_img = brand.brand_img
                    brandName = brand.brand_name
                  }
                }
                _data = {
                  ..._data,
                  brandCode,
                  brand_img,
                  brand_logo: brand_img,
                  en_plate: value
                }
                let _title: any[] = []
                let titleKey = ['brand', 'manufacturers', 'models', 'chassis_code', 'produced_year', 'displacement', 'engine_model', 'transmission_description', 'drive_mode', 'fuel_type']
                titleKey.forEach((ele: string) => {
                  if(_data[ele]) {
                    _title.push(_data[ele])
                  }
                })
                _data.market_name = _title.join(">")
                let _searchParams = strEncrypt(_data)
                // navigate(`/vin?params=${_searchParams}&vinkey=${Math.random()}`)
                navigate(`/cjh?params=${_searchParams}&vinkey=${Math.random()}`)
              }
            }
          }
          if(res && res.code === 1001) {
            if(!supplementOpen) {
              setSupplementOpen(true)
            }
          }
          if(res && res.code === 1004) {
            if(!isModalConfirm) {
              setIsModalConfirm(true)
              Modal.info({
                title: intl.formatMessage({ id: '正在等待所在公司的审核！' }),
                content: intl.formatMessage({ id: '审核通过后可获得更多商品查询次数' }),
                okText: intl.formatMessage({ id: '知道了' }),
                onOk: () => {
    
                }
              })
            }
          }
        })
      }else {
        message.warning(intl.formatMessage({id: '请输入车架号或品牌'}))
      }
    }
    
  }
  const checkCanRead = () => {
    if(localStorage.getItem("token")) {
      return false
    }else {
      navigate("/login")
      return true
    }
  }
  const inputFocus = () => {
    setOcrShow(false)
    if(keyword.length === 0) {
      setHistoryShow(true)
    }else {
      setHistoryShow(false)
    }
  }
  const inputBlur = () => {
    let timer = setTimeout(() => {
      setHistoryShow(false)
      clearTimeout(timer)
    }, 1000)
    
  }
  const beforeUpload = (file: File, FileList: File[]) => {
    return new Promise((resolve, reject) => {
      resolve(file)
    })
  }
  const start = (result: any) => {
    setUploadLoading(true)
  }
  const success = (result: any, file: File) => {
    if(result.code === 1){
      const reader: any = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', () => {
        setOcrImg(reader.result)
      })
      setOcrKey(result.data.VIN)
    }
    setUploadLoading(false)
  }
  const error = (result: any) => {
    message.warning('warning')
    setUploadLoading(false)
  }
  const vinItemClick = (item: any) => {
    let _vinInfo = {
      ...item,
      vin: keyword
    }
    let title: any[] = []
    let titleKey = ['brand', 'manufacturers', 'models', 'chassis_code', 'produced_year', 'displacement', 'engine_model', 'transmission_description', 'drive_mode', 'fuel_type']
    titleKey.forEach((ele: string) => {
      if(_vinInfo[ele]) {
        title.push(_vinInfo[ele])
      }
    })
    let brandCode = ''
    let brand_img = ''
    if(_vinInfo.brand) {
      let index = brandList.findIndex((ele: any) => ele.brand_name === _vinInfo.brand)
      if(index !== -1) {
        let brand = brandList[index]
        brandCode = brand.brandCode
        brand_img = brand.brand_img
      }
    }
    _vinInfo.brand_logo = brand_img
    _vinInfo.brand_img = brand_img
    _vinInfo.market_name = title.join(">")
    setMoreVinShow(false)
    let _searchParams = strEncrypt(_vinInfo)
    // navigate(`/vin?params=${_searchParams}&vinkey=${Math.random()}`)
    navigate(`/cjh?params=${_searchParams}&vinkey=${Math.random()}`)
  }
  const freeCjhClick = (item: any) => {
    setKeyword(item.vin)
    let _title: any[] = []
    let titleKey = ['brand', 'manufacturers', 'models', 'chassis_code', 'produced_year', 'displacement', 'engine_model', 'transmission_description', 'drive_mode', 'fuel_type']
    titleKey.forEach((ele: string) => {
      if(item[ele]) {
        _title.push(item[ele])
      }
    })
    let brandCode = ''
    let brand_img = ''
    if(item.brand) {
      let index = brandList.findIndex((ele: any) => ele.brand_name === item.brand)
      if(index !== -1) {
        let brand = brandList[index]
        brandCode = brand.brandCode
        brand_img = brand.brand_img
      }
    }
    item = {
      ...item,
      market_name: _title.join(">"),
      brandCode,
      brand_img,
      brand_logo: brand_img,
    }
    setCarInfo({})
    if(localStorage.getItem('token')) {
      vinApi.setHistory({
        brandCode: brandCode,
        query_type: 'vin_query',
        keyword: `${item.vin},${item.brand}-${item.manufacturers}-${item.models}-${item.produced_year}`
      }).then()
    }
    let _searchParams = strEncrypt(item)
    navigate(`/cjh?params=${_searchParams}&vinkey=${Math.random()}`)
  }
  const freeVinContent = () => {
    return (
      <div className={styles['free-list']}>
        {
          freeCjhList.map((item: any, index: number) => {
            return (
              <div key={index} className={styles['free-cjh-item']} onClick={() => freeCjhClick(item)}>
                <span className={styles['vin']}>{item.vin}</span>
                <span className={styles['title']}>{item.title}</span>
              </div>
            )
          })
        }
      </div>
    )
  }
  return (
    <>
      <div className={styles['head']} style={{width: width}}>
        <div className={styles['input-wrap']}>
          <Input.Search 
            placeholder={placeholder ? placeholder : (market === 2 ? intl.formatMessage({id: 'VIN/品牌/牌照'}) : intl.formatMessage({id: 'VIN/品牌'}))} 
            style={{width: width, ...style}}
            value={keyword} 
            enterButton={
              enterButton ? enterButton : <Button type="primary" icon={<SearchOutlined/>} style={{
                ...enterButtonStyle,
                width: 45
              }}></Button>
            }
            classNames={{
              ...classNames,
            }}
            size={size}
            allowClear
            onChange={inputChange} 
            onSearch={value => searchInput(value)}
            onFocus={inputFocus}
            onBlur={inputBlur}
            onClear={() => searchInput('')}
          />
          <span className={styles['camera']} style={{top: size === 'large' ? '11px' : (size === 'small' ? '3px' : '7px'), color: '#096BED', ...cameraButtonStyle}} onClick={() => setOcrShow(!ocrShow)}>
            <CameraOutlined style={{fontSize: 18}} />
          </span>
          {
            hasFreeVin && freeCjhList.length ? <Popover content={freeVinContent}>
              <span className={styles['free-vin']}  style={{top: size === 'large' ? '8px' : (size === 'small' ? '0px' : '4px'), ...freeVinButtonStyle}}>{intl.formatMessage({id: '体验VIN'})}</span>
            </Popover> : null
          }
          
        </div>
        {
          ocrShow && !historyShow ? <div className={styles['ocr']}>
            <span className={styles['close-ocr']} onClick={() => setOcrShow(false)}>
              <CloseOutlined style={{fontSize: 16, color: '#cccccc'}} />
            </span>
            {
              ocrImg ? (
                <Spin spinning={uploadLoading}>
                    <Upload
                      parse={listApi.ocrImg}
                      setLoading={setUploadLoading}
                      action={listApi.ocrPath}
                      name="file"
                      headers={{
                        merchantid: merchantid,
                        ycid: localStorage.getItem("ycid") || "",
                        token: localStorage.getItem("token") || "",
                        openid: localStorage.getItem("openid") || ''
                      }}
                      beforeUpload={beforeUpload}
                      start={start}
                      success={success}
                      error={error}
                    >
                      <div className={styles['ocrImg']}>
                        <img src={ocrImg} />
                      </div>
                    </Upload>
                    <div className={styles['uploaded']}>
                      <div style={{width: '96%', margin: '0 auto'}}>
                        <Input value={ocrKey} onChange={(e)=>setOcrKey(e.target.value)} />
                      </div>
                      <button onClick={()=>searchInput(ocrKey)}>{intl.formatMessage({id: '搜索'})}</button>
                      <Upload
                        action={listApi.ocrPath}
                        name="file"
                        headers={{
                          merchantid: merchantid,
                          ycid: localStorage.getItem("ycid") || "",
                          token: localStorage.getItem("token") || "",
                          openid: localStorage.getItem("openid") || ''
                        }}
                        beforeUpload={beforeUpload}
                        start={start}
                        success={success}
                        error={error}
                      >
                        <p>{intl.formatMessage({id: '重新上传'})}</p>
                      </Upload>
                    </div>
                </Spin>
              ): (
                <Upload
                  parse={listApi.ocrImg}
                  setLoading={setUploadLoading}
                  loading={uploadLoading}
                  action={listApi.ocrPath}
                  name="file"
                  headers={{
                    merchantid: merchantid,
                    ycid: localStorage.getItem("ycid") || "",
                    token: localStorage.getItem("token") || "",
                    openid: localStorage.getItem("openid") || ''
                  }}
                  beforeUpload={beforeUpload}
                  start={start}
                  success={success}
                  error={error}
                >
                  <div className={styles['upload']}>
                    <span className="iconfont icontop"></span>
                    <p>{intl.formatMessage({id: '粘贴或拖动文件到此区域以进行识别'})}</p>
                    <button>{intl.formatMessage({id: '选择图像'})}</button>
                  </div>
                </Upload>
              )
            }
          </div> : null
        }
        {
          historyShow && vinHistory.length ? <div className={styles['vin-history']}>
            <div className={styles['title']}>{intl.formatMessage({id: '历史记录'})}</div>
            {
              vinHistory.map((item: any, index: number) => {
                return (
                  <div className={styles['item']} key={index} onClick={()=>{setKeyword(item.vin);searchInput(item.vin)}}>
                    <span>{item.vin} - {item.carsinfo}</span>
                  </div>
                )
              })
            }
          </div> : null
        }
      </div>
      <Modal
        open={moreVinShow}
        title={intl.formatMessage({id: '选择要查询的内容'})}
        footer={null}
        maskClosable={false}
        onCancel={() => setMoreVinShow(false)}
      >
        <div className={styles['more-vin']}>
          {
            moreVinList.map((item: any) => {
              return (
                <div className={styles["item"]} key={item.code} onClick={() => vinItemClick(item)}>
                  <div className={styles["txt"]}>{item.manufacturers} </div>
                  <div className={styles["first"]}>
                    <div style={{marginRight: 20}}>
                      <label>{intl.formatMessage({id: '年份'})}：</label>
                      <span>{item.produced_year}</span>
                    </div>
                    <div>
                      <label>{intl.formatMessage({id: '排量'})}：</label>
                      <span>{item.displacement}</span>
                    </div>
                  </div>
                  <div className={styles["other"]}>
                    <label>{intl.formatMessage({id: '变速箱'})}：</label>
                    <span>{item.transmission_description}</span>
                  </div>
                  <div className={styles["other"]}>
                    <label>{intl.formatMessage({id: '底盘'})}/{intl.formatMessage({id: '驱动'})}：</label>
                    <span>{item.drive_mode}</span>
                  </div>
                  <div className={styles["other"]}>
                    <label>{intl.formatMessage({id: '发动机型号'})}：</label>
                    <span>{item.engine_model}</span>
                  </div>
                </div>
              )
            })
          }
        </div>
      </Modal>
      {
        supplementOpen ? (
          <Supplement
            companyInfo={companyInfo}
            onCancel={() => setSupplementOpen(false)}
            onConfirm={() => {getUserInfo()}}
          />
        ): null
      }
      <Modal
        open={brandOpen}
        onCancel={() => setBrandOpen(false)}
        footer={null}
        width={1200}
        wrapClassName={styles['car-brand-search']}
      >
        <Carsearch
          from='vinInput'
          carInfo={carInfo}
          setCarInfo={setCarInfo}
          setOpen={setBrandOpen}
        />
      </Modal>
    </>  
  )
}
export default VinInput