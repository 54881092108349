import React, { Fragment, useEffect, useRef, useState, useContext } from "react";
import styles from './index.module.scss'
import accountApi from "../../api/accountApi";
import orderApi from '../../api/orderApi';
import { Row, Spin, Col, Select, Input, Modal, Button } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { strEncrypt, getDecryptParams } from '../../utils/utils'
import { useIntl } from 'react-intl'
import { Supplement } from '../Mine/Company'
import MainContext from '../../stores/stores'

const Carsearch: React.FC<any> = (props) => {
  const { setCarInfo, setOpen  }  = props
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const intl = useIntl()
  const { commonStore, setCommonStore } = useContext(MainContext)
  const { colorPrimary  } = commonStore
  const [activeAcronym, setActiveAcronym] = useState<string>('all')
  const [brandLoading, setBrandLoading] = useState<boolean>(false)
  const [brandData, setBrandData] = useState<any>({
    acronyms: [],
    brands: []
  })
  const [searchKey, setSearchKey] = useState<any>("")
  const [modelLoading, setModelLoading] = useState<boolean>(false)
  const [activeBrand, setActiveBrand] = useState<any>({})
  


  const [selectType, setSelectType] = useState<any>('')
  const [selectArr, setSelectArr] = useState<any>([])
  const [selectList, setSelectList] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)

  
  const [keyword, setKeyword] = useState<string>("")
  const [supplementOpen, setSupplementOpen] = useState<boolean>(false)
  const [companyInfo, setCompanyInfo] = useState<any>({})
	
	const [passLoading, setPassLoading] = useState<boolean>(false)

  const [isFirst, setIsFirst] = useState<boolean>(false)

  useEffect(() => {
    getSelectBrandList()
  },[])

  useEffect(() => {
    if(commonStore.userInfo && commonStore.userInfo.repair_shop_id) {
      getCompanyInfo()
    }
  }, [commonStore.userInfo])
  useEffect(() => {
    if(props.carInfo && props.carInfo.brandCode && brandData.brands && brandData.brands.length && selectArr.length === 0) {
      brandData.brands.forEach((item: any) => {
        item.brands.forEach((it: any) => {
          if(it.brandCode === props.carInfo.brandCode) {
            setActiveBrand(it)
            setSearchKey(it.key)
          }
        })
      })
    }
  }, [props.carInfo, brandData])

  useEffect(() => {
    if(props.carInfo && props.carInfo.brand && selectArr.length && !isFirst) {
      let carInfoKeys = Object.keys(props.carInfo)
      let carInfoArr = carInfoKeys.filter((key: any) => key !== 'brand_img' && key !== 'cars_id' && key !== 'brandCode' && key !== 'code' && key !== 'brand_logo' && key !== 'market_name')
      let carInfoLength = carInfoArr.length
      let selectArrLength = selectArr.length
      let indexs = selectArr.length - 1
      let selectArrLastItem = selectArr[indexs]
      console.log("selectArrLength:", selectArrLength, "carInfoLength:", carInfoLength, "selectArrLastItem:", selectArrLastItem)
      if((selectArrLength <= carInfoLength - 2 && !selectArrLastItem.title) || selectArrLength < carInfoLength - 3) {
        let selectArrLastList = selectArrLastItem.list
        let _selectType = selectArrLastItem.key
        let carInfoIndex = carInfoKeys.indexOf(_selectType)
        if(carInfoIndex > -1) {
          let selectValue = props.carInfo[_selectType]
          let index = selectArrLastList.findIndex((item: any) => item[_selectType] === selectValue)
          if(index > -1) {
            let item = selectArrLastList[index]
            tonext(item, indexs, false)
          }
        }
      }
    }
  }, [props.carInfo, selectType, isFirst])

  useEffect(() => {
    if(activeBrand.brandCode) {
      if(searchKey) {
        modelSelect()
      }
    }else {
      setSearchKey("")
      setSelectArr([])
      setSelectType("")
    }
  },[activeBrand, searchKey])

  const getCompanyInfo = () => {
    orderApi.getCompanyInfo({
      repair_shop_id: commonStore.userInfo && commonStore.userInfo.repair_shop_id ? commonStore.userInfo.repair_shop_id : '',
    }).then((res: any) => {
      if(res.code === 1) {
        setCompanyInfo(res.data)
      }
    })
  }
  const getUserInfo = () => {
    accountApi.getUserInfo().then((res: any) => {
      if(res.code === 1) {
        res.data.company = res.data.reg_company_name
        setCommonStore({value: res.data, type: 'userInfo'})
      }
    })
  }

  const getSelectBrandList = () => {
    setBrandLoading(true)
    accountApi.getSelectBrandList().then((res: any) => {
      setBrandLoading(false)
      if(res.code === 1) {
        setBrandData(res.data.cars_brands.all_brands)
      }
    })
  }

  const modelSelect = () => {

    let params = {
      brand: activeBrand.brand_name,
    }
    selectArr.forEach((item: any) => {
      if(item.params) {
        params = {...params, ...item.params}
      }
    })
    if(selectType === 'brand') {
      setLoading(true)
    }
    accountApi.modelSelect({ key: searchKey }).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        let merge_list = res.merge_list
        let key = res.fields.key
        setSelectType(key)
        res.data.forEach((item: any) => {
          if(merge_list.length) {
            let otherKey = merge_list.filter((it: any) => it !== key)[0]
            item.title = item[key] + `${item[otherKey] ? '(' + item[otherKey] + ')' : ''}`
            item.otherKey = otherKey
            item.otherValue = item[otherKey]
          }else {
            item.title = item[key]
          }
        })
        setSelectList(res.data)

        let _selectArr = JSON.parse(JSON.stringify(selectArr))
        _selectArr.push({
          selectTitle: res.fields.values,
          list: res.data || [],
          key
        })
        setSelectArr(_selectArr)
      }
      if(res.code === 201) {
        if(setOpen) {
          setOpen(false)
        }
      }
      if(res.code === 1001) {
        setSupplementOpen(true)
      }
      if(res.code === 1004) {
        Modal.info({
          title: intl.formatMessage({ id: '正在等待所在公司的审核！' }),
          content: intl.formatMessage({ id: '审核通过后可获得更多商品查询次数' }),
          okText: intl.formatMessage({ id: '知道了' }),
          onOk: () => {

          }
        })
      }
    })
  }

  const selectBrand = (brand: any) => {
    setActiveBrand(brand); 
    setSearchKey(brand.key)
    setSelectType('brand')
  }

  const tonext = (item: any, indexs: number, canClose: boolean) => {
    let _selectArr: any = JSON.parse(JSON.stringify(selectArr))
    if(!item.code) {
      if(_selectArr.length === indexs + 1) {
        let _selectArr = JSON.parse(JSON.stringify(selectArr))
        let otherKeyPramas = item['otherKey'] ? {
          [item['otherKey']]: item[item['otherKey']] || undefined
        } : {}
        _selectArr[_selectArr.length - 1] = {
          ..._selectArr[_selectArr.length - 1], 
          title: item[selectType] + `${item[item['otherKey']] ? '(' + item[item['otherKey']] + ')' : ''}`,
          val: item[selectType],
          key: selectType,
          searchKey: item.key,
          params: {
            [selectType]: item[selectType],
            ...otherKeyPramas
          }
        }
        setSelectArr(_selectArr)
        setSearchKey(item.key)
      }else {
        if(selectArr[indexs].title === item.title) return false
        let _selectArr: any = JSON.parse(JSON.stringify(selectArr))
        _selectArr = _selectArr.slice(0,indexs+1)
        let _selectType = _selectArr[_selectArr.length - 1].key
        setIsFirst(true)
        setSelectType(_selectType)
        let otherKeyPramas = item['otherKey'] ? {
          [item['otherKey']]: item[item['otherKey']] || undefined
        } : {}
        _selectArr[_selectArr.length - 1] = {
          ..._selectArr[_selectArr.length - 1], 
          title: item[_selectType] + `${item[item['otherKey']] ? '(' + item[item['otherKey']] + ')' : ''}`,
          val: item[_selectType],
          key: _selectType,
          searchKey: item.key,
          params: {
            [_selectType]: item[_selectType],
            ...otherKeyPramas
          }
        }
        setSelectArr(_selectArr)
        setSearchKey(item.key)
      }
      return false
    }
    let title: any = []
    let params: any = {
      cars_id: (item.code || []).join(","),
      brand: activeBrand.brand_name,
      brand_logo: activeBrand.brand_img,
      brand_img: activeBrand.brand_img,
      brandCode: activeBrand.brandCode,
      code: item.code || []
    }
    selectArr.forEach((it: any) => {
      // params[it.key] = it.title
      params = {
        ...params,
        ...it.params
      }
    })
    if(setCarInfo){
      let _selectArr = JSON.parse(JSON.stringify(selectArr))
      let _selectType = _selectArr[_selectArr.length - 1].key
      let otherKeyPramas = item['otherKey'] ? {
        [item['otherKey']]: item[item['otherKey']] || undefined
      } : {}
      _selectArr[_selectArr.length - 1] = {
        ..._selectArr[_selectArr.length - 1], 
        title: item[_selectType] + `${item[item['otherKey']] ? '(' + item[item['otherKey']] + ')' : ''}`,
        val: item[_selectType],
        key: _selectType,
        searchKey: item.key,
        params: {
          [_selectType]: item[_selectType],
          ...otherKeyPramas
        }
      }
      setSelectArr(_selectArr)
      console.log("selectType:", selectType, 'item[selectType]:', item[selectType])
      params = {...params, [selectType]: item[selectType]}
      let titleKey = ['brand', 'manufacturers', 'models', 'chassis_code', 'produced_year', 'displacement', 'engine_model', 'transmission_description', 'drive_mode', 'fuel_type']
      titleKey.forEach((ele: string) => {
        if(params[ele]) {
          title.push(params[ele])
        }
      })
      let car_info = {
        ...params,
        market_name: title.join('>'),
      }
      if(props.from && props.from === 'list') {
        setCarInfo(car_info)
        if(canClose) {
          setOpen(false)
        }
        setSearchParams({
          params: strEncrypt(car_info)
        })
      }else if(props.from && props.from === 'vinInput') {
        console.log("car_info:", car_info)
        setCarInfo(car_info)
        if(canClose) {
          setOpen(false)
        }
        let url = `/cjh?params=${strEncrypt(car_info)}&vinkey=${new Date().getTime()}`
        navigate(url)
      }else {
        setCarInfo(car_info)
        if(canClose) {
          setOpen(false)
        }
      }
    }else {
      params = {...params, [selectType]: item[selectType]}
      let titleKey = ['brand', 'manufacturers', 'models', 'chassis_code', 'produced_year', 'displacement', 'engine_model', 'transmission_description', 'drive_mode', 'fuel_type']
      titleKey.forEach((ele: string) => {
        if(params[ele]) {
          title.push(params[ele])
        }
      })
      let car_info = {
        ...params,
        market_name: title.join('>'),
      }
      console.log("car_info params:", car_info)
      let url = `/carsearch/list?params=${strEncrypt(car_info)}`
      navigate(url)
    }
    
  }
	
	const pass = () => {
		setPassLoading(true)
		accountApi.modelSelect({key: searchKey, is_end: 1}).then((res: any) => {
			setPassLoading(false)
			if(res.code === 1) {
				let code: any = []
        let title: any = []
				res.data.forEach((item: any) => {
					code = [...code,...item.code]
				})
				let params: any = {
				  cars_id: (code || []).join(","),
				  brand: activeBrand.brand_name,
          brand_img: activeBrand.brand_img,
          brand_logo: activeBrand.brand_img,
          brandCode: activeBrand.brandCode,
          code: code || []
				}
				selectArr.forEach((it: any) => {
					if(it.title) {
						// params[it.key] = it.title
            params = {
              ...params,
              ...it.params
            }
					}
				})
        let titleKey = ['brand', 'manufacturers', 'models', 'chassis_code', 'produced_year', 'displacement', 'engine_model', 'transmission_description', 'drive_mode', 'fuel_type']
        titleKey.forEach((ele: string) => {
          if(params[ele]) {
            title.push(params[ele])
          }
        })
        let car_info = {
          ...params,
          market_name: title.join('>'),
        }
        if(setCarInfo){
          if(props.from && props.from === 'list') {
            setCarInfo(car_info)
            setOpen(false)
            setSearchParams({
              params: strEncrypt(car_info)
            })
          }else if(props.from && props.from === 'vinInput') {
            setCarInfo(car_info)
            setOpen(false)
            let url = `/cjh?params=${strEncrypt(car_info)}&vinkey=${new Date().getTime()}`
            navigate(url)
          }else {
            setCarInfo(car_info)
            setOpen(false)
          }
        }else {
          let url = `/carsearch/list?params=${strEncrypt(car_info)}`
          navigate(url)
        }
			} 
		})
	}

  return (
    <div className="flex-c-c">
      <div className="ptb10" style={{ width: setCarInfo ? '100%' : '90%', overflowX: 'scroll' }}>
        <div className={styles['carsearch'] +  ' plr10'} >
          {
            !activeBrand.brandCode ? (
              <Spin spinning={brandLoading} indicator={<img src="https://cdns.007vin.com/img/autorepair/cst/car_loading.gif"/>} wrapperClassName={styles['list-spin']}>  
                <div className={styles['acronyms'] + ' flex-s-c ptb10'}>
                  <span className="mr5">{intl.formatMessage({ id: '品牌' })}: </span>
                  {
                    ['all', ...brandData.acronyms].map((acronym: string) => {
                      return (<span onClick={() => setActiveAcronym(acronym)} className={styles['acronym'] + " " + `${activeAcronym === acronym ? styles['active'] : ''}`}>{acronym.toUpperCase()}</span>)
                    })
                  }
                </div>
                
                <div className={styles['brand-list']}>
                  {
                    brandData.brands.filter((_item: any) => (_item.acronyms === activeAcronym || activeAcronym === 'all')).map((item: any, index: number) => {
                      return item.brands.map((brand: any, i: number) => {
                        return (
                          <div key={'' + index + i} className={styles['brand']} onClick={() => {selectBrand(brand)}}>
                            <img src={brand.brand_img} alt="" />
                            <span>{brand.brand_name}</span>
                          </div>
                        )
                      })
                    })
                  }
                </div>
                </Spin>
              ): (
                <div className={styles['select']}>
                  <div className={styles['select-data'] + ' flex-s-c ptb5'}>
                    <img className="pointer" src={activeBrand.brand_img} onClick={() => setActiveBrand({})} />
                    <span className="mr5 pointer" onClick={() => setActiveBrand({})}>{intl.formatMessage({ id: '品牌' })}：{activeBrand.brand_name}</span>
                    {
                      selectArr.filter((it: any) => it.title).map((item: any, index: number) => {
                        return <div key={index} ><span className="mlr5" >&gt;</span><span>{item.title}</span></div>
                      })
                    }
                    <div className={styles['pass'] + ' flex-s-c'}>
                      {
                        selectArr.length > 0 ? <Button size="small" style={{marginRight: 5}} onClick={() => setActiveBrand({})}>{intl.formatMessage({id: '重置'})}</Button> : null
                      }
                      {
                        selectArr.length > 1 ? (
                          <Button loading={passLoading} size="small" onClick={() => pass()}>{intl.formatMessage(( {id: '跳过' } ))}</Button>
                        ): null
                      }
                    </div>
                    
                  </div>
                  <Spin spinning={loading} indicator={<img src="https://cdns.007vin.com/img/autorepair/cst/car_loading.gif"/>} wrapperClassName={styles['list-spin']}>
                    <div className={styles['select-option'] + ' flex-s-c'}>
                        {
                          selectArr.map((items: any, indexs: number) => {
                            return (
                              <div className="mr10" style={{ width: 220 }} key={indexs}>
                                <div className={styles['title']}>{items.selectTitle}</div>
                                {
                                  items.key === 'models' ? (
                                    <Input.Search style={{ height: 30 }} allowClear placeholder={intl.formatMessage({ id: '搜索' })}  onSearch={(value) => setKeyword(value)} />
                                  ): null
                                }
                                <div className={styles['list']} style={{ overflow: 'scroll', height: `calc(100vh - ${items.key === 'models' ? 270 : 240}px)` }}>
                                  
                                  {
                                    (items.list || []).map((item: any, index: number) => {
                                      if(items.key !== 'models' || (keyword && item.title.toLocaleUpperCase().includes(keyword.toLocaleUpperCase())) || !keyword) {
                                        return (
                                          <div className={styles['item'] + " " + `${items.title === item.title ? styles['active'] : ''}`} style={{background: items.title === item.title ? colorPrimary : '#fff'}} key={index} onClick={() => tonext(item, indexs, true)}>
                                            {item.title}
                                          </div>
                                        )
                                      }
                                    })
                                  }
                                </div>
                              </div>
                            )
                          })
                        }
                    </div>
                  </Spin>
                </div>
              )
            }
        </div>
      </div>
      {
        supplementOpen ? (
          <Supplement
            companyInfo={companyInfo}
            onCancel={() => setSupplementOpen(false)}
            onConfirm={() => {getUserInfo()}}
          />
        ): null
      }
    </div>
    
  )
}

export default Carsearch