const ara = {
  locale: 'ar_EG',
  messages: {
    "1周内容": "Weekly Content",
    "1月": "January",
    "1月内": "Within a Month",
    "2": "Two",
    "2月": "February",
    "3天内": "Within 3 days",
    "3月": "March",
    "4S价": "4S Price",
    "4S价格": "4S Price",
    "4月": "April",
    "5月": "May",
    "6月": "June",
    "7月": "July",
    "8月": "August",
    "9月": "September",
    "10月": "October",
    "11月": "November",
    "12月": "December",
    "100%正品，假一赔三": "100% Genuine, False a Compensate Three",
    "123": "One hundred and twenty-three",
    "2023年上半年我们的品牌WOMALA丰富了客户未来的选择。 我们致力于成为国际认可的行业领先品牌": "In the first half of 2023, our brand WOMALA enriched our customers' future choices. We are committed to becoming an internationally recognized industry leading brand",
    "2023年暂无业绩考核": "There Will be no Performance Assessment in 2023",
    "11111": "Eleven thousand one hundred and eleven",
    "111111": "One hundred and eleven thousand one hundred and eleven",
    " 订单来了": "The Order Has Arrived.",
    "(含税)": "(Tax Included)",
    "(选填)公司地址": "(Optional) Company Address",
    "(选填)公司电话": "(Optional) Company Phone Number",
    "(选填)开户银行": "(Optional) Account bank",
    "(选填)请输入公司地址": "(Optional) Please Enter Your Company Address",
    "(选填)请输入公司电话": "(Optional) Please Enter Your Company Phone Number",
    "(选填)请输入开户银行": "(Optional) Please Enter the Account Opening Bank",
    "(选填)请输入银行账户": "(Optional) Please Enter Your Bank Account",
    "(选填)银行账户": "(Optional) Bank Account",
    "*修改此模版下所有商品！": "*Modify All Products Under This Template!",
    "*所有新增客户挂帐额度，将默认为此金额！": "*All Newly Added Customer Credit Limits Will Be Defaulted to This Amount!",
    "*根据客户关联的价格模版，使用对应报价模版；若没有关联则使用系统默认模版。": "*According to the price template associated with the customer, use the corresponding quotation template; If there is no association, use the system default template.",
    "APP": "APP",
    "EPC查询": "EPC Query",
    "ERP产品名称": "ERP Product Name",
    "ERP产品编码": "ERP Product Code",
    "ERP价格": "ERP Price",
    "ERP价格￥": "ERP Price￥",
    "ERP品质": "ERP Quality",
    "ERP库存": "ERP Inventory",
    "ERP开单": "ERP Billing",
    "ERP系统用户名错误将无法开单！": "ERP System User Name Error Will Not Be Able to Bill!",
    "Logo图片": "Logo Image",
    "OE号": "OE Number",
    "OE号搜索": "OE Number Search",
    "OE搜索": "OE Search",
    "OSSCA1993年开始进入汽配行业，2000年正式注册OSSCA商标，服务全球售后市场，产品以严苛的标准，卓越的品质在 100 多个国家广泛流通。主营Audi、Benz、BMW、路虎等欧系豪华车零部件，目前涵盖 100多个产品系列，11万余种产品型号，所有 OSSCA产品均提供 12-24个月质保。我们将继续秉承专业、全面、放心的服务理念打造 OSSCA品牌，致力于成为全球汽车后市场的一站式卓越品牌。": "OSSCA entered the automotive parts industry in 1993 and officially registered the OSSCA trademark in 2000, serving the global after-sales market. Our products are widely circulated in over 100 countries with strict standards and excellent quality. We specialize in European luxury car components such as Audi, Benz, BMW, and Land Rover. Currently, we cover over 100 product series and over 110000 product models. All OSSCA products come with a 12-24 month warranty. We will continue to uphold the professional, comprehensive, and reliable service concept to build the OSSCA brand, committed to becoming a one-stop excellent brand in the global automotive aftermarket.",
    "OSSCA1993年开始进入汽配行业，2000年正式注册OSSCA商标，服务全球售后市场，产品以严苛的标准，卓越的品质在 100 多个国家广泛流通。主营奥迪、奔驰、宝马、路虎等欧系豪华车零部件，目前涵盖 100多个产品系列，11万余种产品型号，所有 OSSCA产品均提供 12-24个月质保。我们将继续秉承专业、全面、放心的服务理念打造 OSSCA品牌，致力于成为全球汽车后市场的一站式卓越品牌。": "OSSCA entered the automotive parts industry in 1993 and officially registered the OSSCA trademark in 2000, serving the global after-sales market. Our products are widely circulated in over 100 countries with strict standards and excellent quality. We specialize in European luxury car components such as Audi, Mercedes Benz, BMW, Land Rover, etc. Currently, we cover over 100 product series and over 110000 product models. All OSSCA products come with a 12-24 month warranty. We will continue to uphold the professional, comprehensive, and reliable service concept to build the OSSCA brand, committed to becoming a one-stop excellent brand in the global automotive aftermarket.",
    "OSSCA：以卓越品质服务全球售后市场": "OSSCA: Serves the global aftermarket with superior quality",
    "SKU数量": "SKU Quantity",
    "VIN查询": "VIN Query",
    "VIN查询2": "VIN Query 2",
    "VIN码识别": "VIN Code Recognition",
    "Vin查询": "Vin Query",
    "WEB商城": "WEB Mall",
    "apple": "Apple",
    "h5": "H5",
    "hello": "Hello",
    "logo": "Logo",
    "logo图片": "Logo Image",
    "x 付款": "x Payment",
    "x秒后自动跳转我的订单": "Automatically Redirect My Order After X Seconds",
    "{name}秒后自动跳转我的订单": "Automatically Redirect My Order After {name} Seconds",
    "《用户注册协议》": "User Registration Agreement",
    "《用户隐私协议》": "User Privacy Agreement",
    "一线品牌": "First-Line Brand",
    "万": "Ten Thousand",
    "上下架": "This Product Has Already Hit the Store Shelves and This Product Will No Longer Be Sold.",
    "上下架编辑": "Edit: This Product Has Already Hit the Store Shelves and This Product Will No Longer Be Sold.",
    "上传": "Upload",
    "上传公司名片": "Upload Company Business Card",
    "上传发货凭证": "Upload Shipping Voucher",
    "上传图片大小必须小于2MB!": "The Size of the Uploaded Image Must Be Less Than 2MB!",
    "上传图片大小必须小于 2MB!": "The Size of the Uploaded Image Must Be Less Than 2MB!",
    "上传失败！": "Upload Failed!",
    "上传收货凭证": "Upload Receipt Voucher",
    "上传清晰照片，可以自动识别并填充相关信息！": "Upload Clear Photos and Automatically Recognize and Fill in Relevant Information!",
    "上传营业执照": "Upload Business License",
    "上传营业执照，可以自动识别并填充相关信息！": "Upload the Business License, Which Can Automatically Identify and Fill in Relevant Information!",
    "上架": "Listing",
    "上涨": "Rise",
    "上询列表": "Inquiry List",
    "下单时间": "Order Time",
    "下架": "Remove From Shelves",
    "下载": "Download",
    "下降": "Delist",
    "不分享": "Do Not Share",
    "不分享商品": "Non-Sharing of Products",
    "不删": "Undelete",
    "不含税总价": "Total Price Excluding Tax",
    "不执行": "Not Executed",
    "不锁": "Not Locked",
    "专业的汽配商城": "Professional Auto Parts Mall",
    "专业的汽配查询工具": "Professional Auto Parts Query Tool",
    "专注于研发和销售高性能的汽车零部件": "Focus on the Development and Sales of High-Performance Automotive Parts",
    "专用发票": "Special Invoice",
    "专票": "Special Ticket",
    "业绩考核": "Performance Appraisal",
    "个人": "Personal",
    "中文": "Chinese",
    "丰田专区": "Toyota Zone",
    "主组": "Main Group",
    "买家备注": "Buyer's Remarks",
    "二级分类": "Secondary Classification",
    "二维码登录": "QR Code Sign in",
    "交易流水号": "Transaction Serial Number",
    "产品供应稳定，品类齐全，更专业的服务团队": "Stable Product Supply, Complete Product Categories, and a More Professional Service Team",
    "产品名称": "Product Name",
    "产品编码": "Product Code",
    "产地": "Place of Origin",
    "产地/品质名称": "Origin/Quality Name",
    "产地不唯一，请先选择产地": "The Origin Is Not Unique, Please Select the Origin First",
    "人员": "Personnel",
    "人工审核": "Manual Audit",
    "人民币": "RMB",
    "人民币大写": "Chinese Yuan in Capital Letters",
    "仅退款": "Refund Only",
    "今日": "Today",
    "仓位": "Shipping Space",
    "仓库": "Warehouse",
    "付款": "Payment",
    "付款时间": "Payment Time",
    "以下产品已存在，是否覆盖现有产品？": "The Following Products Already Exist, Do They Cover the Existing Products?",
    "以卓越品质服务全球售后市场": "Serving the Global After-Sales Market with Excellent Quality",
    "件数": "Number of Pieces",
    "价格模板(可多选)": "Price Template (Multiple Options)",
    "价格模版": "Price Template",
    "价格由低到高": "Price From Low to High",
    "价格由高到低": "Price From High to Low",
    "价格设置": "Price Setting",
    "企业微信": "WeCom",
    "企业认证": "Enterprise Certification",
    "会员价格": "Membership Price",
    "传动系统": "Drive System",
    "传扶": "Chuanfu",
    "传真": "Facsimile",
    "你们所有的货物在交货前都要检验吗？": "Do You Inspect All Your Goods Before Delivery?",
    "你们的交货时间怎么样？": "How Is Your Delivery Time?",
    "你们的样本政策是什么？": "What Is Your Sample Policy?",
    "你们的质量保证期是多久？": "How Long Is Your Quality Assurance Period?",
    "你们能根据样品生产吗？": "Can You Produce Based on Samples?",
    "你只可以上传jpeg、jpg、png格式!": "You Can Only Upload in JPEG, JPG, and PNG Formats!",
    "你可以上传 JPG/PNG 文件!": "You Can Upload JPG/PNG Files!",
    "你可以上传JPG/PNG文件!": "You can upload JPG/PNG files!",
    "使用零零汽账号登录": "Sign In with Your Zero Zero Seven Account",
    "供应品牌": "Supply Brand",
    "供应商": "Supplier",
    "供应商名称": "Supplier Name",
    "供应商状态": "Supplier Status",
    "供应商管理": "Supplier Management",
    "供应商类型": "Supplier Type",
    "供应商详细地址": "Supplier's Detailed Address",
    "俄文": "Russian",
    "保存": "Save",
    "保险公司": "Insurance Company",
    "修改": "Edit",
    "修改价格模版": "Modify Price Template",
    "修改信息": "Modifying Information",
    "修改公司信息": "Modifying Company Information",
    "修改发票": "Revise The Invoice",
    "修改地址": "Modify Address",
    "修改成功": "Modified Successfully",
    "修改成功！": "Modified Successfully!",
    "修改标签": "Modify Tags",
    "修理厂": "Repair Shop",
    "偏好设置": "Preference Settings",
    "催收单": "Collection Note",
    "催收文本": "Collection Text",
    "免费注册": "Free Registration",
    "全选": "Select All",
    "全部": "All",
    "全部定价商品": "All Priced Products",
    "公司": "Company",
    "公司名片": "Company Business Card",
    "公司名称": "Company",
    "公司注册城市": "Company Registration City",
    "公司税号": "Company Tax Number",
    "公司管理": "Company Management",
    "公司类型": "Company Type",
    "公司详细地址": "Company Detailed Address",
    "公司：": "Company",
    "共x个": "X in Total",
    "关于我们": "About Us",
    "关注微信公众号": "Follow WeChat Official Account",
    "关联商品": "Related Products",
    "关闭": "Close",
    "其他": "Other",
    "其他方式登录": "Sign In by Other Means",
    "其它照片": "Other Photos",
    "内贸团队": "Domestic Trade Team",
    "写跟进": "Write Follow-Up",
    "冷却单元": "Cooling Unit",
    "冷却系统": "Cooling System",
    "净重": "Net Weight",
    "减价": "Price Reduction",
    "出库数": "Outbound Quantity",
    "分享": "Share",
    "分享商品": "Share Products",
    "分享状态": "Share Status",
    "分公司": "Subsidiary Company",
    "分类": "Classification",
    "分组": "Grouping",
    "分配": "Distribution",
    "分配单": "Distribution Sheet",
    "分销商管理": "Distributor Management",
    "切换公司": "Switching Companies",
    "列表": "List",
    "创建人": "Creator",
    "创建时间": "Creation Time",
    "删": "Delete",
    "删除": "Delete",
    "删除商品": "Delete Product",
    "删除商品?": "Delete Product?",
    "删除成功": "Delete Successful",
    "删除成功！": "Delete Successful!",
    "删除订单": "Delete Order",
    "删除记录": "Delete Record",
    "制动主缸类": "Brake Master Cylinder Type",
    "制动盘类": "Brake Disc Type",
    "制动系统": "Braking System",
    "制动轮缸类": "Brake Wheel Cylinder Type",
    "制单": "Making Orders",
    "制单日期": "Date of Bill Making",
    "刹车": "Brake",
    "前往处理": "Go and Handle",
    "功能": "Features",
    "加价": "Price Increase",
    "加入时间": "Date Added",
    "加入购物车": "Add to Cart",
    "加入购物车成功": "Added to Cart Successfully",
    "助力器总成类": "Booster Assembly Category",
    "勾选即表示同意": "Check to Indicate Agreement",
    "包装员": "Packer",
    "包裹": "Package",
    "包裹x": "Package x",
    "北京时间": "Beijing Time",
    "华东仓": "Huadong Warehouse",
    "单价": "Unit Price",
    "单位地址": "Unit Address",
    "单号": "Order Number",
    "单据列表": "List of Documents",
    "博士": "Doctor",
    "卡钳带转向节总成类": "Caliper with Steering Knuckle Assembly Category",
    "卡钳总成类": "Caliper Assembly Category",
    "危险品": "Hazardous Materials",
    "厂商": "Manufacturer",
    "历史记录": "History",
    "原厂OE": "Original OE",
    "原厂OE号": "Original Factory OE Number",
    "原厂品质": "Original Factory Quality",
    "原厂大库": "Original Factory Warehouse",
    "原厂替换件": "Original Replacement Parts",
    "原厂订货": "Original Factory Ordering",
    "去查看": "Go Check",
    "去看看": "Go Take a Look",
    "去确认": "Go Confirm",
    "去认证": "Go for Authentication",
    "参加广交会。": "Participate in the Canton Fair.",
    "参数": "Parameter",
    "参考价格": "Reference Price",
    "反馈": "Feedback",
    "反馈内容": "Feedback Content",
    "反馈列表": "Feedback List",
    "发": "Send",
    "发动机": "Engine",
    "发动机型号": "Engine Model",
    "发动机查询": "Engine Query",
    "发布询价": "Publish Inquiry",
    "发短信": "Send Message",
    "发短信，将发送到公司管理员": "Send a Text Message to the Company Administrator",
    "发票": "Invoice",
    "发票管理": "Control Over Invoices",
    "发票类型": "Invoice",
    "发货": "Shipment",
    "发货信息": "Shipping Information",
    "发货凭证": "Shipping Certificate",
    "发货单信息": "shipping Order Information",
    "发货员": "Shipper",
    "发货地址：": "Shipping Address",
    "发货成功！": "Shipment Successful!",
    "发货时间": "shipping Time",
    "发送短信": "Send SMS",
    "取消": "Cancel",
    "取消售后": "Cancel After-Sales Service",
    "取消时间": "Cancellation Time",
    "取消申请": "Cancel Application",
    "取消申请，订单将恢复正常！": "Cancel the Application, the Order Will Return to Normal!",
    "变更": "Change",
    "变速箱": "Transmission",
    "变速箱类型": "Transmission Type",
    "可上传多张，每次1张，大小不超过15M": "Multiple Images Can Be Uploaded, One at a Time, with a Size Not Exceeding 15MB",
    "可以，我们可以根据您的样品或技术图纸进行生产。我们可以做模具和固定装置。": "Sure, We Can Produce According to Your Samples or Technical Drawings. We Can Make Molds and Fixing Devices.",
    "可以，我们接受贴牌生产。": "Sure, We Accept OEM Production.",
    "号码": "Number",
    "合 计": "Total",
    "合作意向": "Intention to Cooperate",
    "合作电话": "Contact Phone for Cooperation",
    "合计": "Total",
    "合资车系": "Joint Venture Vehicle Series",
    "同意": "Agree",
    "同意/拒绝": "Agree/Reject",
    "名称": "Name",
    "名称/编码/OE": "Name/Code/OE",
    "否": "No",
    "含税": "Tax Included",
    "含税价": "Tax Inclusive Price",
    "启用": "Enable",
    "员工": "Employee",
    "和": "and",
    "品牌": "Brand",
    "品牌专区": "Brand Zone",
    "品牌编号": "Brand Number",
    "品类": "Category",
    "品类分类": "Category Classification",
    "品质": "Quality",
    "品质(含税价)": "Quality (Tax-Inclusive Price)",
    "品质/品牌": "Quality/Brand",
    "品质质保": "Quality Assurance",
    "品质质保管理": "Quality Assurance Management",
    "售价": "Price",
    "售后件数": "Number of After-Sales Items",
    "售后单号": "after-sales order number",
    "售后原因": "After Sales Reasons",
    "售后图片": "After Sales Pictures",
    "售后数量": "After Sales Quantity",
    "售后服务": "After-Sale Service",
    "售后类型": "After Sales Type",
    "售后详情": "After Sales Details",
    "售后说明": "After Sales Instructions",
    "售后退款": "After Sales Refund",
    "商品": "Commodity",
    "商品信息": "Product Information",
    "商品名称": "Product Name",
    "商品名称/商品编码": "Product Name/Product Code",
    "商品品质": "Product Quality",
    "商品性质": "Product Nature",
    "商品数量": "Product Quantity",
    "商品管理": "Product Management",
    "商品编号": "Product Code",
    "商品编码": "Product Code",
    "商品编辑成功！": "Product Edited Successfully!",
    "商品评价": "Product Review",
    "商城": "Shopping Mall",
    "商城审核": "Mall Review",
    "商城首页": "Homepage",
    "商家同意后，系统将退款给您": "After the Merchant Agrees, the System Will Refund You",
    "商家已同意": "Merchant Has Agreed",
    "商家已同意，钱款原路退回": "The Merchant Has Agreed to Return the Money to the Original Way",
    "商家已同意，钱款原路退回！": "The Merchant Has Agreed to Return the Money to the Original Way!",
    "商家已拒绝": "Merchant Has Rejected",
    "商家已拒绝，请联系商家协商！": "The Merchant Has Refused, Please Contact the Merchant for Negotiation!",
    "商家操作时间": "Merchant Operation Time",
    "商户": "Merchant",
    "商户名称": "Merchant Name",
    "国产车系": "Domestic Car Series",
    "图片": "Picture",
    "图片/视频": "Picture/Video",
    "图片大小不大于 2MB!": "The Image Size Should Not Exceed 2MB!",
    "图片设置成功！": "Image Set Successfully!",
    "在线库存": "Online Inventory",
    "在线支付": "Online Payment",
    "地址": "Address",
    "地址信息": "Address Information",
    "地址：": "Address",
    "型号": "Model",
    "城市": "City",
    "基本信息": "Basic Information",
    "基础模版": "Basic Template",
    "填写地址与收货人信息": "Fill In Address and Recipient Information",
    "增值税专用发票点数": "Value-Added Tax Invoice Points",
    "备注": "Note",
    "备注信息": "Note Information",
    "复制": "Copy",
    "复制成功！": "Copy Successful!",
    "外地代收": "Out of Town Collection",
    "外询统计": "External Inquiry Statistics",
    "外贸团队": "Foreign Trade Team",
    "大牌正品": "Authentic Branded Products",
    "头像": "Avatar",
    "奔驰": "Benz",
    "奔驰专区": "Mercedes-Benz Zone",
    "奥迪": "Audi",
    "奥迪专区": "Audi Zone",
    "如果有现货，我们可以提供样品，但是客户需要支付样品费和快递费。": "If there is stock available, we can provide samples, but the customer needs to pay for the sample fee and courier fee.",
    "姓名": "Name",
    "威克特": "Wickett",
    "威可特": "Wickett",
    "完成率": "Completion Rate",
    "完成金额 (万)": "Completed Amount (In 10000)",
    "完成金额(万)": "Completed Amount (In 10000)",
    "官网": "Official Website",
    "定价": "Pricing",
    "定价列表": "Pricing List",
    "定价规则": "Pricing Rules",
    "宝马": "BMW",
    "宝马专区": "BMW Zone",
    "实付金额": "Actual Payment Amount",
    "实到货款": "Actual Payment Received",
    "实到采购款": "Actual Purchase Payment Received",
    "实退": "Actual Refund",
    "实退金额": "Actual Refund Amount",
    "审核": "Review",
    "审核中": "Under Review",
    "审核状态": "Review Status",
    "客户": "Customer",
    "客户ID": "Customer ID",
    "客户名称": "Customer Name",
    "客户备注": "Customer Remarks",
    "客户审核待通过": "Customer Review Pending Approval",
    "客户审核被拒绝": "Customer Review Rejected",
    "客户状态": "Customer Status",
    "客户管理": "Customer Management",
    "客户类型": "Customer Type",
    "客户详细地址": "Customer Detailed Address",
    "客服": "Customer Service",
    "宽": "Wide",
    "密码": "Password",
    "密码登录": "Password Sign In",
    "密码输入不一致！": "Password Input Is Inconsistent!",
    "对账单": "Reconciliation Statement",
    "导出": "Export",
    "小程序": "Applet",
    "小程序商城": "Applet Store",
    "小计": "Subtotal",
    "展开": "Unfold",
    "属性": "Attribute",
    "岗位": "Position",
    "工作时间": "Working Hours",
    "工程编号": "Project Number",
    "已上架": "On Sale",
    "已下架": "Removed",
    "已关联": "Associated",
    "已删除": "Deleted",
    "已发货": "Shipped",
    "已取消": "Cancelled",
    "已取消售后申请！": "The After-Sales Application Has Been Cancelled!",
    "已取消，订单恢复正常": "Cancelled, Order Has Returned to Normal",
    "已同意": "Agreed",
    "已售后": "After-Sales Processed",
    "已完成": "Completed",
    "已成功发起售后申请，请耐心等待商家处理!": "The After-Sales Application Has Been Successfully Submitted. Please Be Patient and Wait for the Merchant to Process It!",
    "已成功发起售后申请，请耐心等待商家处理！": "The After-Sales Application Has Been Successfully Submitted. Please Be Patient and Wait for the Merchant to Process It!",
    "已成功发送短信": "Successfully Sent SMS",
    "已成功批量生成催收单": "Collection Notices Have Been Successfully Generated in Bulk",
    "已成功设置审核状态": "Review Status Has Been Successfully Set",
    "已报价": "Quote Provided",
    "已报价-已锁定": "Quoted - Locked",
    "已报价-待确认": "Quoted - Pending Confirmation",
    "已拒绝": "Rejected",
    "已拒绝，订单恢复正常": "Rejected, Order Returned to Normal",
    "已有催收单无法生成": "Cannot Generate New Collection Notices as One Already Exists",
    "已添加备注": "Added Remarks",
    "已添加标签": "Added Tags",
    "已添加至购物车": "Added to Shopping Cart",
    "已禁用": "Disabled",
    "已结算": "Settled",
    "已认证": "Certified",
    "已选": "Selected",
    "已选个数": "Selected Number",
    "已通过": "Passed",
    "市内代收": "Local Collection",
    "市场": "Market",
    "常用零件": "Commonly Used Parts",
    "常规件": "Standard Parts",
    "平台": "Platform",
    "平均": "Average",
    "年份": "Year",
    "广州市白云区景泰街白云大道南685号101号自编1009房": "Room 1009, No. 101, 685 South Baiyun Avenue, Jingtai Street, Baiyun District, Guangzhou City",
    "广州沃玛拉国际贸易有限公司": "Guangzhou Womara International Trading Co., Ltd.",
    "广州沃玛拉国际贸易有限公司成立于2012年。公司位于中国广州，交通便利，占地面积2500多平方米。是一家专业销售沃尔沃汽车零部件的公司，主要经营沃尔沃XC系列、V系列、S系列、C系列的进口零部件，与车主、维修店、经销商、代理商、生产厂家长期合作，我们在全球市场建立了销售网络。我们以卓越的品质、一流的服务、专业负责的形象，致力于为客户带来最优质的产品和服务，成为国内外有名的汽车零部件经销商。": "Guangzhou Womara International Trading Co., Ltd. was established in 2012. Located in Guangzhou, China, the company covers an area of over 2,500 square meters and benefits from convenient transportation. We specialize in the sale of Volvo automotive parts, focusing on imported components for Volvo XC series, V series, S series, and C series vehicles. We maintain long-term cooperation with vehicle owners, repair shops, dealers, agents, and manufacturers, and have established a sales network in the global market. With our commitment to superior quality, top-notch service, and a professional and responsible image, we strive to provide the highest quality products and services to our customers, aiming to become a renowned automotive parts distributor both domestically and internationally.",
    "广州沃玛拉国际贸易有限公司成立于中国广州。": "Guangzhou Womara International Trading Co., Ltd. was established in Guangzhou, China.",
    "广州沃玛拉零部件有限公司成立于2012年。公司位于中国广州，交通便利，占地面积2500多平方米。是一家专业销售沃尔沃汽车零部件的公司，主要经营沃尔沃XC系列、V系列、S系列、C系列的进口零部件，与车主、维修店、经销商、代理商、生产厂家长期合作，我们在全球市场建立了销售网络。我们以卓越的品质、一流的服务、专业负责的形象，致力于为客户带来最优质的产品和服务，成为国内外有名的汽车零部件经销商。": "Guangzhou Womara Auto Parts Co., Ltd. was established in 2012. The company is located in Guangzhou, China, with convenient transportation and covers an area of over 2,500 square meters. We specialize in the sale of Volvo automotive parts, focusing on imported components for Volvo XC series, V series, S series, and C series vehicles. We have established long-term partnerships with vehicle owners, repair shops, dealers, agents, and manufacturers, and have built a sales network in the global market. With our commitment to excellent quality, first-class service, and a professional and responsible image, we strive to provide customers with the highest quality products and services, aiming to become a well-known automotive parts distributor both domestically and internationally.",
    "序号": "Serial Number",
    "库存": "Stock",
    "库存价": "Stock Price",
    "库存价格": "Stock Price",
    "库存价格设置成功！": "Stock Price Set Successfully!",
    "库存充足": "Stock is Sufficient",
    "库存列表": "Stock List",
    "库存导入": "Stock Import",
    "库存数量": "Stock Quantity",
    "库存数量：": "Stock Quantity",
    "库存紧张": "Tight Stock",
    "应付单": "Accounts Payable",
    "应付货款(￥)": "Accounts Payable (￥)",
    "应付金额": "Payable Amount",
    "底盘": "Chassis",
    "底盘悬挂": "Chassis Suspension",
    "底盘系统": "Chassis System",
    "店长": "Store Manager",
    "开具发票": "Issue Invoice",
    "开单": "Issue a Bill",
    "开单成功": "Billing Success",
    "开始扩大业务范围，销售宝马、奔驰、保时捷、路虎的汽车配件。": "Start expanding the business scope to include the sale of automotive parts for BMW, Mercedes-Benz, Porsche, and Land Rover.",
    "开户银行": "Depositary Bank",
    "张女士": "Ms. Zhang",
    "当前定价": "Current Pricing",
    "当前报价单": "Current Quotation",
    "当前操作人": "Current Operator",
    "当前状态": "8待收货（8  Pending Shipment）",
    "当前输入的模版名称已存在！": "The Template Name Currently Entered Already Exists!",
    "当前选中": "Currently Selected",
    "待分配": "Pending Allocation",
    "待发货": "Pending Shipment",
    "待商家处理": "Pending Merchant Processing",
    "待处理": "Pending Processing",
    "待审核": "Pending Review",
    "待报价": "Pending Quotation",
    "待报价-待认领": "Pending Quotation - Unclaimed",
    "待支付": "Pending Payment",
    "待收货": "Pending Receipt",
    "待确认": "Pending Confirmation",
    "待认领": "Pending Claim",
    "待译码": "Pending Decoding",
    "微信": "WeChat",
    "微信头像": "WeChat Avatar",
    "微信扫码上传": "Wechat Scan Code Upload",
    "微信扫码支付": "WeChat QR Code Payment",
    "微信抓单": "WeChat Order Grabbing",
    "微信支付": "WeChat Payment",
    "微信昵称": "WeChat Nickname",
    "忘记密码": "Forgot Password",
    "急件": "Urgent Dispatch",
    "总业绩": "Total Performance",
    "总共": "Total",
    "总共付款": "Total Payment",
    "总共数量": "Total Quantity",
    "悬挂系统": "Suspension System",
    "成为博世、海拉、马瑞利授权经销商。": "Become an authorized distributor of Bosch, Hella, and Marelli.",
    "成交率": "Conversion Rate",
    "成交订单": "Completed Orders",
    "成功设置计划金额": "Successful Set Budget Amount",
    "我们开始向电子商务转型，成立外贸部并创立新品牌Womala开设两家阿里巴巴网店和一个国外网站。": "We started to transition to e-commerce, established a Foreign trade department and launched a new brand Womala to open two Alibaba online stores and a foreign website.",
    "我们沃玛拉的质量保证期是18个月。": "Womala offers an 18-month quality guarantee.",
    "我们的产品种类丰富，涵盖了发动机零件、制动系统、悬挂系统等多个类别，满足不同客户的多样化需求。我们始终坚持质量第一的原则，严格控制产品的生产流程，确保每一件产品都达到国际标准。": "Our wide range of products covers engine parts, braking systems, suspension systems and other categories to meet the diverse needs of different customers. We always adhere to the principle of quality first, strictly control the production process of products, to ensure that every product meets international standards.",
    "我们的仓库库存已接近1000万件，成为最具优势的零部件行业之一。沃尔沃集团的供应链": "Our warehouse inventory is close to 10 million pieces, becoming one of the most advantageous parts industry. Volvo Group's supply chain",
    "我们的成长历程": "Our Growth Journey",
    "我的": "My",
    "或": "Or",
    "所在区域": "Region",
    "所在地址": "Address",
    "所在城市": "Location City",
    "手机号": "Mobile Phone Number",
    "手机号码": "Phone Number",
    "手续费": "Service Charge",
    "打印": "Print",
    "打印发货单": "Print Shipping Invoice",
    "打印时间": "Print Time",
    "托收": "Collection",
    "执行": "Execute",
    "执行规则?": "Execute Rules?",
    "扫描二维码支付": "Scan QR Code for Payment",
    "批量上架": "Bulk Listing",
    "批量下架": "Bulk Unlisting",
    "批量价格模版": "Bulk Price Template",
    "批量修改价格": "Bulk Price Update",
    "批量修改标签": "Bulk Tag Update",
    "批量删除": "Bulk Delete",
    "批量启用/禁用": "Bulk Enable/Disable",
    "批量新增标签": "Bulk Add Tags",
    "批量标签": "Bulk Tags",
    "批量生成催收单": "Bulk Generate Collection Orders",
    "批量询价": "Bulk Inquiry",
    "投诉电话": "Complaint Phone Number",
    "折扣": "Discount",
    "报价": "Quotation",
    "报价中": "Quoting",
    "报价列表": "Quotation List",
    "报价单数": "Number of Quotations",
    "报价员": "Quotation Officer",
    "报价品质": "Quotation Quality",
    "报价回写失败": "Quotation Write-back Failed",
    "报价回写成功": "Quotation Write-back Successful",
    "报价待认领": "Quotation Pending Claim",
    "报价时间": "Quotation Time",
    "报价模版": "Quotation Template",
    "拒绝": "Reject",
    "拒绝售后": "Reject After-Sales Service",
    "拒绝理由": "Reason for Rejection",
    "拖拽工单图片到这里，或点击上传": "Drag and Drop Work Order Images Here or Click to Upload",
    "拖拽新图片到框内，可替换图片重新识别": "Drag and Drop New Images into the Box to Replace and Re-recognize the Image",
    "招商负责人-张女士": "Investment Manager - Ms. Zhang",
    "招商负责人-杨先生": "Investment Manager - Mr. Yang",
    "挂帐并发货": "Credit and Ship",
    "挂帐支付": "Credit Payment",
    "挂账": "Credit",
    "挂账支付": "Credit Payment (Same as above, might be used in different contexts)",
    "挂账金额": "Credit Amount",
    "挂账金额(万)": "Credit Amount (Ten Thousand)",
    "挂账金额已退回！": "Credit Amount Has Been Refunded!",
    "挂账额度设置": "Credit Limit Setting",
    "按所选日期批量生成": "Generate in Bulk by Selected Date",
    "按比例定价商品": "Proportional Pricing for Products",
    "挑选产品并加入购物车": "Select Products and Add to Cart",
    "授信挂账额度": "Credit Line for Deferred Payment",
    "授信额度": "Credit Limit",
    "排量": "Engine Displacementt",
    "排量(发动机代码)": "Engine Displacement (Engine Code)",
    "接听人": "Call Receiver",
    "接听时间": "Answer Time",
    "推广专区": "Promotional Area",
    "推荐图片": "Recommended Images",
    "推荐排序": "Recommended Sorting",
    "提交时间": "Submission Time",
    "提交申请": "Submit Application",
    "提示": "Tips",
    "提示 ": "如需订单帮助，请发送电子邮件至（For order assistance, please email us at .）",
    "搜索": "Search",
    "搜索零件名称": "Search for Part Names",
    "操作": "Operation",
    "操作人": "Operator",
    "操作成功": "Operation Successful",
    "操作成功！": "Operation Successful!",
    "操作时间": "Operation Time",
    "支付宝": "Alipay",
    "支付宝扫码支付": "Alipay QR Code Payment",
    "支付成功": "Payment Successful",
    "支付方式": "Payment Method",
    "支付金额": "Payment Amount",
    "支持输入负数": "Support Input Negative Numbers",
    "收款": "Payment Collection",
    "收货人": "Consignee",
    "收货人信息": "Consignee Information",
    "收货人姓名": "Consignee Name",
    "收货付款": "Payment Upon Receipt",
    "收货凭证": "Receipt Certificate",
    "收货地址": "Shipping Address",
    "收货地址管理": "Shipping Address Management",
    "收货成功！": "Received Successfully!",
    "收起": "Collapse",
    "改装车系": "Modified Car Series",
    "放心启动": "Start With Confidence",
    "放心好油": "Reliable Quality Fuel",
    "敞篷车": "Convertible Car",
    "数据录入": "Data Entry",
    "数量": "Quantity",
    "数量/单位": "Quantity/Unit",
    "整车配件一站式解决方案 · 沃尔沃汽车零部件专家": "One-Stop Solution for Complete Vehicle Parts · Volvo Automotive Parts Expert",
    "整车配件一站式解决方案·沃尔沃汽车零部件专家": "One-Stop Solution for Complete Vehicle Parts · Volvo Automotive Parts Expert",
    "文本": "Text",
    "新品发布": "New Product Release",
    "新增": "New",
    "新增发票": "Add Invoice",
    "新增地址": "Add Address",
    "新增成功！": "Successfully Added!",
    "新增标签": "Add New Tag",
    "新增模版": "Add New Template",
    "新建客户": "Create New Customer",
    "无": "None",
    "无价格": "No Price",
    "无图": "No Image",
    "无意向": "No Intent",
    "无替换件": "No Replacement Parts",
    "无模版": "No Template",
    "无法生成催收单！": "Unable to Generate Collection Order!",
    "日期": "Date",
    "易碎品": "Fragile Products",
    "是": "Yes",
    "是否移出购物车？": "Remove From Shopping Cart?",
    "是的，我们在发货前有100%的测试。我们注重质量。": "Yes, we perform 100% testing before shipping. We focus on quality.",
    "昵称": "Name",
    "显示其他属性": "Show Other Attributes",
    "普票": "Regular Invoice",
    "普通发票": "Regular Invoice",
    "普通发票开票点数": "Standard Invoice Points",
    "普通员工": "Regular Employee",
    "智能识别": "Smart Recognition",
    "暂不支持此支付方式": "This Payment Method Is Currently Not Supported",
    "暂无": "Not Available",
    "暂无发票": "No Invoice Available",
    "暂无发票，请添加发票": "No Invoice Available. Please Add An Invoice",
    "暂无地址": "No Address Available",
    "暂无地址，新增地址": "No Address Available. Add A New Address",
    "暂无数据": "No Data",
    "暂无跟进记录": "No Follow-Up Records Available",
    "更多": "More",
    "更新成功": "Update Successful",
    "更新成功！": "Update Successful!",
    "更新时间": "Update Time",
    "替换件": "Replacement Parts",
    "替换家": "Replacement Vendor",
    "最后一次询价时间": "Last Inquiry Time",
    "最多3张": "Up to 3 Images",
    "最多可退": "Maximum Returnable",
    "最多可退件数": "Maximum Returnable Items",
    "最大（默认）": "Maximum (Default)",
    "最小": "Minimum",
    "最新报价时间": "Latest Quotation Time",
    "最近跟进人": "Most Recent Follow-Up Person",
    "最近跟进内容": "Most Recent Follow-Up Content",
    "有": "Have",
    "有价格": "Have a Price",
    "有原盒": "Original Box Available",
    "有图": "Image Available",
    "未关联": "Not Linked",
    "未合作原因": "Reason for No Cooperation",
    "未查询到商品信息，请前往ERP库存补充此产品编码对应的适用OE，生成商品编码后，即可进行定价！": "Product Information Not Found. Please Update the Applicable OE for This Product Code in ERP Inventory. Once the Product Code Is Generated, Pricing Can Be Done!",
    "未活跃天数": "Days Inactive",
    "未结算": "Not Settled",
    "未认证": "Not Verified",
    "未过滤": "Not Filtered",
    "未通过": "Not passed",
    "本期应付货款": "Current Payable Amount",
    "材质": "Material",
    "条": "Item",
    "来源": "Source",
    "杨先生": "Mr. Yang",
    "极致优选，国际大牌配套": "Premium Selection, International Brand Compatible",
    "查看": "View",
    "查看更多": "View More",
    "查询": "Search",
    "查询次数": "Search Count",
    "查询结果": "Search Results",
    "标签": "Tag",
    "标签(可多选)": "Tags (Multiple Choice)",
    "标签名称": "Tag Name",
    "标签类型": "Tag Type",
    "标签设置成功！": "Tag Set Successfully!",
    "模版名称": "Template Name",
    "正常": "Normal",
    "比价": "Compare Prices",
    "汇款": "Remittance",
    "汽灵灵云汽配，一站式交易平台。": "Qi Ling Ling Yun Auto Parts, One-Stop Trading Platform.",
    "汽灵灵自2018年成立以来，便专注于为汽车后市场提供供应链产业协同服务。致力于打造高效供应链服务平台，实现资源的优化配置与信息共享，其服务内容涵盖了汽车配件的采购、销售、物流配送、技术支持以及平台运营等多个环节，为汽配行业提供全方位、一站式的供应链解决方案。": "Since its establishment in 2018, Qilingling has been dedicated to providing supply chain industry collaboration services for the automotive aftermarket. Committed to building an efficient supply chain service platform, achieving optimized resource allocation and information sharing, its service content covers multiple links such as automotive parts procurement, sales, logistics distribution, technical support, and platform operation, providing a comprehensive and one-stop supply chain solution for the automotive parts industry.",
    "汽车售后产业平台，提供一站式服务支持": "Automotive Aftermarket Platform, Offering One-Stop Service Supportt",
    "汽车配件在线交易平台": "Online Auto Parts Trading Platform",
    "汽配商": "Auto Parts Dealer",
    "沃尔沃": "Volvo",
    "沃玛拉国际贸易有限公司": "Womara International Trade Co., Ltd",
    "油品": "Oil Products",
    "油品1": "Oil Products 1",
    "注册": "Register",
    "注册公司名称": "Registered Company Name",
    "注册成功！": "Registration Successful!",
    "注册时间": "Registration Time",
    "注释": "Annotation",
    "活跃用户": "Active Users",
    "测试中心": "Testing Center",
    "添加": "Add",
    "添加人员": "Add Personnel",
    "添加商品": "Add Product",
    "添加成功": "Added Successfully",
    "添加抬头": "Add Header",
    "温馨提示": "Reminder",
    "点击打开小程序支付": "Click to Open Mini Program for Payment",
    "点击打开网页支付": "Click to Open Web Payment",
    "点击查看价格": "Click to View Price",
    "点火系统": "Ignition System",
    "热销商品": "Best-Selling Products",
    "热门商品": "Popular Products",
    "照明系统": "Lighting System",
    "燃油供给系": "Fuel Supply System",
    "燃油类型": "Fuel Type",
    "物流": "Logistics",
    "物流单号": "Logistics Tracking Number",
    "物流名称": "Logistics Provider",
    "特价专区": "Special Offers",
    "特价商品": "Special Offer Products",
    "状态": "Status",
    "玛莎拉蒂": "Maserati",
    "现有货源充足，小订单交货期1周以内，普通订单交货期15天左右，定制订单交货期1-2个月。": "Current Stock is Sufficient. Small Orders Have a Delivery Time Within 1 Week. Regular Orders Have a Delivery Time of Approximately 15 Days. Customized Orders Have a Delivery Time of 1-2 Months.",
    "现货": "In Stock",
    "现货充足": "Sufficient Stock",
    "现货紧张": "Stock is Tight",
    "现金": "Cash",
    "生成催收单": "Generate a Collection Note",
    "用户": "User",
    "用户列表": "User List",
    "用户名": "Username",
    "用户名称": "User Name",
    "用户状态": "User Status",
    "用户管理": "User Management",
    "申请人姓名": "Applicant Name",
    "申请售后": "Request After-Sales Service",
    "申请时间": "Request Time",
    "申请金额": "Requested Amount",
    "电气系统": "Electrical System",
    "电话": "Phone",
    "电话号码": "Phone Number",
    "疑问解答": "FAQ (Frequently Asked Questions)",
    "登录": "Sign In",
    "直接定价": "Direct Pricing",
    "直接替换": "Direct Replacement",
    "省/市/区": "Province/City/District",
    "省市区": "Province, City, and District",
    "知名品牌": "Famous Brands",
    "确定": "Confirm",
    "确定收货": "Confirm Receipt",
    "确定要修改吗？": "Are You Sure You Want to Modify?",
    "确认": "Confirm",
    "确认下单": "Confirm Order",
    "确认付款": "Confirm Payment",
    "确认删除?": "Confirm Deletion?",
    "确认删除订单?": "Confirm Order Deletion?",
    "确认密码": "Confirm Password",
    "确认支付": "Confirm Payment",
    "确认收货": "Confirm Receipt",
    "确认解绑": "Confirm Unlink",
    "确认购买": "Confirm Purchase",
    "禁用": "Disable",
    "离合器泵类": "Clutch Pumps",
    "积分兑换区": "Points Redemption Area",
    "积分商城": "Points Store",
    "税号": "Tax ID",
    "税点": "Tax Points",
    "税额": "Tax Amount",
    "税额设置": "Tax Amount Settings",
    "空": "Empty",
    "空调系统": "Air Conditioning System",
    "空闲": "Idle",
    "立即购买": "Buy Now",
    "管": "Pipe",
    "管理员": "Administrator",
    "管理收货地址": "Manage Shipping Address",
    "管路总成类": "Pipe Assembly",
    "米": "Meter",
    "类型": "Type",
    "粘贴信息、自动拆分姓名、电话和地址": "Paste Information, Automatically Split Names, Phone Numbers, and Addresses",
    "粘贴信息，自动拆分姓名、电话和地址": "Paste Information, Automatically Split Names, Phone Numbers, and Addresses",
    "粘贴并识别": "Paste and Identify",
    "粘贴或拖动文件到此区域以进行识别": "Paste or Drag Files to This Area for Identification",
    "系统标签": "System Label",
    "系统预置": "System Preset",
    "繁忙": "Busy",
    "纳税人识别号": "Taxpayer Identification Number (TIN)",
    "线上已支付": "Paid Online",
    "线下已支付": "Paid Offline",
    "线下已支付待确认": "Offline Payment Pending Confirmation",
    "线下门店": "Physical Store",
    "组": "Group",
    "绑定关系": "Binding Relationship",
    "结算": "Settle Accounts",
    "维修保养件": "Maintenance Parts",
    "维修方案": "Maintenance Plan",
    "编号查询": "Number Inquiry",
    "编辑": "Edit",
    "编辑岗位": "Editing Position",
    "编辑机洗规则": "Edit Machine Wash Rules",
    "编辑标签": "Edit Tags",
    "编辑模版": "Edit Template",
    "老板": "Boss",
    "耐博德": "Naibode",
    "联系人": "Contacts",
    "联系我们": "Contact Us",
    "联系电话": "Contact Phone Number",
    "自动通过": "Automatic Pass",
    "自定义": "Custom",
    "自定义替换OE": "Custom Replacement OE",
    "自定义替换OE编辑成功！": "Custom Replacement OE Edited Successfully!",
    "自定义替换件": "Custom Replacement Parts",
    "自定义标签": "Custom Tags",
    "自定义配件": "Custom Parts",
    "自营产品": "Self-Operated Products",
    "英文": "English",
    "获取价格": "Get Price",
    "获取验证码": "Get Verification Code",
    "营业员": "Sales Clerk",
    "营业执照": "Business License",
    "萨肯SHAKOLN，专注于研发和销售高性能的汽车零部件。": "SHAKOLN, Focused on Developing and Selling High-Performance Automotive Parts.",
    "行驶系统": "Driving System",
    "解放": "FAW",
    "解析错误，请上传清晰图片！": "Parsing Error, Please Upload a Clear Image!",
    "解绑": "Unbind",
    "解绑后，将不再是公司成员！": "After Unbinding, You Will No Longer Be a Company Member!",
    "计划金额 (万)": "Planned Amount (in 10000)",
    "订单": "Order",
    "订单信息": "Order Information",
    "订单分配": "Order Allocation",
    "订单列表": "Order List",
    "订单号": "Order Number",
    "订单号/VIN码搜索": "Order Number/VIN Code Search",
    "订单回写中，请等待": "Order Rewriting in Progress, Please Wait",
    "订单备注": "Order Notes",
    "订单数": "Number of Orders",
    "订单数量": "Order Quantity",
    "订单管理": "Order Management",
    "订单详情": "Order Details",
    "订单金额": "Order Amount",
    "认证中": "Under Review",
    "认证公司名称": "Certified Company Name",
    "认证公司后显示价格": "Prices Displayed After Certification",
    "认证后可获得更多，商品查询次数": "Get More Product Search Queries After Certification",
    "认证失败": "Certification Failed",
    "认证状态": "Certification Status",
    "认领": "Claim",
    "让配件交易更简单": "Make Parts Trading Simpler",
    "让配件查询更简单": "Make Parts Search Easier",
    "设为默认": "Set as Default",
    "设备": "Device",
    "设备1": "Device 1",
    "设置": "Setting",
    "设置为收货地址": "Set as Shipping Address",
    "设置为默认发票": "Set as Default Invoice",
    "设置为默认收货地址": "Set as Default Shipping Address",
    "译码": "Decode",
    "译码列表": "Decode List",
    "译码员": "Decoder",
    "译码时间": "Decode Time",
    "试装过": "Tested",
    "诚邀全国合作代理商": "We Invite Nationwide Agents for Cooperation",
    "询价": "Inquiry",
    "询价人": "Inquirer",
    "询价列表": "Inquiry List",
    "询价成功": "Inquiry Successful",
    "询价成功，可在订单列表中查看！": "Inquiry Successful. You Can View It in the Order List!",
    "该公司已存在，您将自动申请加入此公司": "The Company Already Exists; You Will Be Automatically Added.",
    "该商品尚未定价": "This Product Has Not Been Priced Yet",
    "该日期内已有生成的催收单，将无法生成可取消或客户付款后再生成！": "A Collection Request Has Already Been Generated for This Date. It Cannot Be Generated Again or After Customer Payment.",
    "该日期内已有生成的催收单，请先取消或客户付款后再生成！": "A Collection Request Has Already Been Generated for This Date. Please Cancel It First or Wait Until After Customer Payment to Generate a New One.",
    "该用户曾绑定多家公司": "This User Has Previously Been Linked to Multiple Companies.",
    "详情": "Details",
    "详细信息": "Detailed Information",
    "详细地址": "Full Address",
    "说明": "Description",
    "请使用微信扫一扫": "Please Use WeChat to Scan",
    "请使用微信扫一扫，扫描二维码支付": "Please Use WeChat to Scan and Pay with the QR Code",
    "请使用零零汽APP扫码登录": "Please Use the Zero Zero Zero Automotive APP to Scan and Sign in",
    "请先填写内容": "Please Fill Out the Information First",
    "请先填写必填信息!": "Please Fill Out the Required Information First!",
    "请先点击同意《用户注册协议》和《用户隐私协议》再注册！": "Please Click to Agree to the 'User Registration Agreement' and 'User Privacy Policy' Before Registering!",
    "请先点击同意《用户注册协议》和《用户隐私协议》再登录！": "Please Click to Agree to the 'User Registration Agreement' and 'User Privacy Policy' Before Logging In!",
    "请先登录": "Please Sign In First",
    "请先输入手机号!": "Please Enter Your Phone Number First!",
    "请先输入邮箱!": "Please Enter Your Email First!",
    "请填写件数": "Please Enter the Quantity",
    "请填写发货单备注": "Please Enter the Shipping Order Notes",
    "请填写物流单号": "Please Enter the Tracking Number",
    "请填写物流名称": "Please Enter the Logistics Name",
    "请填写物流备注": "Please Enter the Logistics Notes",
    "请填写送货骑手姓名": "Please Enter the Delivery Rider's Name",
    "请最少选择一个商品发货！": "Please Select at Least One Product for Shipping!",
    "请最少选择一项！": "Please Select at Least One Item!",
    "请正确填写参数": "Please Enter the Parameters Correctly",
    "请添加发票": "Please Add an Invoice",
    "请添加商品到购物车": "Please Add Products to Your Cart",
    "请登录": "Sign In",
    "请确认 ERP 系统用户名": "Please Confirm the ERP System Username",
    "请确认删除?": "Please Confirm Deletion?",
    "请确认收货": "Please Confirm Receipt",
    "请认证公司，获取查询次数！": "Please Verify Your Company to Obtain More Query Limits!",
    "请输入": "Please Enter",
    "请输入OE或厂家编码": "Please Enter OE or Manufacturer Code",
    "请输入VIN码": "Please Enter the VIN Code",
    "请输入VIN码或拖拽VIN码图片到此处": "Please Enter the VIN Code or Drag and Drop a VIN Image Here",
    "请输入oe": "Please Enter OE",
    "请输入公司税号": "Please Enter the Company Tax ID",
    "请输入发件人姓名": "Please Enter the Sender's Name",
    "请输入发件人联系方式": "Please Enter the Sender's Contact Information",
    "请输入发件人详细地址": "Please Enter the Sender's Full Address",
    "请输入增值税专用发票点数": "Please Enter the VAT Invoice Points",
    "请输入备注": "Please Enter a Note",
    "请输入备注内容": "Please Enter Note Content",
    "请输入完整的收发货信息！": "Please Enter Complete Shipping and Receiving Information!",
    "请输入客户ID": "Please Enter Customer ID",
    "请输入客户名字或者简拼": "Please Enter Customer Name or Abbreviation",
    "请输入客户名称": "Please Enter Customer Name",
    "请输入抬头名称": "Please Enter the Title Name",
    "请输入授信金额": "Please Enter the Credit Amount",
    "请输入授信金额(￥)": "Please Enter the Credit Amount (￥)",
    "请输入收件人姓名": "Please Enter the Recipient's Name",
    "请输入收件人联系方式": "Please Enter the Recipient's Contact Information",
    "请输入收件人详细地址": "Please Enter the Recipient's Full Address",
    "请输入数值": "Please Enter a Numerical Value",
    "请输入普通发票开票点数": "Please Enter the Points for Regular Invoice",
    "请输入材质": "Please Enter the Material",
    "请输入模版名称": "Please Enter the Template Name",
    "请输入正确的数字并保留两位小数": "Please Enter a Valid Number with Two Decimal Places",
    "请输入物流名称！": "Please Enter the Logistics Name!",
    "请输入纳税人识别号": "Please Enter the Taxpayer Identification Number",
    "请输入联系人": "Please Enter the Contact Person",
    "请输入联系电话": "Please Enter the Contact Phone Number",
    "请输入详细地址": "Please Enter the Full Address",
    "请输入详细地址信息，如道路、门牌号等信息": "Please Enter Detailed Address Information, Such as Street and House Number",
    "请输入送货骑手联系方式": "Please Enter the Delivery Rider's Contact Information",
    "请输入零件号查询，多零件请换行": "Please Enter the Part Number for Search; Use a New Line for Multiple Parts",
    "请输入零件名称或零件号": "Please Enter the Part Name or Part Number",
    "请输入需要添加的标签": "Please Enter the Tags to Add",
    "请选择": "Please Select",
    "请选择 ERP 系统用户名": "Please Select the ERP System Username",
    "请选择仓库": "Please Select a Warehouse",
    "请选择价格模版": "Please Select a Pricing Template",
    "请选择公司": "Please Select a Company",
    "请选择地址": "Please Select an Address",
    "请选择基础模版": "Please Select a Base Template",
    "请选择客户": "Please Select a Customer",
    "请选择客户类型": "Please Select a Customer Type",
    "请选择开具发票信息": "Please Select Invoice Information",
    "请选择或者输入必填项！": "Please Select or Enter the Required Fields!",
    "请选择支付方式": "Please Select a Payment Method",
    "请选择收货地址": "Please Select a Shipping Address",
    "请选择需要购买的商品": "Please Select the Products to Purchase",
    "调比例": "Adjust Ratio",
    "账号": "Account",
    "账号/公司/标签": "Account/Company/Label",
    "账号/公司名称": "Account/Company Name",
    "账号/公司搜索": "Account/Company Search",
    "账号状态": "Account Status",
    "账户余额": "Account Balance",
    "货到付款": "Cash on Delivery (COD)",
    "货款": "Payment for Goods",
    "货款已结算": "Payment Settled",
    "货款未结算": "Payment Unsettled",
    "货款状态": "Payment Status",
    "质保": "Warranty",
    "质保政策": "Warranty Policy",
    "质保时间": "Warranty Period",
    "质保时间管理": "Warranty Period Management",
    "购买数量：": "Purchase Quantity",
    "购买流程": "Purchase Process",
    "购物车": "Shopping Cart",
    "购货单位": "Purchasing Unit",
    "购货收货人": "Purchase Recipient",
    "购货日期": "Purchase Date",
    "起订量": "Minimum Order Quantity",
    "距离二维码过期还剩x秒，过期后请刷新页面重新获取二维码": "QR Code Expires in x Seconds; Please Refresh the Page to Get a New QR Code After Expiration",
    "路虎捷豹": "Land Rover and Jaguar",
    "跳过": "Skip",
    "车型": "Vehicle Model",
    "车型(底盘)": "Vehicle Model (Chassis)",
    "车型查询": "Vehicle Model Search",
    "车架号查询": "VIN Search",
    "车润保是一家专注于整合配件商与修理厂的汽车配件交易平台公司。我们的使命是为汽车维修行业提供高效、便捷的配件采购和交易解决方案。 作为汽车配件交易平台，我们与全国各地的优秀配件供应商、配件生产厂家建立了紧密的合作关系。通过我们的平台，修理厂可以轻松浏览和购买各类汽车配件，包括The engine零件、制动系统、悬挂部件、电子设备等。我们提供多样化的产品选择，以满足不同车型和维修需求。": "CheRunBao is a company focused on integrating parts suppliers and repair shops through an automotive parts trading platform. Our mission is to provide efficient and convenient parts procurement and trading solutions for the automotive repair industry. As an automotive parts trading platform, we have established close partnerships with top parts suppliers and manufacturers across the country. Through our platform, repair shops can easily browse and purchase various automotive parts, including engine components, braking systems, suspension parts, electronic equipment, and more. We offer a diverse range of products to meet the needs of different car models and repair requirements.",
    "车身": "Vehicle Body",
    "车辆信息": "Vehicle Information",
    "车辆确认": "Vehicle Confirmation",
    "转向系统": "Steering System",
    "转向节总成类": "Steering Knuckle Assembly",
    "转让后，该账号将成为店长！": "After Transfer, This Account Will Become the Store Manager!",
    "转让管理员": "Transfer Administrator",
    "输入OE号或17位车架号(支持拖拽或粘贴图片识别)": "Enter OE Number or 17-Digit VIN (Supports Drag-and-Drop or Image Recognition)",
    "输入VIN码，快速查询零件": "Enter VIN for Quick Parts Lookup",
    "输入公司名称": "Enter Company Name",
    "输入内容…": "Enter Content",
    "输入名称": "Enter Name",
    "输入商品名称/编码": "Enter Product Name/Code",
    "输入备注": "Enter Remarks",
    "输入奔驰后8位或17位车架号": "Enter Last 8 or 17 Digits of Mercedes VIN Number",
    "输入宝马后7位或17位车架号": "Enter Last 7 or 17 Digits of BMW VIN Number",
    "输入岗位名称": "Enter Position Name",
    "输入手机号": "Enter Phone Number",
    "输入拒绝理由": "Enter Reason for Rejection",
    "输入标签名称": "Enter Label Name",
    "输入玛莎拉蒂后7位或17位车架号": "Enter Last 7 or 17 Digits of Maserati VIN Number",
    "输入用户名称或账号": "Enter Username or Account",
    "输入申请人姓名": "Enter Applicant's Name",
    "输入税号": "Enter Tax Number",
    "输入街道、门牌号、写字楼、商圈": "Enter Street, House Number, Office Building, Business District",
    "输入解放后8位或17位车架号": "Enter Last 8 or 17 Digits of FAW Jiefang VIN Number",
    "输入账号/名称搜索": "Enter Account/Name for Search",
    "输入账号/姓名搜索": "Enter Account/Name for Search",
    "输入路虎捷豹后8位或17位车架号": "Enter Last 8 or 17 Digits of Land Rover Jaguar VIN Number",
    "输入重汽后8位或17位车架号": "Enter Last 8 or 17 Digits of Sinotruk VIN Number",
    "输入金额": "Enter Amount",
    "输入陕汽后8位或17位车架号": "Enter Last 8 or 17 Digits of Shaanxi Automobile VIN Number",
    "输入零件原厂名／零件号": "Enter OEM Name/Part Number",
    "过滤": "Filter",
    "迈巴赫专区": "Maybach Section",
    "返回": "Return",
    "返回商城登录": "Return to Mall Sign In",
    "返回搜索": "Return to Search",
    "返回登录": "Return to Sign In",
    "返回首页": "Return to Homepage",
    "返点比例": "Rebate Ratio",
    "返点金额 (万)": "Rebate Amount (in Ten Thousands)",
    "进价(含税)": "Purchase Price (Including Tax)",
    "进价(未含税)": "Purchase Price (Excluding Tax)",
    "进口品牌": "Imported Brands",
    "进货价(含税)": "Purchase Cost (Including Tax)",
    "退出": "Exit",
    "退出登录": "Sign Out",
    "退款": "Refund",
    "退款/售后": "Refund/After-Sales",
    "退款金额": "Refund Amount",
    "退货退款": "Returns and Refund",
    "送及经手人": "Delivery Person",
    "送货骑手": "Delivery Rider",
    "适用OE": "Applicable OE",
    "适用车型": "Applicable Vehicle Models",
    "适配OE": "Compatible OE",
    "适配车型": "Compatible Vehicle Models",
    "选择": "Select",
    "选择价格类型": "Select Price Type",
    "选择品牌": "Select Brand",
    "选择商户": "Select Merchant",
    "选择图像": "Select Image",
    "选择岗位": "Select Position",
    "选择年份": "Select Year",
    "选择排量": "Select Engine Displacement",
    "选择省/市/区": "Select Province/City/District",
    "选择类型": "Select Type",
    "选择要查询的内容": "Select the Content to Query",
    "选择账号": "Select Account",
    "选择车型": "Vehicle Model",
    "选择配置信息": "Select Configuration Information",
    "邮箱": "Email",
    "部分发货": "Partial Shipment",
    "配件品牌": "Parts Brand",
    "配件总成类": "Parts Assembly Type",
    "配置": "Configuration",
    "配置详情": "Configuration Details",
    "配送方式": "Delivery Method",
    "采埃孚": "ZF (Zf Friedrichshafen)",
    "采购商": "Buyer",
    "采购款": "Purchase Amount",
    "采购款已结算": "Purchase Amount Settled",
    "采购款未结算": "Purchase Amount Unsettled",
    "采购款状态": "Purchase Amount Status",
    "重": "Heavy",
    "重新上传": "Re-upload",
    "重新申请": "Reapply",
    "重汽": "Sinotruk",
    "金额": "Amount",
    "银行账户": "Bank Account",
    "销售价": "Selling Price",
    "销售价(含税)": "Selling Price (Including Tax)",
    "销售清单": "Sales List",
    "锁定": "Lock",
    "锁定上下架状态?": "Lock the Listing Status?",
    "锁定上架状态?": "Lock the Listing Status?",
    "锁定下架状态?": "Lock the Unlisting Status?",
    "长": "Length",
    "长度不超过25个字符": "Length Cannot Exceed 25 Characters",
    "门店照片": "Store Photo",
    "间接替换": "Indirect Replacement",
    "陕汽": "Shaanxi Automobile",
    "雨刮": "Wiper",
    "雨刮系统": "Wiper System",
    "零件": "Part",
    "零件信息": "Part Information",
    "零件分类": "Part Classification",
    "零件参数": "Part Specifications",
    "零件号查询": "Part Number Search",
    "零件名": "Part Name",
    "零件名称": "Part Name",
    "零件名称/OE": "Part Name/OE",
    "零件图": "Part Image",
    "零件类型：": "Part Type",
    "零件识别": "Part Identification",
    "零零汽APP扫码登录": "Sign in with ZeroZero Auto App QR Code",
    "零零汽二维码登录": "Sign in with ZeroZero Auto QR Code",
    "零零汽账号": "ZeroZero Auto Account",
    "零零汽账号登录": "Sign in with ZeroZero Auto Account",
    "非危险品": "Non-Hazardous Goods",
    "非易碎品": "Non-Fragile Items",
    "预计购买后3-7个工作日发货": "Estimated Shipping Within 3-7 Business Days After Purchase",
    "风险": "Risk",
    "风险用户提示": "Risk User Alert",
    "驱动": "Drive",
    "驱动方式": "Drive Type",
    "驾安配": "Jia'an Auto Parts",
    "验证码": "Verification Code",
    "验证码登录": "Sign In with Verification Code",
    "高": "High",
    "高质量货源，全平台覆盖，自带销量": "High-Quality Source, Platform-Wide Coverage, Built-In Sales",
    "默认": "Default",
    "鼓式制动器": "Drum Brake",
    "下单成功": "Checkout success ",
    "浏览记录": "Browsing history",
    '共{count}家店': "There are {count} stores"
  }
}

export default ara