const LocaleConfig = {
  // common
  saveText: "cứu",
  setText: "cài đặt",
  saveSuccessText: "Đã lưu thành công",
  saveFailedText: "Lưu không thành công",
  addFriendSuccessText: "Thêm bạn bè thành công",
  applyFriendSuccessText: "Ứng dụng thêm bạn bè thành công",
  addFriendFailedText: "Không thêm được bạn bè",
  applyFriendFailedText: "Đơn xin thêm bạn bè không thành công",
  okText: "Chắc chắn",
  cancelText: "Hủy bỏ",
  deleteText: "xóa bỏ",
  recallText: "rút",
  forwardText: "Phía trước",
  forwardFailedText: "Chuyển tiếp không thành công",
  sendBtnText: "gửi",
  replyText: "hồi đáp",
  commentText: "tin nhắn",
  recentSessionText: "Những chuyến thăm gần đây",
  you: "Bạn",
  deleteFriendText: "Xóa một người bạn",
  confirmDeleteText: "Bạn có chắc chắn muốn xóa không?",
  confirmDeleteFriendText: "Bạn có chắc chắn muốn xóa bạn bè không?",
  deleteFriendSuccessText: "Xóa bạn bè thành công",
  deleteFriendFailedText: "Không xóa được bạn bè",
  blackText: "Chặn bạn bè",
  removeBlackText: "Bỏ chặn",
  blackSuccessText: "Thành công của danh sách đen",
  blackFailedText: "Danh sách đen không thành công",
  removeBlackSuccessText: "Đã bỏ chặn thành công",
  removeBlackFailedText: "Bỏ chặn không thành công",
  maxSelectedText: "Bạn chỉ có thể chọn",
  selectedText: "Đã chọn",
  friendsText: "Bạn",
  strangerText: "Một người lạ",
  emailErrorText: "Định dạng email không đúng",
  uploadLimitText: "Kích thước hình ảnh hoặc tệp được hỗ trợ tối đa",
  uploadLimitUnit: "M",
  uploadImgFailedText: "Không tải được hình ảnh lên",
  accountText: "tài khoản",
  nickText: "Biệt danh",
  genderText: "giới tính",
  phoneText: "điện thoại di động",
  emailText: "Thư",
  signText: "dấu hiệu",
  accountPlaceholder: "Vui lòng nhập số tài khoản của bạn",
  teamIdPlaceholder: "Vui lòng nhập ID nhóm",
  nickPlaceholder: "Vui lòng nhập biệt danh của bạn",
  genderPlaceholder: "Vui lòng chọn giới tính",
  phonePlaceholder: "Vui lòng nhập số điện thoại của bạn",
  emailPlaceholder: "Vui lòng nhập địa chỉ email của bạn",
  signPlaceholder: "Vui lòng nhập chữ ký của bạn",
  searchInputPlaceholder: "Tìm kiếm bạn bè hoặc nhóm",
  searchTeamMemberPlaceholder: "Tìm kiếm thành viên nhóm",
  searchText: "tìm kiếm",
  man: "nam giới",
  woman: "nữ giới",
  unknow: "không rõ",
  welcomeText: "Chào mừng đến với Yunxin",
  notSupportMessageText: "Tin nhắn này hiện không được hỗ trợ",
  applyTeamText: "Đăng ký tham gia nhóm",
  applyTeamSuccessText: "Đơn xin gia nhập nhóm đã thành công",
  rejectText: "từ chối",
  acceptText: "đồng ý",
  inviteTeamText: "Mời bạn tham gia nhóm",
  applyFriendText: "Thêm bạn làm bạn bè",
  acceptResultText: "Đồng ý",
  rejectResultText: "Vật bị loại bỏ",
  beRejectResultText: "Yêu cầu kết bạn bị từ chối",
  passResultText: "Đã chấp nhận yêu cầu kết bạn",
  rejectTeamInviteText: "Lời mời nhóm bị từ chối",
  updateTeamAvatar: "Cập nhật avatar nhóm",
  updateTeamName: "Cập nhật tên nhóm thành",
  updateTeamIntro: "Cập nhật giới thiệu nhóm",
  updateTeamInviteMode: "Đã cập nhật quyền nhóm &quot;Mời người khác quyền&quot; thành",
  updateTeamUpdateTeamMode: "Đã cập nhật quyền nhóm &quot;Quyền sửa đổi dữ liệu nhóm&quot; thành",
  updateAllowAt: "Đã cập nhật &quot;Quyền @Mọi người&quot; thành",
  updateTeamMute: "Đã cập nhật &quot;Nhóm tắt tiếng&quot; thành",
  onlyTeamOwner: "Chỉ có chủ sở hữu nhóm",
  teamAll: "Tất cả",
  closeText: "đóng cửa",
  openText: "Mở",
  inviteText: "mời",
  aliasText: "Nhận xét",
  updateAliasSuccessText: "Sửa đổi ghi chú thành công",
  updateAliasFailedText: "Không thể sửa đổi bình luận",
  sendText: "Gửi tin nhắn",
  noPermission: "Bạn không có quyền hoạt động",
  unreadText: "Chưa đọc",
  readText: "Đọc",
  allReadText: "Tất cả đã đọc",
  amap: "Bản đồ",
  txmap: "Bản đồ Tencent",
  bdmap: "Bản đồ Baidu",
  callDurationText: "Thời gian nói chuyện",
  callCancelText: "Đã hủy",
  callRejectedText: "Vật bị loại bỏ",
  callTimeoutText: "Đã hết thời gian",
  callBusyText: "Bên kia đang bận",
  // conversation-kit
  onDismissTeamText: "Nhóm đã bị giải tán",
  onRemoveTeamText: "Bạn đã bị xóa khỏi nhóm",
  textMsgText: "Tin nhắn văn bản",
  customMsgText: "Tin nhắn tùy chỉnh",
  audioMsgText: "Tin nhắn âm thanh",
  videoMsgText: "Tin nhắn video",
  fileMsgText: "Tệp tin nhắn",
  callMsgText: "Tin nhắn ghi âm cuộc gọi",
  geoMsgText: "Vị trí tin nhắn",
  imgMsgText: "Tin nhắn hình ảnh",
  notiMsgText: "Tin nhắn thông báo",
  robotMsgText: "Tin nhắn máy",
  tipMsgText: "Tin nhắn nhắc nhở",
  unknowMsgText: "Tin nhắn không xác định",
  deleteSessionText: "Xóa một phiên",
  muteSessionText: "Bật chế độ Không làm phiền",
  unmuteSessionText: "Hủy Không làm phiền",
  deleteStickTopText: "Gỡ ghim",
  addStickTopText: "Tin nhắn dính",
  beMentioned: "[Ai đó @ tôi]",
  imgText: "hình ảnh",
  videoText: "băng hình",

  // contact-kit
  teamListTitle: "Nhóm của tôi",
  friendListTitle: "Bạn bè của tôi",
  blackListTitle: "danh sách đen",
  msgListTitle: "Trung tâm tin nhắn",
  blackListDesc: "(Bạn sẽ không nhận được tin nhắn từ bất kỳ địa chỉ liên lạc nào trong danh sách)",
  teamMenuText: "Nhóm của tôi",
  friendMenuText: "Bạn bè của tôi",
  blackMenuText: "danh sách đen",
  msgMenuText: "Trung tâm tin nhắn",
  acceptedText: "Ứng dụng đã được chấp thuận",
  acceptFailedText: "Việc chấp thuận đơn đăng ký đã không thành công",
  rejectedText: "Đơn đã bị từ chối",
  rejectFailedText: "Việc từ chối đơn đăng ký đã không thành công",
  getApplyMsgFailedText: "Không nhận được tin nhắn",

  // search-kit
  addFriendText: "Thêm bạn bè",
  createTeamText: "Tạo một nhóm",
  joinTeamText: "Tham gia một nhóm",
  joinTeamSuccessText: "Đã tham gia nhóm thành công",
  beRemoveTeamText: "Đã xóa khỏi nhóm",
  addButtonText: "Thêm vào",
  addSuccessText: "Đã thêm thành công",
  addFailedText: "Thêm không thành công",
  createButtonText: "tạo nên",
  createTeamSuccessText: "Nhóm đã được tạo thành công",
  createTeamFailedText: "Không tạo được nhóm",
  chatButtonText: "Đi đến trò chuyện",
  getRelationFailedText: "Không thể có được mối quan hệ",
  accountNotMatchText: "Không tìm thấy tài khoản nào như vậy",
  teamIdNotMatchText: "Không tìm thấy số nhóm như vậy",
  searchButtonText: "Tìm thấy",
  searchTeamPlaceholder: "Vui lòng nhập tên nhóm",
  teamTitle: "Tên nhóm",
  teamAvatarText: "Nhóm Avatar",
  addTeamMemberText: "Thêm thành viên",
  searchEmptyText: "Bạn chưa thêm bạn bè hoặc nhóm nào",
  searchNoResText: "Không tìm thấy kết quả tìm kiếm",
  searchFriendTitle: "Bạn",
  searchTeamTitle: "Nhóm",
  notSupportJoinText: "Không thể tham gia trực tiếp vào nhóm thảo luận, vui lòng liên hệ với người quản trị để thêm bạn vào nhóm",

  // chat-kit
  sendToText: "Gửi đến",
  sendUsageText: "(Nhấn enter để gửi trực tiếp, shift+enter để ngắt dòng)",
  sendEmptyText: "Không thể gửi tin nhắn trống",
  teamMutePlaceholder: "Nhóm trò chuyện hiện tại đã bị tắt tiếng",
  enterTeamText: "Đã vào nhóm",
  leaveTeamText: "Đã rời khỏi nhóm",
  teamMuteText: "Nhóm tắt tiếng",
  muteAllTeamSuccessText: "Đã bật thành công chức năng tắt tiếng cho tất cả thành viên",
  unmuteAllTeamSuccessText: "Đã chấm dứt lệnh cấm thành công đối với tất cả thành viên",
  muteAllTeamFailedText: "Không thể bật tắt tiếng cho tất cả thành viên",
  unmuteAllTeamFailedText: "Không thể kết thúc lệnh cấm",
  updateTeamSuccessText: "Sửa đổi thành công",
  updateTeamFailedText: "Sửa đổi không thành công",
  leaveTeamSuccessText: "Đã rời khỏi nhóm này thành công",
  leaveTeamFailedText: "Không thể rời khỏi nhóm này",
  dismissTeamSuccessText: "Nhóm đã được giải tán thành công",
  dismissTeamFailedText: "Nhóm tan rã không thành công",
  addTeamMemberSuccessText: "Thêm thành viên thành công",
  addTeamMemberFailedText: "Không thêm được thành viên",
  addTeamMemberConfirmText: "Vui lòng chọn thành viên nhóm bạn muốn thêm",
  removeTeamMemberText: "Xóa thành viên",
  removeTeamMemberConfirmText: "Bạn có chắc chắn muốn xóa thành viên này không?",
  removeTeamMemberSuccessText: "Thành viên đã bị xóa thành công",
  removeTeamMemberFailedText: "Không thể xóa thành viên",
  teamTitleConfirmText: "Tên nhóm không được để trống",
  teamAvatarConfirmText: "Avatar nhóm không được để trống",
  teamIdText: "ID nhóm",
  teamSignText: "Giới thiệu nhóm",
  teamTitlePlaceholder: "Vui lòng nhập tên nhóm",
  teamSignPlaceholder: "Vui lòng nhập nội dung",
  teamOwnerText: "Chủ nhóm",
  teamManagerText: "Quản trị viên nhóm",
  teamManagerEditText: "Quản lý con người",
  teamManagerEmptyText: "Không có quản trị viên nhóm",
  teamOwnerAndManagerText: "Chủ sở hữu và quản trị viên nhóm",
  updateTeamManagerSuccessText: "Sửa đổi quản trị viên nhóm thành công",
  updateTeamManagerFailText: "Không thể sửa đổi quản trị viên nhóm",
  userNotInTeam: "Thành viên không còn trong nhóm nữa",
  teamManagerLimitText: "Ai có thể chỉnh sửa thông tin?",
  teamInviteModeText: "Ai có thể mời thành viên mới",
  teamAtModeText: "Ai có thể @ mọi người",
  teamMemberText: "Thành viên nhóm",
  teamInfoText: "Thông tin nhóm",
  teamPowerText: "Quản lý nhóm",
  dismissTeamText: "Giải tán một nhóm",
  transferOwnerText: "Chuyển chủ sở hữu nhóm",
  newGroupOwnerText: "Trở thành chủ sở hữu nhóm mới",
  beAddTeamManagersText: "Được bổ nhiệm làm Quản trị viên",
  beRemoveTeamManagersText: "Đã xóa Quản trị viên",
  transferTeamFailedText: "Chuyển nhượng chủ sở hữu nhóm không thành công",
  transferToText: "Chuyển đến",
  transferTeamSuccessText: "Chuyển chủ sở hữu nhóm thành công",
  transferOwnerConfirmText: "Xác nhận việc chuyển nhượng chủ sở hữu nhóm",
  dismissTeamConfirmText: "Bạn có xác nhận giải tán nhóm không?",
  leaveTeamTitle: "Rời khỏi nhóm",
  leaveTeamConfirmText: "Bạn có chắc chắn muốn thoát khỏi nhóm này không?",
  personUnit: "mọi người",
  leaveTeamButtonText: "Xóa và thoát",
  sendMsgFailedText: "Gửi tin nhắn không thành công",
  getHistoryMsgFailedText: "Không lấy được tin nhắn lịch sử",
  sendBlackFailedText: "Bạn đã bị bên kia đưa vào danh sách đen",
  recallMessageText: "Đã rút lại một tin nhắn",
  recallReplyMessageText: "Tin nhắn này đã được rút lại hoặc xóa",
  reeditText: "Chỉnh sửa lại",
  addChatMemberText: "Thêm thành viên trò chuyện",
  chatHistoryText: "Lịch sử trò chuyện",
  noMoreText: "Không có thêm tin tức",
  receiveText: "Bạn đã nhận được một tin nhắn mới",
  strangerNotiText: "Người này hiện không phải là bạn của bạn. Hãy cẩn thận để bảo vệ quyền riêng tư của bạn.",
  nickInTeamText: "Biệt danh của tôi trong nhóm",
  editNickInTeamText: "Sửa biệt danh của tôi trong nhóm",
  updateMyMemberNickSuccess: "Đã cập nhật biệt danh nhóm của tôi thành công",
  updateMyMemberNickFailed: "Không cập nhật được biệt danh nhóm của tôi",
  updateBitConfigMaskSuccess: "Cập nhật tin nhắn nhóm không làm phiền thành công",
  updateBitConfigMaskFailed: "Cập nhật tin nhắn nhóm không làm phiền không thành công",
  onlineText: "[Trực tuyến]",
  offlineText: "(Ngoại tuyến)",

  // emoji 不能随便填，要用固定 key，，参考 demo
  Laugh: "[cười lớn]",
  Happy: "[vui mừng]",
  Sexy: "[màu sắc]",
  Cool: "[mát mẻ]",
  Mischievous: "[nụ cười nham hiểm]",
  Kiss: "[liên quan đến]",
  Spit: "[lè lưỡi]",
  Squint: "[nheo mắt]",
  Cute: "[dễ thương]",
  Grimace: "😊",
  Snicker: "[Cười]",
  Joy: "[vui sướng]",
  Ecstasy: "[sự sung sướng]",
  Surprise: "[sự ngạc nhiên]",
  Tears: "[nước mắt]",
  Sweat: "[mồ hôi]",
  Angle: "[Thiên thần]",
  Funny: "[cười và khóc]",
  Awkward: "[Vụng về]",
  Thrill: "[kinh hoàng]",
  Cry: "[khóc]",
  Fretting: "[bực tức]",
  Terrorist: "[nỗi sợ]",
  Halo: "[Những ngôi sao trong mắt tôi]",
  Shame: "[xấu hổ]",
  Sleep: "[ngủ]",
  Tired: "[Ngôi sao]",
  Mask: "[Khẩu trang]",
  ok: "[OK]",
  AllRight: "[Được rồi]",
  Despise: "[khinh thường]",
  Uncomfortable: "[Không thoải mái]",
  Disdain: "[Khinh thường]",
  ill: "[khó chịu]",
  Mad: "[sự tức giận]",
  Ghost: "[Yêu tinh]",
  Angry: "[Tức giận]",
  Unhappy: "[không vui]",
  Frown: "[cau mày]",
  Broken: "[Tan vỡ]",
  Beckoning: "[Nhịp tim]",
  Ok: "[ĐƯỢC RỒI]",
  Low: "[Mức thấp]",
  Nice: "[khen]",
  Applause: "[vỗ tay]",
  GoodJob: "[Mạnh mẽ]",
  Hit: "[đánh bạn]",
  Please: "[A Di Đà]",
  Bye: "[Tạm biệt]",
  First: "[Đầu tiên]",
  Fist: "[nắm đấm]",
  GiveMeFive: "[lòng bàn tay]",
  Knife: "[Kéo]",
  Hi: "[sóng]",
  No: "[không muốn]",
  Hold: "[Giữ]",
  Think: "[nghĩ]",
  Pig: "[Đầu heo]",
  NoListen: "[Không nghe]",
  NoLook: "[Đừng xem]",
  NoWords: "[Không được nói]",
  Monkey: "[con khỉ]",
  Bomb: "[bom]",
  Cloud: "[Mây lộn nhào]",
  Rocket: "[Tên lửa]",
  Ambulance: "[xe cứu thương]",
  Poop: "[phân]",

  session: "Phiên họp",
  addressText: "Liên hệ",
  sdkVersionSelectionText: "Lựa chọn phiên bản SDK",
  p2pMsgVisibleModeText: "Hiển thị tin nhắn p2p đã đọc và chưa đọc",
  teamMsgVisibleModeText: "Hiển thị tin nhắn nhóm là đã đọc hay chưa đọc",
  addFriendMode: "Thêm chế độ bạn bè",
  logoutText: "Đăng xuất",
  yesText: "Đúng",
  noText: "KHÔNG",
  needVerifyText: "Yêu cầu xác minh",
  notNeedVerifyText: "Không cần xác minh",
  oppositeSide: "phía bên kia",
  settingText: "cài đặt",
  logoutConfirmText: "Bạn có chắc chắn muốn đăng xuất không?",
  voiceCallText: "Cuộc gọi thoại",
  vedioCallText: "Gọi video",
  callTimeoutText2: "Hết thời gian và không trả lời",
  callBusyText2: "Bận rộn bỏ lỡ",
  networkDisconnectText: "Kết nối mạng bị mất, vui lòng kiểm tra cài đặt mạng của bạn và thử kết nối lại",
  callFailed: "Cuộc gọi không thành công",
  inCallText: "Đang gọi điện, vui lòng kết thúc cuộc gọi trước khi gọi lại",
  passFriendAskText: "Tôi đã chấp thuận đơn đăng ký của bạn, chúng ta hãy bắt đầu trò chuyện ngay bây giờ nhé~",
  needMentionText: "Bạn có cần @message không?",
  teamManagerEnableText: "Có nên bật chức năng quản trị viên nhóm không",
}

export default LocaleConfig
