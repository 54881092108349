const LocaleConfig = {
  // common
  saveText: "manter",
  setText: "Configurações",
  saveSuccessText: "Salvo com sucesso",
  saveFailedText: "Falha ao salvar",
  addFriendSuccessText: "Amigo adicionado com sucesso",
  applyFriendSuccessText: "Sua solicitação de amizade foi bem-sucedida!",
  addFriendFailedText: "Falha ao adicionar amigo",
  applyFriendFailedText: "Solicitação de amizade recusada",
  okText: "Claro",
  cancelText: "Cancelar",
  deleteText: "excluir",
  recallText: "retirar",
  forwardText: "Compartilhar",
  forwardFailedText: "Falha ao compartilhar",
  sendBtnText: "enviar",
  replyText: "Resposta",
  commentText: "mensagem",
  recentSessionText: "Visitas recentes",
  you: "você",
  deleteFriendText: "Apagando um amigo",
  confirmDeleteText: "Tem certeza que deseja excluir？",
  confirmDeleteFriendText: "Você está certo de excluir o amigo?",
  deleteFriendSuccessText: "Amigo removido com sucesso",
  deleteFriendFailedText: "Excluir amigo falhou",
  blackText: "Bloquear amigos",
  removeBlackText: "Desbloquear",
  blackSuccessText: "Bloqueado com sucesso",
  blackFailedText: "Falhou ao adicionar à lista negra",
  removeBlackSuccessText: "Desbloquear com sucesso",
  removeBlackFailedText: "Não foi possível remover da lista negra",
  maxSelectedText: "põe limite de escolha",
  selectedText: "Selecionado",
  friendsText: "um amigo",
  strangerText: "um estrangeiro",
  emailErrorText: "O formato do e-mail é inválido",
  uploadLimitText: "Tamanho máximo suportado para imagem ou arquivo",
  uploadLimitUnit: "M",
  uploadImgFailedText: "Falha ao enviar a imagem",
  accountText: "conta",
  nickText: "Apelido",
  genderText: "Gênero",
  phoneText: "celular",
  emailText: "e-mail",
  signText: "sinal",
  accountPlaceholder: "Por favor, insira sua conta",
  teamIdPlaceholder: "Por favor, digite o ID do grupo...",
  nickPlaceholder: "Por favor, insira seu apelido",
  genderPlaceholder: "Por favor, selecione seu gênero",
  phonePlaceholder: "Por favor, insira seu número de telefone",
  emailPlaceholder: "Por favor, insira seu e-mail",
  signPlaceholder: "Por favor, insira sua assinatura",
  searchInputPlaceholder: "Pesquisar amigos ou grupos",
  searchTeamMemberPlaceholder: "Pesquisar membros do grupo",
  searchText: "procurar",
  man: "macho",
  woman: "mulher",
  unknow: "Desconhecido",
  welcomeText: "Bem-vindo ao YunXin",
  notSupportMessageText: "Ainda não suportamos essa mensagem",
  applyTeamText: "Inscreva-se para participar do grupo",
  applyTeamSuccessText: "Solicitação de ingresso realizada com sucesso",
  rejectText: "rejeitar",
  acceptText: "Concordo",
  inviteTeamText: "Convido você a se juntar ao grupo",
  applyFriendText: "Adicionar você como amigo",
  acceptResultText: "Já concordou",
  rejectResultText: "Rejeitado",
  beRejectResultText: "Recusou a solicitação de amizade",
  passResultText: "Aceitou solicitação de amizade",
  rejectTeamInviteText: "Recusou a convite do grupo",
  updateTeamAvatar: "atualizou a foto do grupo",
  updateTeamName: "Editar o nome do grupo para",
  updateTeamIntro: "Atualizou a introdução do grupo",
  updateTeamInviteMode: "Updated a permissão do grupo 'Invitar outras pessoas' para",
  updateTeamUpdateTeamMode: "atualizou a permissão do grupo 'permissão de edição dos dados do grupo' para",
  updateAllowAt: "Atualizado &quot;@Permissões de todos&quot; para",
  updateTeamMute: "Atualizou o 'Silenciamento do grupo' para",
  onlyTeamOwner: "Somente o administrador do grupo",
  teamAll: "todas as pessoas",
  closeText: "encerramento",
  openText: "Abrir",
  inviteText: "convidar",
  aliasText: "Observação",
  updateAliasSuccessText: "Edição da observação realizada com sucesso.",
  updateAliasFailedText: "Editar nota falhou",
  sendText: "Enviar mensagem",
  noPermission: "Você não tem permissão para executar essa operação",
  unreadText: "Não lido",
  readText: "Ler",
  allReadText: "Tudo lido",
  amap: "Amap",
  txmap: "Mapas Tencent",
  bdmap: "Mapa Baidu",
  callDurationText: "Duração da ligação",
  callCancelText: "Cancelado",
  callRejectedText: "Rejeitado",
  callTimeoutText: "Excedido o tempo",
  callBusyText: "O outro está ocupado",
  // conversation-kit
  onDismissTeamText: "Grupo foi dissolvido",
  onRemoveTeamText: "Você foi removido do grupo",
  textMsgText: "Mensagem de texto",
  customMsgText: "Mensagem Personalizada",
  audioMsgText: "Áudiomensagem",
  videoMsgText: "mensagem de vídeo",
  fileMsgText: "Mensagem de arquivo",
  callMsgText: "Registro de chamadas e mensagens",
  geoMsgText: "Mensagem de localização",
  imgMsgText: "Fotomensagem",
  notiMsgText: "Notificação",
  robotMsgText: "Mensagem de máquina",
  tipMsgText: "Mensagem de aviso",
  unknowMsgText: "Mensagem desconhecida",
  deleteSessionText: "Excluir sessão",
  muteSessionText: "Ligar modo de não perturbe",
  unmuteSessionText: "Desativar modo de não perturbar",
  deleteStickTopText: "Desmarcar como destaque",
  addStickTopText: "Mensagem Fixa",
  beMentioned: "[Alguém @ mim]",
  imgText: "foto",
  videoText: "Vídeo",

  // contact-kit
  teamListTitle: "Meus Grupos",
  friendListTitle: "Meus amigos",
  blackListTitle: "lista negra",
  msgListTitle: "Centro de Notícias",
  blackListDesc: "você não receberá nenhuma mensagem de nenhum contato da lista",
  teamMenuText: "Meus Grupos",
  friendMenuText: "Meus amigos",
  blackMenuText: "lista negra",
  msgMenuText: "Centro de Notícias",
  acceptedText: "Pedido autorizado",
  acceptFailedText: "Concordo com a rejeição do pedido",
  rejectedText: "Sua solicitação foi recusada",
  rejectFailedText: "Recusar a solicitação falhou",
  getApplyMsgFailedText: "Falha ao obter mensagem",

  // search-kit
  addFriendText: "Adicionar amigos",
  createTeamText: "Criar um grupo",
  joinTeamText: "Junte-se a um grupo",
  joinTeamSuccessText: "Entrou no grupo com sucesso",
  beRemoveTeamText: "Foi removido(a) do grupo",
  addButtonText: "adicionar à",
  addSuccessText: "Adicionado com sucesso",
  addFailedText: "Adicionar falhou",
  createButtonText: "criar",
  createTeamSuccessText: "Grupo criado com sucesso",
  createTeamFailedText: "Falha ao criar grupo",
  chatButtonText: "Ir para o chat",
  getRelationFailedText: "Falha ao obter relação",
  accountNotMatchText: "Conta não encontrada",
  teamIdNotMatchText: "Grupo não encontrado",
  searchButtonText: "Encontrar",
  searchTeamPlaceholder: "Por favor, insira o nome do grupo",
  teamTitle: "Nome do grupo",
  teamAvatarText: "Foto de perfil do grupo",
  addTeamMemberText: "Adicionar membro",
  searchEmptyText: "você ainda não adicionou amigos e grupos",
  searchNoResText: "Nenhum resultado encontrado",
  searchFriendTitle: "Amigos",
  searchTeamTitle: "Grupos",
  notSupportJoinText: "Grupo de discussão não pode entrar diretamente, por favor, entre em contato com os administradores para que ele/ela te adicione ao grupo.",

  // chat-kit
  sendToText: "Enviar para",
  sendUsageText: "(Pressione enter para enviar diretamente, shift+enter para finalizar)",
  sendEmptyText: "Não pode enviar uma mensagem vazia.",
  teamMutePlaceholder: "O grupo está em silêncio por hora",
  enterTeamText: "Entrou no grupo",
  leaveTeamText: "Saiu do grupo",
  teamMuteText: "Grupo mudo",
  muteAllTeamSuccessText: "Todos os usuários foram silenciados com sucesso.",
  unmuteAllTeamSuccessText: "Terminar o silêncio de todos os membros com sucesso",
  muteAllTeamFailedText: "Falhou ao silenciar todos",
  unmuteAllTeamFailedText: "Falhou para desativar o silêncio de todos os membros",
  updateTeamSuccessText: "Modificação realizada com sucesso",
  updateTeamFailedText: "Modificação falhou",
  leaveTeamSuccessText: "saiu com sucesso do grupo",
  leaveTeamFailedText: "Não foi possível sair do grupo",
  dismissTeamSuccessText: "Grupo desfeito com sucesso",
  dismissTeamFailedText: "Falha ao dissolver o grupo",
  addTeamMemberSuccessText: "Membro adicionado com sucesso",
  addTeamMemberFailedText: "Falha ao adicionar membro",
  addTeamMemberConfirmText: "Por favor, selecione os membros que deseja adicionar à equipe",
  removeTeamMemberText: "Remover membro",
  removeTeamMemberConfirmText: "Confirma a remoção desse membro?",
  removeTeamMemberSuccessText: "Remover membro com sucesso",
  removeTeamMemberFailedText: "Remoção de membro falhou",
  teamTitleConfirmText: "Nome do grupo não pode estar vazio",
  teamAvatarConfirmText: "Foto de grupo não pode estar vazia",
  teamIdText: "ID do grupo",
  teamSignText: "Introdução do grupo",
  teamTitlePlaceholder: "Por favor, insira o nome do grupo",
  teamSignPlaceholder: "Por favor, insira o conteúdo",
  teamOwnerText: "Administrador",
  teamManagerText: "moderador de grupo",
  teamManagerEditText: "Gestão de Pessoal",
  teamManagerEmptyText: "Sem administrador do grupo",
  teamOwnerAndManagerText: "dono do grupo e administrador",
  updateTeamManagerSuccessText: "Modificação do administrador do grupo realizada com sucesso.",
  updateTeamManagerFailText: "Falha ao modificar administrador do grupo",
  userNotInTeam: "Membro já não está no grupo",
  teamManagerLimitText: "Quem pode modificar os dados",
  teamInviteModeText: "Quem pode convidar novos membros",
  teamAtModeText: "Quem pode @todo mundo",
  teamMemberText: "Membros do grupo",
  teamInfoText: "Informações do grupo",
  teamPowerText: "administração de grupo",
  dismissTeamText: "Dissolver um grupo",
  transferOwnerText: "Transferir administração do grupo",
  newGroupOwnerText: "seja o novo proprietário do grupo",
  beAddTeamManagersText: "Ser nomeado administrador",
  beRemoveTeamManagersText: "Administrador removido",
  transferTeamFailedText: "Falha ao transferir a administração do grupo",
  transferToText: "Transferir para",
  transferTeamSuccessText: "Transferência de admin feita com sucesso",
  transferOwnerConfirmText: "Confirmação da transferência do grupo",
  dismissTeamConfirmText: "Confirma o desmanche do grupo？",
  leaveTeamTitle: "Sair do grupo",
  leaveTeamConfirmText: "Você confirma a saída deste grupo?",
  personUnit: "pessoa",
  leaveTeamButtonText: "Excluir e sair",
  sendMsgFailedText: "Falhou ao enviar a mensagem",
  getHistoryMsgFailedText: "Falha ao obter histórico de mensagens",
  sendBlackFailedText: "Você foi adicionado à lista negra pelo outro usuário",
  recallMessageText: "Retirou uma mensagem",
  recallReplyMessageText: "Esta mensagem foi retirada ou excluída.",
  reeditText: "Reeditar",
  addChatMemberText: "Adicionar membros à conversa",
  chatHistoryText: "Registro de conversa",
  noMoreText: "Não há mais notícias",
  receiveText: "Você recebeu uma nova mensagem",
  strangerNotiText: "Actualmente, não é seu amigo. Por favor, proteja sua privacidade.",
  nickInTeamText: "Meu apelido no grupo",
  editNickInTeamText: "Editar meu apelido no grupo",
  updateMyMemberNickSuccess: "Atualizar meu apelido do grupo com sucesso",
  updateMyMemberNickFailed: "Atualizar meu apelido do grupo falhou.",
  updateBitConfigMaskSuccess: "Atualizado: mensagem do grupo em modo de não perturbar com sucesso.",
  updateBitConfigMaskFailed: "Atualização da mensagem do grupo 'não perturbe' falhou.",
  onlineText: "[On-line]",
  offlineText: "(Off-line)",

  // emoji 不能随便填，要用固定 key，，参考 demo
  Laugh: "[rindo alto]",
  Happy: "[feliz]",
  Sexy: "[cor]",
  Cool: "[legal]",
  Mischievous: "[risada maliciosa]",
  Kiss: "[Querido]",
  Spit: "[mostra a língua]",
  Squint: "[Frizar os olhos]",
  Cute: "[Fofinho]",
  Grimace: "[careta]",
  Snicker: "[Rindo]",
  Joy: "[alegria]",
  Ecstasy: "[êxtase]",
  Surprise: "[surpresa]",
  Tears: "[chorando]",
  Sweat: "[suor]",
  Angle: "[Anjo]",
  Funny: "[chorando de rir]",
  Awkward: "[Embaraço]",
  Thrill: "[horrorizado]",
  Cry: "[chorando]",
  Fretting: "[irritação]",
  Terrorist: "[temer]",
  Halo: "[Olhos arregalados]",
  Shame: "[tímido]",
  Sleep: "[adormecido]",
  Tired: "[dois pontos]",
  Mask: "[Máscara facial]",
  ok: "[OK]",
  AllRight: "[Tudo bem]",
  Despise: "[desprezar]",
  Uncomfortable: "[Desconfortável]",
  Disdain: "[desdém]",
  ill: "não me sinto bem",
  Mad: "[raiva]",
  Ghost: "[fantasma]",
  Angry: "[Nervoso]",
  Unhappy: "[infeliz]",
  Frown: "[carranca]",
  Broken: "[partido]",
  Beckoning: "[Desejo do coração]",
  Ok: "[Bom]",
  Low: "[Nível baixo]",
  Nice: "[ótimo]",
  Applause: "[aplaudir]",
  GoodJob: "[Poderoso]",
  Hit: "[bater em você]",
  Please: "[Amitaba]",
  Bye: "[Bye Bye]",
  First: "[primeiro]",
  Fist: "[punho]",
  GiveMeFive: "[Palma da mão]",
  Knife: "[Tesoura]",
  Hi: "[aceno]",
  No: "[Não]",
  Hold: "segurando",
  Think: "[pensar]",
  Pig: "[cabeça de porco]",
  NoListen: "[Não ouvir]",
  NoLook: "[Não ver]",
  NoWords: "[Não informar]",
  Monkey: "[macaco]",
  Bomb: "[bomba]",
  Cloud: "[Nuvem de cambalhota]",
  Rocket: "[Foguete]",
  Ambulance: "[ambulância]",
  Poop: "[cocô]",

  session: "Sessão",
  addressText: "Contatos",
  sdkVersionSelectionText: "SDK - Seleção de Versão",
  p2pMsgVisibleModeText: "Mostrar se as mensagens P2P estão lidas ou não lidas",
  teamMsgVisibleModeText: "Mostrar se as mensagens de grupo estão lidas ou não lidas",
  addFriendMode: "Modo de adicionar amigos",
  logoutText: "Sair da conta",
  yesText: "sim",
  noText: "Não",
  needVerifyText: "Requer verificação",
  notNeedVerifyText: "Não é necessário validar",
  oppositeSide: "o outro",
  settingText: "Configurações",
  logoutConfirmText: "Você realmente quer sair?",
  voiceCallText: "Conversa telefônica",
  vedioCallText: "Vídeo Chamada",
  callTimeoutText2: "Fora de hora",
  callBusyText2: "Ocupado",
  networkDisconnectText: "Sua conexão de rede foi desconectada. Por favor, verifique suas configurações de rede e tente reconnectar.",
  callFailed: "Falha na chamada",
  inCallText: "Em chamada, por favor, encerre a chamada e depois ligue novamente.",
  passFriendAskText: "Já concordei com o seu pedido, vamos começar a conversar~",
  needMentionText: "Precisa @mensagem",
  teamManagerEnableText: "Habilitar a função de administrador de grupo",
}

export default LocaleConfig
