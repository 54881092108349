const LocaleConfig = {
  // common
  saveText: "сохранять",
  setText: "настраивать",
  saveSuccessText: "Сохранено успешно",
  saveFailedText: "Сохранить не удалось",
  addFriendSuccessText: "Успешно добавлено в друзья",
  applyFriendSuccessText: "Приложение для успешного добавления друга",
  addFriendFailedText: "Не удалось добавить друга",
  applyFriendFailedText: "Заявка на добавление друга не удалась",
  okText: "Конечно",
  cancelText: "Отмена",
  deleteText: "удалить",
  recallText: "отзывать",
  forwardText: "Вперед",
  forwardFailedText: "Пересылка не удалась",
  sendBtnText: "отправлять",
  replyText: "отвечать",
  commentText: "сообщение",
  recentSessionText: "Недавние визиты",
  you: "ты",
  deleteFriendText: "Удаление друга",
  confirmDeleteText: "Вы уверены, что хотите удалить?",
  confirmDeleteFriendText: "Вы уверены, что хотите удалить друга?",
  deleteFriendSuccessText: "Удалить друга успешно",
  deleteFriendFailedText: "Не удалось удалить друга.",
  blackText: "Блокировать друзей",
  removeBlackText: "Разблокировать",
  blackSuccessText: "Черный список успеха",
  blackFailedText: "Черный список не пройден",
  removeBlackSuccessText: "Успешно разблокировано",
  removeBlackFailedText: "Разблокировать не удалось",
  maxSelectedText: "Вы можете выбрать только",
  selectedText: "Выбрано",
  friendsText: "Друзья",
  strangerText: "Незнакомец",
  emailErrorText: "Неверный формат электронной почты",
  uploadLimitText: "Максимальный поддерживаемый размер изображения или файла",
  uploadLimitUnit: "M",
  uploadImgFailedText: "Не удалось загрузить изображение.",
  accountText: "счет",
  nickText: "Псевдоним",
  genderText: "пол",
  phoneText: "сотовый телефон",
  emailText: "Почта",
  signText: "знак",
  accountPlaceholder: "Пожалуйста, введите номер вашего счета",
  teamIdPlaceholder: "Пожалуйста, введите идентификатор группы",
  nickPlaceholder: "Пожалуйста, введите ваш псевдоним",
  genderPlaceholder: "Пожалуйста, выберите пол",
  phonePlaceholder: "Введите ваш номер телефона",
  emailPlaceholder: "Пожалуйста, введите ваш адрес электронной почты",
  signPlaceholder: "Пожалуйста, введите вашу подпись",
  searchInputPlaceholder: "Поиск друзей или групп",
  searchTeamMemberPlaceholder: "Поиск участников группы",
  searchText: "поиск",
  man: "мужской",
  woman: "женский",
  unknow: "неизвестный",
  welcomeText: "Добро пожаловать в Юньсинь",
  notSupportMessageText: "Это сообщение в настоящее время не поддерживается.",
  applyTeamText: "Подать заявку на вступление в группу",
  applyTeamSuccessText: "Заявка на вступление в группу принята успешно",
  rejectText: "отклонять",
  acceptText: "соглашаться",
  inviteTeamText: "Приглашаю вас присоединиться к группе",
  applyFriendText: "Добавить вас в друзья",
  acceptResultText: "Согласованный",
  rejectResultText: "Отклоненный",
  beRejectResultText: "Запрос на добавление в друзья отклонен",
  passResultText: "Принятый запрос на добавление в друзья",
  rejectTeamInviteText: "Отклоненное групповое приглашение",
  updateTeamAvatar: "Обновленный аватар группы",
  updateTeamName: "Обновить название группы на",
  updateTeamIntro: "Обновленное введение группы",
  updateTeamInviteMode: "Обновлено групповое разрешение «Приглашать других» на",
  updateTeamUpdateTeamMode: "Обновлено групповое разрешение «Разрешение на изменение информации о группе» на",
  updateAllowAt: "Обновлены «Разрешения @Everyone» на",
  updateTeamMute: "Обновлено «Групповое отключение звука» на",
  onlyTeamOwner: "Только владельцы групп",
  teamAll: "Все",
  closeText: "закрытие",
  openText: "Открыть",
  inviteText: "приглашать",
  aliasText: "Замечание",
  updateAliasSuccessText: "Успешно изменить заметку",
  updateAliasFailedText: "Не удалось изменить комментарий.",
  sendText: "Отправить сообщение",
  noPermission: "У вас нет разрешения на эксплуатацию",
  unreadText: "Непрочитано",
  readText: "Читать",
  allReadText: "Все прочитано",
  amap: "Амап",
  txmap: "Карты Tencent",
  bdmap: "Карта Байду",
  callDurationText: "Время разговора",
  callCancelText: "Отменено",
  callRejectedText: "Отклоненный",
  callTimeoutText: "Истек срок ожидания",
  callBusyText: "Другая сторона занята",
  // conversation-kit
  onDismissTeamText: "Группа расформирована.",
  onRemoveTeamText: "Вы были удалены из группы",
  textMsgText: "Текстовые сообщения",
  customMsgText: "Пользовательское сообщение",
  audioMsgText: "Аудио сообщение",
  videoMsgText: "Видео сообщение",
  fileMsgText: "Файл Сообщение",
  callMsgText: "Сообщение о вызове",
  geoMsgText: "Местоположение Сообщение",
  imgMsgText: "Сообщение с изображением",
  notiMsgText: "Уведомительное сообщение",
  robotMsgText: "Машинное сообщение",
  tipMsgText: "Быстрое сообщение",
  unknowMsgText: "Неизвестное сообщение",
  deleteSessionText: "Удаление сеанса",
  muteSessionText: "Включить режим «Не беспокоить»",
  unmuteSessionText: "Отменить «Не беспокоить»",
  deleteStickTopText: "Открепить",
  addStickTopText: "Закрепленное сообщение",
  beMentioned: "[Кто-то @ мне]",
  imgText: "картина",
  videoText: "видео",

  // contact-kit
  teamListTitle: "Мои группы",
  friendListTitle: "Мои друзья",
  blackListTitle: "черный список",
  msgListTitle: "Центр сообщений",
  blackListDesc: "(Вы не будете получать сообщения ни от одного из контактов в списке)",
  teamMenuText: "Мои группы",
  friendMenuText: "Мои друзья",
  blackMenuText: "черный список",
  msgMenuText: "Центр сообщений",
  acceptedText: "Заявка одобрена.",
  acceptFailedText: "Одобрение заявки не удалось",
  rejectedText: "Заявка отклонена.",
  rejectFailedText: "Отклонение заявки не удалось",
  getApplyMsgFailedText: "Не удалось получить сообщение",

  // search-kit
  addFriendText: "Добавить друзей",
  createTeamText: "Создать группу",
  joinTeamText: "Присоединяйтесь к группе",
  joinTeamSuccessText: "Присоединился к группе успешно",
  beRemoveTeamText: "Удалено из группы",
  addButtonText: "добавить в",
  addSuccessText: "Успешно добавлено",
  addFailedText: "Добавить не удалось",
  createButtonText: "создавать",
  createTeamSuccessText: "Группа успешно создана",
  createTeamFailedText: "Не удалось создать группу",
  chatButtonText: "Перейти к чату",
  getRelationFailedText: "Не удалось получить связь",
  accountNotMatchText: "Такой аккаунт не найден",
  teamIdNotMatchText: "Такой номер группы не найден",
  searchButtonText: "Находить",
  searchTeamPlaceholder: "Введите название группы.",
  teamTitle: "Название группы",
  teamAvatarText: "Аватар группы",
  addTeamMemberText: "Добавить участников",
  searchEmptyText: "Вы еще не добавили ни одного друга или группы",
  searchNoResText: "Результаты поиска не найдены.",
  searchFriendTitle: "Друзья",
  searchTeamTitle: "Группы",
  notSupportJoinText: "К группе обсуждения нельзя присоединиться напрямую, пожалуйста, свяжитесь с администратором, чтобы он добавил вас в группу.",

  // chat-kit
  sendToText: "Отправить",
  sendUsageText: "(Нажмите Enter для прямой отправки, Shift+Enter для переноса)",
  sendEmptyText: "Невозможно отправить пустое сообщение.",
  teamMutePlaceholder: "Текущий групповой чат отключен.",
  enterTeamText: "Вступил в группу",
  leaveTeamText: "Покинул группу",
  teamMuteText: "Групповое отключение звука",
  muteAllTeamSuccessText: "Успешно включил функцию отключения звука для всех участников",
  unmuteAllTeamSuccessText: "Успешно снят запрет на всех участников",
  muteAllTeamFailedText: "Не удалось включить отключение звука для всех участников.",
  unmuteAllTeamFailedText: "Не удалось снять запрет",
  updateTeamSuccessText: "Модификация прошла успешно",
  updateTeamFailedText: "Модификация не удалась",
  leaveTeamSuccessText: "Успешно покинул эту группу",
  leaveTeamFailedText: "Не удалось покинуть эту группу",
  dismissTeamSuccessText: "Группа была успешно расформирована.",
  dismissTeamFailedText: "Расформирование группы не удалось",
  addTeamMemberSuccessText: "Добавление участника успешно",
  addTeamMemberFailedText: "Не удалось добавить участника",
  addTeamMemberConfirmText: "Пожалуйста, выберите участника группы, которого вы хотите добавить.",
  removeTeamMemberText: "Удалить участника",
  removeTeamMemberConfirmText: "Вы уверены, что хотите удалить этого участника?",
  removeTeamMemberSuccessText: "Участник успешно удален",
  removeTeamMemberFailedText: "Не удалось удалить участника",
  teamTitleConfirmText: "Имя группы не может быть пустым",
  teamAvatarConfirmText: "Аватар группы не может быть пустым",
  teamIdText: "Групповой идентификатор",
  teamSignText: "Введение в группу",
  teamTitlePlaceholder: "Введите название группы.",
  teamSignPlaceholder: "Пожалуйста, введите содержание",
  teamOwnerText: "Владелец группы",
  teamManagerText: "Администратор группы",
  teamManagerEditText: "Управление персоналом",
  teamManagerEmptyText: "Нет администраторов группы",
  teamOwnerAndManagerText: "Владельцы и администраторы групп",
  updateTeamManagerSuccessText: "Успешное изменение администратора группы",
  updateTeamManagerFailText: "Не удалось изменить администратора группы",
  userNotInTeam: "Участник больше не находится в группе",
  teamManagerLimitText: "Кто может редактировать информацию?",
  teamInviteModeText: "Кто может приглашать новых участников",
  teamAtModeText: "Кто может @всех",
  teamMemberText: "Члены группы",
  teamInfoText: "Информация о группе",
  teamPowerText: "Управление группой",
  dismissTeamText: "Расформировать группу",
  transferOwnerText: "Владелец группы передачи",
  newGroupOwnerText: "Станьте новым владельцем группы",
  beAddTeamManagersText: "Назначен администратором",
  beRemoveTeamManagersText: "Удален администратор",
  transferTeamFailedText: "Передача владельца группы не удалась",
  transferToText: "Передача в",
  transferTeamSuccessText: "Передача владельца группы прошла успешно",
  transferOwnerConfirmText: "Подтвердите передачу владельца группы",
  dismissTeamConfirmText: "Подтверждаете ли вы роспуск группы?",
  leaveTeamTitle: "Покинуть группу",
  leaveTeamConfirmText: "Вы уверены, что хотите выйти из этой группы?",
  personUnit: "люди",
  leaveTeamButtonText: "Удалить и выйти",
  sendMsgFailedText: "Отправка сообщения не удалась",
  getHistoryMsgFailedText: "Не удалось получить исторические сообщения.",
  sendBlackFailedText: "Вы были занесены в черный список другой стороной",
  recallMessageText: "Отозвано сообщение",
  recallReplyMessageText: "Это сообщение было отозвано или удалено",
  reeditText: "Повторно редактировать",
  addChatMemberText: "Добавить участников чата",
  chatHistoryText: "История чата",
  noMoreText: "Больше новостей нет",
  receiveText: "Вы получили новое сообщение",
  strangerNotiText: "Этот человек вам сейчас не друг. Пожалуйста, будьте осторожны, чтобы защитить свою конфиденциальность.",
  nickInTeamText: "Мой ник в группе",
  editNickInTeamText: "Изменить мой ник в группе",
  updateMyMemberNickSuccess: "Мой групповой псевдоним успешно обновлен",
  updateMyMemberNickFailed: "Не удалось обновить псевдоним моей группы.",
  updateBitConfigMaskSuccess: "Обновление сообщения группы «Не беспокоить» успешно выполнено",
  updateBitConfigMaskFailed: "Обновление группового сообщения «Не беспокоить» не удалось",
  onlineText: "[Онлайн]",
  offlineText: "(Оффлайн)",

  // emoji 不能随便填，要用固定 key，，参考 demo
  Laugh: "[смеяться вслух]",
  Happy: "[счастливый]",
  Sexy: "[цвет]",
  Cool: "[прохладный]",
  Mischievous: "[зловещая улыбка]",
  Kiss: "[родственник]",
  Spit: "[высовывает язык]",
  Squint: "[прищурившись]",
  Cute: "[милый]",
  Grimace: "[гримаса]",
  Snicker: "[Смеется]",
  Joy: "[радость]",
  Ecstasy: "[экстаз]",
  Surprise: "[сюрприз]",
  Tears: "[слезы]",
  Sweat: "[пот]",
  Angle: "[Ангел]",
  Funny: "[смех и плач]",
  Awkward: "[Неуклюжий]",
  Thrill: "[в ужасе]",
  Cry: "[плачет]",
  Fretting: "[раздражённо]",
  Terrorist: "[страх]",
  Halo: "[Звезды в моих глазах]",
  Shame: "[застенчивый]",
  Sleep: "[спать]",
  Tired: "[Звезда]",
  Mask: "[Медицинская маска]",
  ok: "[OK]",
  AllRight: "[Все в порядке]",
  Despise: "[презирать]",
  Uncomfortable: "[Неудобно]",
  Disdain: "[Презрение]",
  ill: "[неудобно]",
  Mad: "[злость]",
  Ghost: "[Гоблин]",
  Angry: "[Злой]",
  Unhappy: "[недовольный]",
  Frown: "[хмуриться]",
  Broken: "[Убитый горем]",
  Beckoning: "[Сердцебиение]",
  Ok: "[ХОРОШО]",
  Low: "[Низкий уровень]",
  Nice: "[хвалить]",
  Applause: "[аплодируют]",
  GoodJob: "[Мощный]",
  Hit: "[ударил тебя]",
  Please: "[Амитабха]",
  Bye: "[Пока-пока]",
  First: "[Первый]",
  Fist: "[кулак]",
  GiveMeFive: "[ладонь]",
  Knife: "[Ножницы]",
  Hi: "[волна]",
  No: "[не хочу]",
  Hold: "[удерживание]",
  Think: "[думать]",
  Pig: "[Голова свиньи]",
  NoListen: "[Не слушаю]",
  NoLook: "[Не смотреть]",
  NoWords: "[Не разглашается]",
  Monkey: "[обезьяна]",
  Bomb: "[бомбить]",
  Cloud: "[Облако-сальто]",
  Rocket: "[Ракета]",
  Ambulance: "[скорая помощь]",
  Poop: "[какать]",

  session: "Сессия",
  addressText: "Контакты",
  sdkVersionSelectionText: "Выбор версии SDK",
  p2pMsgVisibleModeText: "Отображать ли прочитанные и непрочитанные сообщения p2p",
  teamMsgVisibleModeText: "Отображать ли групповые сообщения как прочитанные или непрочитанные",
  addFriendMode: "Режим «Добавить друга»",
  logoutText: "Выйти",
  yesText: "да",
  noText: "нет",
  needVerifyText: "Требуется проверка",
  notNeedVerifyText: "Проверка не требуется",
  oppositeSide: "Другая сторона",
  settingText: "настраивать",
  logoutConfirmText: "Вы уверены, что хотите выйти?",
  voiceCallText: "Голосовой вызов",
  vedioCallText: "Видеозвонки",
  callTimeoutText2: "Тайм-аут и нет ответа",
  callBusyText2: "Занят Пропущено",
  networkDisconnectText: "Сетевое соединение потеряно, проверьте настройки сети и попробуйте подключиться заново.",
  callFailed: "Вызов не удался",
  inCallText: "Вы уже разговариваете по телефону. Пожалуйста, завершите разговор, прежде чем звонить снова.",
  passFriendAskText: "Я одобрил вашу заявку, давайте начнем общение прямо сейчас~",
  needMentionText: "Вам нужно @сообщение?",
  teamManagerEnableText: "Включать ли функцию администратора группы",
}

export default LocaleConfig
