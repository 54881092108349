const LocaleConfig = {
  // common
  saveText: "حفظ",
  setText: "الإعدادات",
  saveSuccessText: "تم الحفظ",
  saveFailedText: "فشل الحفظ",
  addFriendSuccessText: "تمت إضافة صديق",
  applyFriendSuccessText: "تمت إضافتك إلى الأصدقاء",
  addFriendFailedText: "فشل إضافة صديق",
  applyFriendFailedText: "فشل في إضافة صديق",
  okText: "مؤكد",
  cancelText: "الغاء",
  deleteText: "حذف",
  recallText: "ينسحب",
  forwardText: "تغريد",
  forwardFailedText: "فشل التثبيت",
  sendBtnText: "إرسال",
  replyText: "الرد",
  commentText: "تعليق",
  recentSessionText: "الزيارات الأخيرة",
  you: "أنت",
  deleteFriendText: "حذف صديق",
  confirmDeleteText: "تريد حذفه؟",
  confirmDeleteFriendText: "تريد حذف صديقك؟",
  deleteFriendSuccessText: "تم حذف العلة بنجاح",
  deleteFriendFailedText: "فشل حذف الصديق",
  blackText: "حظر الأصدقاء",
  removeBlackText: "إلغاء الحظر",
  blackSuccessText: "تم حظره",
  blackFailedText: "فشلت القائمة السوداء",
  removeBlackSuccessText: "تم رفع الحظر بنجاح",
  removeBlackFailedText: "فشل إلغاء الحظر",
  maxSelectedText: "يمكنك فقط الاختيار",
  selectedText: "المُنتَخَب",
  friendsText: "أصدقاء",
  strangerText: "غريب",
  emailErrorText: "صيغة البريد الإلكتروني غير صحيحة",
  uploadLimitText: "الحد الأقصى لحجم الصورة أو الملف المدعوم",
  uploadLimitUnit: "M",
  uploadImgFailedText: "فشل تحميل الصورة",
  accountText: "حساب",
  nickText: "اسم المستخدم",
  genderText: "الجنس",
  phoneText: "الهاتف النقال",
  emailText: "البريد الإلكتروني",
  signText: "التوقيع",
  accountPlaceholder: "يرجى إدخال الحساب",
  teamIdPlaceholder: "يرجى إدخال ID المجموعة",
  nickPlaceholder: "يرجى إدخال الاسم المستخدم",
  genderPlaceholder: "الرجاء اختيار الجنس",
  phonePlaceholder: "من فضلك أدخل رقم هاتفك",
  emailPlaceholder: "أدخل بريدك الإلكتروني",
  signPlaceholder: "يرجى إدخال التوقيع",
  searchInputPlaceholder: "ابحث عن صديق أو مجموعة",
  searchTeamMemberPlaceholder: "البحث عن أعضاء المجموعة",
  searchText: "يبحث",
  man: "رجل",
  woman: "انثى",
  unknow: "مجهول",
  welcomeText: "مرحباً بكم في YunXin",
  notSupportMessageText: "غير مدعوم في الوقت الحالي: الرسالة هذه",
  applyTeamText: "انضم إلى المجموعة",
  applyTeamSuccessText: "طلب الانضمام إلى المجموعة ناجح",
  rejectText: "يرفض",
  acceptText: "موافقة",
  inviteTeamText: "الانضمام إلى المجموعة",
  applyFriendText: "أضفني إلى أصدقائك",
  acceptResultText: "وافقت",
  rejectResultText: "مقبول",
  beRejectResultText: "رفضت طلب الصداقة",
  passResultText: "قام بقبول الطلب",
  rejectTeamInviteText: "رفض دعوة المجموعة",
  updateTeamAvatar: "تم تحديث الصورة الرمزية للمجموعة",
  updateTeamName: "تحديث اسم المجموعة إلى",
  updateTeamIntro: "تم تحديث وصف المجموعة",
  updateTeamInviteMode: " تم تحديث أذونات المجموعة ' دعوة أذونات أخرى ' من أجل ",
  updateTeamUpdateTeamMode: "تم تحديث إذن المجموعة &quot;إذن تعديل معلومات المجموعة&quot; إلى",
  updateAllowAt: "تم تحديث '@كل الأعضاء' إلى",
  updateTeamMute: "تم تحديث 'تعطيل المحادثات الجماعية' إلى",
  onlyTeamOwner: "لغيره من الملاك",
  teamAll: "الناس",
  closeText: "إغلاق",
  openText: "يبدأ",
  inviteText: "دعوة",
  aliasText: "ملاحظة",
  updateAliasSuccessText: "تم تعديل الملاحظة بنجاح",
  updateAliasFailedText: "فشل في تعديل التعليق",
  sendText: "إرسال الرسالة",
  noPermission: "ليس لديك إذن للعمل",
  unreadText: "غير مقروء",
  readText: "قرأ",
  allReadText: "تم القراءة جميعها",
  amap: "خرائط آلتوك",
  txmap: "خرائط تينسنت",
  bdmap: "خريطة بايدو",
  callDurationText: "مدة المكالمة",
  callCancelText: "تم إلغاؤه",
  callRejectedText: "مقبول",
  callTimeoutText: "منتهي المهلة",
  callBusyText: "الطرف الآخر مشغول",
  // conversation-kit
  onDismissTeamText: "تم حل المجموعة",
  onRemoveTeamText: "تم إخراجك من المجموعة",
  textMsgText: "نص رسالة",
  customMsgText: "رسالة مخصصة",
  audioMsgText: "رسالة صوتية",
  videoMsgText: "رسالة فيديو",
  fileMsgText: "رسالة ملفات",
  callMsgText: "سجل المكالمات والرسائل",
  geoMsgText: "الموقع الجغرافي للرسالة",
  imgMsgText: "رسالة الصورة",
  notiMsgText: "رسالة الإشعار",
  robotMsgText: "رسالة من النظام",
  tipMsgText: "رسالة سريعة",
  unknowMsgText: "رسالة مجهولة",
  deleteSessionText: "حذف الدردشة",
  muteSessionText: "السماح بالسكون",
  unmuteSessionText: "إلغاء الإعفاء من الاتصالات",
  deleteStickTopText: "ยก الغلاف",
  addStickTopText: "الإشعار المعلق",
  beMentioned: "[شخص ما @ لي]",
  imgText: "الصورة",
  videoText: "فيديو",

  // contact-kit
  teamListTitle: "المجموعة التي أملكها",
  friendListTitle: "أصدقائي",
  blackListTitle: "القائمة السوداء",
  msgListTitle: "مركز الإخبارات",
  blackListDesc: "لن تلقي أي جهة اتصال من القائمة رسائل",
  teamMenuText: "المجموعة التي أملكها",
  friendMenuText: "أصدقائي",
  blackMenuText: "القائمة السوداء",
  msgMenuText: "مركز الإخبارات",
  acceptedText: "وافقت على الطلب",
  acceptFailedText: "رفض الطلب المذكور",
  rejectedText: "تم رفض الطلب",
  rejectFailedText: "فشل رفض الطلب",
  getApplyMsgFailedText: "فشل في الاتصال",

  // search-kit
  addFriendText: "أضف صديقًا",
  createTeamText: "إنشاء مجموعة",
  joinTeamText: "انضم إلى المجموعة",
  joinTeamSuccessText: "انضم إلى المجموعة بنجاح",
  beRemoveTeamText: "تم إخراجك من المجموعة",
  addButtonText: "إضافة",
  addSuccessText: "تم الإضافة بنجاح",
  addFailedText: "فشل الإضافة",
  createButtonText: "إنشاء",
  createTeamSuccessText: "تم إنشاء المجموعة بنجاح",
  createTeamFailedText: "فشل إنشاء المجموعة",
  chatButtonText: "الدردشة",
  getRelationFailedText: "فشل في الحصول على العلاقة",
  accountNotMatchText: "هذا الحساب غير موجود",
  teamIdNotMatchText: "هذا المجموعة غير موجودة",
  searchButtonText: "بحث",
  searchTeamPlaceholder: "يرجى إدخال اسم الجماعي",
  teamTitle: "اسم المجموعة",
  teamAvatarText: "صورة المجموعة",
  addTeamMemberText: "أضف عضو",
  searchEmptyText: "لم يتم إضافة أي أصدقاء أو مجموعات Yet",
  searchNoResText: "عذرًا، لم يتم العثور على نتائج للبحث المطلوب.",
  searchFriendTitle: "أصدقاء",
  searchTeamTitle: "المجموعة",
  notSupportJoinText: "لا يمكن الانضمام لمجموعة المناقشة بشكل مباشر، يرجى التواصل مع المسؤول لإضافتك إلى المجموعة",

  // chat-kit
  sendToText: "إرسال إلى",
  sendUsageText: "اضغط على Enter لإرسال الفيديو مباشرةShift + Enter للتبديل إلى سطر جديد",
  sendEmptyText: "لا يمكنك إرسال رسالة فارغة",
  teamMutePlaceholder: "المجموعة مغلقة للدردشة حاليًا",
  enterTeamText: "انضم إلى المجموعة",
  leaveTeamText: "انسحب من المجموعة",
  teamMuteText: "منع من التحدث في المجموعة",
  muteAllTeamSuccessText: "تم تمكين وظيفة كتم الصوت بنجاح لجميع الأعضاء",
  unmuteAllTeamSuccessText: "تم إنهاء حظر الدردشة للجميع بنجاح",
  muteAllTeamFailedText: "فشل في تمكين كتم الصوت لجميع الأعضاء",
  unmuteAllTeamFailedText: "إنهاء السكتة الجماعية أخفق",
  updateTeamSuccessText: "تم التعديل بنجاح",
  updateTeamFailedText: "فشل التعديل",
  leaveTeamSuccessText: "تم مغادرة هذه المجموعة بنجاح",
  leaveTeamFailedText: "فشل في مغادرة هذه المجموعة",
  dismissTeamSuccessText: "تم تفريق المجموعة بنجاح",
  dismissTeamFailedText: "فشل إلغاء المجموعة",
  addTeamMemberSuccessText: "تمت إضافة العضو بنجاح",
  addTeamMemberFailedText: "فشل في إضافة العضو",
  addTeamMemberConfirmText: "الرجاء اختيار الأعضاء الذين تريد إضافتهم إلى المجموعة",
  removeTeamMemberText: "إزالة عضو",
  removeTeamMemberConfirmText: "هل تأكيد إزالة هذا العضو؟",
  removeTeamMemberSuccessText: "تم إزالة العضو",
  removeTeamMemberFailedText: "فشل إزالة العضو",
  teamTitleConfirmText: "اسم المجموعة يجب ألا يكون فارغًا",
  teamAvatarConfirmText: "لا يمكن أن يكون الصورة الرمزية للمجموعة فارغة",
  teamIdText: "ID المجموعة",
  teamSignText: "مقدمة المجموعة",
  teamTitlePlaceholder: "يرجى إدخال اسم الجماعي",
  teamSignPlaceholder: "กรúณา أدخل المحتوى",
  teamOwnerText: "المدير",
  teamManagerText: "مدير المجموعة",
  teamManagerEditText: "ادارة الموظفين",
  teamManagerEmptyText: "في الوقت الحالي لا يوجد أمناء للمنشور",
  teamOwnerAndManagerText: "مالك المجموعة و المشرف",
  updateTeamManagerSuccessText: "تم تغيير مسؤول المجموعة بنجاح",
  updateTeamManagerFailText: "فشل تعديل مدير المجموعة",
  userNotInTeam: "العضو لم يعد موجودا في المجموعة",
  teamManagerLimitText: "من يمكنه تعديل المعلومات؟",
  teamInviteModeText: "من يمكنه دعوة أعضاء جدد",
  teamAtModeText: "من يمكن @المجتمعين",
  teamMemberText: "أعضاء المجموعة",
  teamInfoText: "معلومات المجموعة",
  teamPowerText: "إدارة الجروب",
  dismissTeamText: "حل المجموعة",
  transferOwnerText: "تْرَاحُ الْمَلِكَ",
  newGroupOwnerText: "كن مالك المجموعة الجديدة",
  beAddTeamManagersText: "تم تعيينه كمدير",
  beRemoveTeamManagersText: "المدير المRemoved",
  transferTeamFailedText: "فشل نقل المدير",
  transferToText: "نقل إلى",
  transferTeamSuccessText: "تم نقل مالك المجموعة بنجاح",
  transferOwnerConfirmText: "هل تأكد من تناقل المَلَك",
  dismissTeamConfirmText: "هل تأكد من إلغاء المجموعة؟",
  leaveTeamTitle: "مغادرة المجموعة",
  leaveTeamConfirmText: "هل تأكد من مغادرة المجموعة",
  personUnit: "الإنسان",
  leaveTeamButtonText: "حذف وخروج",
  sendMsgFailedText: "فشل إرسال الرسالة",
  getHistoryMsgFailedText: "فشل في الحصول على الرسائل السابقة",
  sendBlackFailedText: "لقد تم إدراجك في القائمة السوداء للطرف الآخر",
  recallMessageText: "انسحب رسالة",
  recallReplyMessageText: "الرسالة تم سحبها أو حذفها",
  reeditText: "تحرير مجدد",
  addChatMemberText: "إضافة أعضاء الدردشة",
  chatHistoryText: "سجل الدردشة",
  noMoreText: "لا مزيد من الأخبار",
  receiveText: "لقد تلقيت رسالة جديدة",
  strangerNotiText: "هذا الشخص ليس صديقك حاليًا. يُرجى توخي الحذر لحماية خصوصيتك.",
  nickInTeamText: "لقبي في المجموعة",
  editNickInTeamText: "تعديل لقبي في المجموعة",
  updateMyMemberNickSuccess: "تم تحديث لقب مجموعتي بنجاح",
  updateMyMemberNickFailed: "فشل في تحديث اسم مجموعتي",
  updateBitConfigMaskSuccess: "تم تحديث رسالة المجموعة &quot;لا تزعج&quot; بنجاح",
  updateBitConfigMaskFailed: "فشل تحديث رسالة المجموعة &quot;عدم الإزعاج&quot;",
  onlineText: "[متصل]",
  offlineText: "(غير متصل)",

  // emoji 不能随便填，要用固定 key，，参考 demo
  Laugh: "[يضحك بصوت عال]",
  Happy: "[سعيد]",
  Sexy: "[لون]",
  Cool: "[رائع]",
  Mischievous: "[ابتسامة شريرة]",
  Kiss: "[نسبي]",
  Spit: "[يخرج لسانه]",
  Squint: "[التحديق]",
  Cute: "[لطيف]",
  Grimace: "[كشر]",
  Snicker: "[يضحك]",
  Joy: "[مرح]",
  Ecstasy: "[نشوة]",
  Surprise: "[مفاجأة]",
  Tears: "[دموع]",
  Sweat: "[يعرق]",
  Angle: "[ملاك]",
  Funny: "[يضحك ويبكي]",
  Awkward: "[محرج]",
  Thrill: "[مذعور]",
  Cry: "[بكاء]",
  Fretting: "[منزعج]",
  Terrorist: "[يخاف]",
  Halo: "[النجوم في عيني]",
  Shame: "[خجول]",
  Sleep: "[ينام]",
  Tired: "[نجم]",
  Mask: "[قناع وجه]",
  ok: "[OK]",
  AllRight: "[حسنًا]",
  Despise: "[احتقار]",
  Uncomfortable: "[غير مريح]",
  Disdain: "[ازدراء]",
  ill: "[غير مريح]",
  Mad: "[غضب]",
  Ghost: "[عفريت]",
  Angry: "[غاضب]",
  Unhappy: "[تعيس]",
  Frown: "[عبوس]",
  Broken: "[محطم القلب]",
  Beckoning: "[نبض القلب]",
  Ok: "[نعم]",
  Low: "[مستوى منخفض]",
  Nice: "[مدح]",
  Applause: "[تصفيق]",
  GoodJob: "[قوي]",
  Hit: "[ضربك]",
  Please: "[أميتابها]",
  Bye: "[وداعا وداعا]",
  First: "[أولاً]",
  Fist: "[قبضة]",
  GiveMeFive: "[نخل]",
  Knife: "[مقص]",
  Hi: "[موجة]",
  No: "[لا أريد]",
  Hold: "[حامل]",
  Think: "[يفكر]",
  Pig: "[رأس الخنزير]",
  NoListen: "[لا يستمع]",
  NoLook: "[لا تشاهد]",
  NoWords: "[لا ينبغي أن يقال]",
  Monkey: "[قرد]",
  Bomb: "[قنبلة]",
  Cloud: "[سحابة الشقلبة]",
  Rocket: "[صاروخ]",
  Ambulance: "[سيارة إسعاف]",
  Poop: "[يتبرّز]",

  session: "المحادثة",
  addressText: "جهات الاتصال",
  sdkVersionSelectionText: "تحديد إصدار SDK",
  p2pMsgVisibleModeText: "هل تظهر حالة الرسائل p2p (قرأ / لم يقرأ)؟",
  teamMsgVisibleModeText: "هل تظهر رسائل المجموعة كـ unread أو read",
  addFriendMode: "وضع إضافة الصديق",
  logoutText: "تسجيل خروج",
  yesText: "نعم",
  noText: "لا",
  needVerifyText: "يحتاج إلى التأكد والتحقق",
  notNeedVerifyText: "غير مطلوب التحقق",
  oppositeSide: "الطرف الآخر",
  settingText: "الإعدادات",
  logoutConfirmText: "هل تأكد من تسجيل الخروج؟",
  voiceCallText: "الدردشة الصوتيyah",
  vedioCallText: "الاتصال المرئي",
  callTimeoutText2: "متأخر للرد",
  callBusyText2: "مشغول فاتني",
  networkDisconnectText: "تم فقد اتصال الشبكة، يرجى التحقق من إعدادات الشبكة ومحاولة إعادة الاتصال",
  callFailed: "فشل الاتصال",
  inCallText: "أنت الآن في مكالمة، يرجى إنهاء المكالمة قبل الاتصال مرة أخرى",
  passFriendAskText: "لقد وافقت على طلبك، فلنبدأ الدردشة الآن~",
  needMentionText: "هل تحتاج إلى @message؟",
  teamManagerEnableText: "ما إذا كان سيتم تمكين وظيفة مسؤول المجموعة",
}

export default LocaleConfig
