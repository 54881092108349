const tr = {
  locale: 'tr',
  messages: {
    "1周内容": "Hafta içeriği",
    "1月": "Ocak",
    "1月内": "Ocak içinde",
    "2": "2",
    "2月": "Şubat",
    "3天内": "3 gün içinde",
    "3月": "Mart",
    "4S价": "4S price",
    "4S价格": "4S price",
    "4月": "April",
    "5月": "May ıs",
    "6月": "Haziran",
    "7月": "Temmuz",
    "8月": "Ağustos",
    "9月": "Eylül",
    "10月": "Ekim",
    "11月": "Kasım",
    "12月": "Aralık",
    "100%正品，假一赔三": "Yüzde 100 gerçek, üçünü sahte bir ürün için ödüllendirin.",
    "123": "yüz yirmi üç",
    "2023年上半年我们的品牌WOMALA丰富了客户未来的选择。 我们致力于成为国际认可的行业领先品牌": "2023 yılın ilk yarısında, müşterilerimizin gelecekte seçimlerini zenginleştirdik. Biz uluslararasıyla tanınan endüstri yönetici marka olarak",
    "2023年暂无业绩考核": "2023 yılında performans değerlendirmesi yok.",
    "11111": "On bir bin yüz on bir",
    "111111": "yüz on bir bin yüz on bir",
    " 订单来了": "订单来了",
    "(含税)": "(vergi dahil)",
    "(选填)公司地址": "Şirket adresi",
    "(选填)公司电话": "Şirketi telefon numarası",
    "(选填)开户银行": "(İhtiyarlı) Aç Bankası",
    "(选填)请输入公司地址": "Lütfen şirketin adresini girin",
    "(选填)请输入公司电话": "Lütfen şirketin telefon numarasını girin.",
    "(选填)请输入开户银行": "Lütfen hesap açma bankasını girin",
    "(选填)请输入银行账户": "Lütfen banka hesabınızı girin",
    "(选填)银行账户": "Banka hesabı",
    "*修改此模版下所有商品！": "* Bu şablon altında tüm ürünleri değiştirin!",
    "*所有新增客户挂帐额度，将默认为此金额！": "*Tüm yeni eklenmiş müşteri kredi sınırları bu miktarın önbellenecek!",
    "*根据客户关联的价格模版，使用对应报价模版；若没有关联则使用系统默认模版。": "* Müşteriyle alakalı fiyat örneklerine göre, uyumlu sitasyon örneklerini kullanın; Eğer bağlantı yoksa, sistem öntanımlı şablonu kullanın.",
    "APP": "APP",
    "EPC查询": "EPC sorgulaması",
    "ERP产品名称": "ERP Produkt İsmi",
    "ERP产品编码": "ERP ürün kodu",
    "ERP价格": "ERP fiyatı",
    "ERP价格￥": "ERP fiyatı 65509;",
    "ERP品质": "ERP kalitesi",
    "ERP库存": "ERP inventörü",
    "ERP开单": "ERP faturası",
    "ERP系统用户名错误将无法开单！": "ERP sistem kullanıcı hatası hesap almayı engelleyecek!",
    "Logo图片": "Logo resimi",
    "OE号": "OE numarası",
    "OE号搜索": "OE Sayı Arama",
    "OE搜索": "OE Arama",
    "OSSCA1993年开始进入汽配行业，2000年正式注册OSSCA商标，服务全球售后市场，产品以严苛的标准，卓越的品质在 100 多个国家广泛流通。主营Audi、Benz、BMW、路虎等欧系豪华车零部件，目前涵盖 100多个产品系列，11万余种产品型号，所有 OSSCA产品均提供 12-24个月质保。我们将继续秉承专业、全面、放心的服务理念打造 OSSCA品牌，致力于成为全球汽车后市场的一站式卓越品牌。": "OSSCA 1993 yılında otomatik parçaları endüstriye girdi ve 2000 yılında resmi olarak OSSCA ticaret markasını kaydedi, küresel satış pazarına hizmet ediyordu. Produklarımız 100 ülkeden fazla sıkı standartlar ve harika kaliteli ile yayılıyor. Audi, Benz, BMW ve Land Rover gibi Avrupa luksuz araba komponentlerinde özelliklerimiz var. Şu and a 100 ürün serisinden fazla ve 110000 ürün modellerinden fazla örtüyoruz. Tüm OSSCA ürünlerinin 12-24 ay garantisi var. OSSCA markasını in şa etmek için profesyonel, bütünlü ve güvenilir hizmet konseptini desteklemeye devam edeceğiz. Küresel otomatik pazarında mükemmel bir marka olmaya karar verdik.",
    "OSSCA1993年开始进入汽配行业，2000年正式注册OSSCA商标，服务全球售后市场，产品以严苛的标准，卓越的品质在 100 多个国家广泛流通。主营奥迪、奔驰、宝马、路虎等欧系豪华车零部件，目前涵盖 100多个产品系列，11万余种产品型号，所有 OSSCA产品均提供 12-24个月质保。我们将继续秉承专业、全面、放心的服务理念打造 OSSCA品牌，致力于成为全球汽车后市场的一站式卓越品牌。": "OSSCA 1993 yılında otomatik parçaları endüstriye girdi ve 2000 yılında resmi olarak OSSCA ticaret markasını kaydedi, küresel satış pazarına hizmet ediyordu. Produklarımız 100 ülkeden fazla sıkı standartlar ve harika kaliteli ile yayılıyor. Audi, Mercedes Benz, BMW, Land Rover gibi Avrupa luksuz araba komponentlerinde özelliklerimiz var. Şu and a 100 ürün serisinden fazla ve 110000 ürün modellerinden fazla örtüyoruz. Tüm OSSCA ürünlerinin 12-24 ay garantisi var. OSSCA markasını in şa etmek için profesyonel, bütünlü ve güvenilir hizmet konseptini desteklemeye devam edeceğiz. Küresel otomatik pazarında mükemmel bir marka olmaya karar verdik.",
    "OSSCA：以卓越品质服务全球售后市场": "OSSCA",
    "SKU数量": "SKU miktarı",
    "VIN查询": "VIN soruşturması",
    "VIN查询2": "VIN query 2",
    "VIN码识别": "VIN code recognition",
    "Vin查询": "Vin Sorgulaması",
    "WEB商城": "WEB Mall",
    "apple": "Apple",
    "h5": "h5",
    "hello": "Merhaba.",
    "logo": "logo",
    "logo图片": "Logo resimi",
    "x 付款": "X Payment",
    "x秒后自动跳转我的订单": "Sıramı x saniye içinde otomatik olarak yeniden yönlendir",
    "{name}秒后自动跳转我的订单": "{name} saniyeden sonra emrimi otomatik olarak yeniden düzenle",
    "《用户注册协议》": "Kullanıcı Kayıt Anlaşması",
    "《用户隐私协议》": "Kullanıcı Özel Anlaşması",
    "一线品牌": "İlk katı markaları",
    "万": "on bin",
    "上下架": "Yukarı ve aşağı raflar",
    "上下架编辑": "Yukarı ve aşağı düzenleme",
    "上传": "upload",
    "上传公司名片": "Şirket iş kartı yükle",
    "上传发货凭证": "Yükleme verici yükle",
    "上传图片大小必须小于2MB!": "Yüklenen görüntüsün boyutu 2MB'den az olmalı!",
    "上传图片大小必须小于 2MB!": "Yüklenen görüntüsün boyutu 2MB'den az olmalı!",
    "上传失败！": "Yükleme başarısız oldu!",
    "上传收货凭证": "Alış verici yükle",
    "上传清晰照片，可以自动识别并填充相关信息！": "Temiz fotoğrafları yükleyin ve bağlı bilgileri otomatik olarak tanıyın ve doldurun!",
    "上传营业执照": "İş lisansını yükle",
    "上传营业执照，可以自动识别并填充相关信息！": "İş lisansını otomatik olarak belirleyebilir ve bilgileri doldurabilir.",
    "上架": "rafları koyun.",
    "上涨": "yükselt",
    "上询列表": "Sorgulama Listesi",
    "下单时间": "Düzenleme vakti",
    "下架": "Rüflerden çıkar",
    "下载": "indir",
    "下降": "reddetme",
    "不分享": "Paylaşma",
    "不分享商品": "Ürüntüleri paylaşma",
    "不删": "Silme",
    "不含税总价": "Vergi dışında toplam fiyat",
    "不执行": "Çalıştırılmadı",
    "不锁": "Kilitli değil",
    "专业的汽配商城": "Profesyonel otomatik parçaları Merkezi",
    "专业的汽配查询工具": "Profesyonel otomatik parçaları sorgulama aracı",
    "专注于研发和销售高性能的汽车零部件": "Yüksek performanslı otomatik komponentleri araştırma ve satma konusunda",
    "专用发票": "Özel hesap",
    "专票": "Özel bilet",
    "业绩考核": "Performans değerlendirmesi",
    "个人": "kişisel",
    "中文": "Chinese",
    "丰田专区": "Toyota Bölgesi",
    "主组": "Ana grup",
    "买家备注": "Satın alıcının s özlerini",
    "二级分类": "İkinci klasifikasyon",
    "二维码登录": "QR kodu giriş",
    "交易流水号": "Transaksyon seri numarası",
    "产品供应稳定，品类齐全，更专业的服务团队": "Stable product supply, complete product categories, and a more professional service team",
    "产品名称": "Üretim İsmi",
    "产品编码": "Üretim kodu",
    "产地": "üretim alanı",
    "产地/品质名称": "Kaynak/kalite İsmi",
    "产地不唯一，请先选择产地": "Kaynak eşsiz değildir, lütfen önce kaynak seçin",
    "人员": "personel",
    "人工审核": "Elle görüntüle",
    "人民币": "RMB",
    "人民币大写": "Çin Yuan, başkent harflerinde",
    "仅退款": "Sadece ödeme",
    "今日": "Bugün",
    "仓位": "Konum",
    "仓库": "warehouse",
    "付款": "ödeme",
    "付款时间": "Ödeme zamanı",
    "以下产品已存在，是否覆盖现有产品？": "Bu ürünler zaten mevcut ürünleri kapatıyor mu?",
    "以卓越品质服务全球售后市场": "Küresel satış pazarına mükemmel kaliteliyle hizmet etmek",
    "件数": "Parçalar sayısı",
    "价格模板(可多选)": "Ödül Şablonu (Çoklu Seçim)",
    "价格模版": "Ödül Şablonu",
    "价格由低到高": "Ödül düşük ve yüksek",
    "价格由高到低": "Ödül yüksekten düşük",
    "价格设置": "Ödül Ayarlaması",
    "企业微信": "WeCom",
    "企业认证": "Şirket sertifikası",
    "会员价格": "Üye fiyatları",
    "传动系统": "drive system",
    "传扶": "Chuanfu",
    "传真": "facsimile",
    "你们所有的货物在交货前都要检验吗？": "Tüm mallarınız teslim etmeden önce kontrol edilmeli mi?",
    "你们的交货时间怎么样？": "Teslim zamanınız nasıl?",
    "你们的样本政策是什么？": "Örnek politikanız nedir?",
    "你们的质量保证期是多久？": "Senin kalite güvenlik dönemin ne kadar sürer?",
    "你们能根据样品生产吗？": "Örneklere dayanabilir misiniz?",
    "你只可以上传jpeg、jpg、png格式!": "Sadece jpeg, jpg ve png formatlarında yükleyebilirsiniz!",
    "你可以上传 JPG/PNG 文件!": "You can upload JPG/PNG files!",
    "你可以上传JPG/PNG文件!": "You can upload JPG/PNG files!",
    "使用零零汽账号登录": "Sıfır sıfır araba hesabı ile gir",
    "供应品牌": "Temsil markası",
    "供应商": "teminatçı",
    "供应商名称": "Teminatçı İsmi",
    "供应商状态": "Teminatçı durumu",
    "供应商管理": "Sunucu Yönetimi",
    "供应商类型": "Sunucu türü",
    "供应商详细地址": "Teminatçının detaylı adresi",
    "俄文": "Rusça",
    "保存": "korumaNote this is a KRunner keyword",
    "保险公司": "Sigorta şirketi",
    "修改": "değiştir",
    "修改价格模版": "Fiyat şablonu değiştir",
    "修改信息": "Bilgi değiştiriliyor",
    "修改公司信息": "Şirket Bilgisini Değiştirme",
    "修改发票": "Modify invoice",
    "修改地址": "Adresi değiştir",
    "修改成功": "Başarılı değiştirildi",
    "修改成功！": "Başarılı değiştirildi!",
    "修改标签": "Etiketleri değiştir",
    "修理厂": "Dükkanı düzenleyin",
    "偏好设置": "Tercih ayarları",
    "催收单": "Kolleksyon notu",
    "催收文本": "Koleksiyon Metni",
    "免费注册": "Özgür kayıt",
    "全选": "Hepsini Seç",
    "全部": "Tüm",
    "全部定价商品": "Bütün fiyatlı ürünler",
    "公司": "şirket",
    "公司名片": "Şirket iş kartı",
    "公司名称": "Şirket İsmi",
    "公司注册城市": "Şirket kayıt şehri",
    "公司税号": "Şirket vergi numarası",
    "公司管理": "Şirket yönetimi",
    "公司类型": "Company type",
    "公司详细地址": "Şirket detaylı adresi",
    "公司：": "Şirketi",
    "共x个": "Toplam X",
    "关于我们": "Bizim hakkımızda.",
    "关注微信公众号": "WeChat resmi hesabını takip et",
    "关联商品": "İlişkili ürünler",
    "关闭": "kapat",
    "其他": "diğer",
    "其它照片": "Diğer fotoğraflar",
    "内贸团队": "Ev ticaret ekibi",
    "写跟进": "İzleme yaz",
    "冷却单元": "Soğuk birimi",
    "冷却系统": "Soğuk sistemi",
    "净重": "Ağ kilo",
    "减价": "Ödül azaltma",
    "出库数": "Dışarı bağlı miktarı",
    "分享": "paylaş",
    "分享商品": "Ürüntüleri paylaşın",
    "分享状态": "Paylaş durumu",
    "分公司": "branch office",
    "分类": "klasifikasyon",
    "分组": "grouping",
    "分配": "dağıtım",
    "分配单": "Bölüm sayfası",
    "分销商管理": "Dağıtıcı Yönetimi",
    "切换公司": "Şirketleri değiştirme",
    "列表": "list",
    "创建人": "Oluşturucu",
    "创建时间": "Oluşturma zamanı",
    "删": "Sil",
    "删除": "Sil",
    "删除商品": "Ürünü sil",
    "删除商品?": "Ürünü silmek mi?",
    "删除成功": "Başarılı sil",
    "删除成功！": "Başarılı sil!",
    "删除订单": "Düzeni sil",
    "删除记录": "Kayıt Sil",
    "制动主缸类": "Brek master cilinder türü",
    "制动盘类": "Disk türü",
    "制动系统": "Frenme sistemi",
    "制动轮缸类": "Brek tekerlik cilinder türü",
    "制单": "Bir belge oluştur",
    "制单日期": "Üretim tarihi",
    "刹车": "Frek",
    "前往处理": "Git ve hallet.",
    "功能": "fonksiyon",
    "加价": "Ceza yükselmesi",
    "加入时间": "Çıkış Zamanı",
    "加入购物车": "Alışveriş arabasına ekle",
    "加入购物车成功": "Alışveriş arabasına başarıyla eklendi",
    "助力器总成类": "Küçük toplama kategorisi",
    "勾选即表示同意": "Anlaşmayı göstermek için kontrol edin",
    "包装员": "Paketleme personeli",
    "包裹": "paket",
    "包裹x": "Paket x",
    "北京时间": "PekinCountry name (optional, but should be translated)",
    "华东仓": "Huadong Warehouse",
    "单价": "Birim Ödülü",
    "单位地址": "Birim adresi",
    "单号": "Order number",
    "单据列表": "Belgelerin listesi",
    "博士": "Doktor.",
    "卡钳带转向节总成类": "Kıpırdama toplantısıyla kaliper.",
    "卡钳总成类": "Kaliper toplantıları",
    "危险品": "Tehlikeli mallar",
    "厂商": "üretici",
    "历史记录": "Tarihi",
    "原厂OE": "Orijinal OE",
    "原厂OE号": "Orijinal fabrika OE numarası",
    "原厂品质": "Orijinal fabrika kalitesi",
    "原厂大库": "Orijinal fabrika deposu",
    "原厂替换件": "Önce değiştirme parçaları",
    "原厂订货": "Orijinal fabrika emri",
    "去查看": "Git kontrol et",
    "去看看": "Go take a look",
    "去确认": "Go confirm",
    "去认证": "Sahihlenme için git",
    "参加广交会。": "Participate in the Canton Fair.",
    "参数": "Parametre",
    "参考价格": "Referans fiyatı",
    "反馈": "Feedback",
    "反馈内容": "Feedback içeriği",
    "反馈列表": "Feedback Listesi",
    "发": "saç",
    "发动机": "motor",
    "发动机型号": "Motor modeli",
    "发动机查询": "Motor sorgulaması",
    "发布询价": "Soruşturma yayınlayın",
    "发短信": "Mesaj gönderir",
    "发短信，将发送到公司管理员": "Şirket yöneticisine mesaj gönder",
    "发票": "fatura",
    "发票管理": "hesaplar üzerinde kontrol",
    "发票类型": "增值税纸质普通发票",
    "发货": "malları teslim et",
    "发货信息": "Gemi Bilgisi",
    "发货凭证": "Delivery voucher",
    "发货单信息": "Telefon notları bilgisi",
    "发货员": "Kaptan.",
    "发货地址：": "Gemi adresi",
    "发货成功！": "Teslimat başarılı!",
    "发货时间": "Teslim zamanı",
    "发送短信": "SMS gönder",
    "取消": "cancel",
    "取消售后": "Satış hizmeti iptal et",
    "取消时间": "Vaktini iptal et",
    "取消申请": "çekilin",
    "取消申请，订单将恢复正常！": "Uygulamayı iptal et, emir normal dönecek!",
    "变更": "değiştirme",
    "变速箱": "Transmission",
    "变速箱类型": "Transmission türü",
    "可上传多张，每次1张，大小不超过15M": "Birçok resim yüklenebilir, birden birden birden, 15M'den fazla boyutla",
    "可以，我们可以根据您的样品或技术图纸进行生产。我们可以做模具和固定装置。": "Sure, we can produce according to your samples or technical drawings. Tuvalet ve aygıtlar düzeltebiliriz.",
    "可以，我们接受贴牌生产。": "Elbette, OEM üretimi kabul ediyoruz.",
    "号码": "Numara",
    "合 计": "toplam",
    "合作意向": "İşbirliği yapmak niyeti",
    "合作电话": "İşbirliği telefon numarası",
    "合计": "toplam",
    "合资车系": "Birleşik teknik araba serisi",
    "同意": "Anlaşıldı.",
    "同意/拒绝": "Anlaş/Red",
    "名称": "isim",
    "名称/编码/OE": "İsim/Kod/OE",
    "否": "Hayır.",
    "含税": "Veri dahil",
    "含税价": "Vergi dahil",
    "启用": "Etkinleştir",
    "员工": "personel",
    "和": "and",
    "品牌": "brand",
    "品牌专区": "Brand Zone",
    "品牌编号": "Marka numarası",
    "品类": "category",
    "品类分类": "Kategori klasifikasyonu",
    "品质": "kalitesi",
    "品质(含税价)": "Kvalit (vergi fiyatı dahil)",
    "品质/品牌": "Keyfiyeti/Marka",
    "品质质保": "kalite Güvenlik",
    "品质质保管理": "kalite güvenlik Yönetimi",
    "售价": "fiyat",
    "售后件数": "Satış öğelerinin sayısı",
    "售后单号": "Satış izleme numarasından sonra",
    "售后原因": "Satış sebeplerinden sonra",
    "售后图片": "Satış fotoğraflarından sonra",
    "售后数量": "Satış miktarından sonra",
    "售后服务": "satış hizmeti",
    "售后类型": "Satış tipinden sonra",
    "售后详情": "Satış detaylarından sonra",
    "售后说明": "Satış talimatlarından sonra",
    "售后退款": "Satıştan sonra",
    "商品": "mal",
    "商品信息": "Üretim bilgisi",
    "商品名称": "Üretim İsmi",
    "商品名称/商品编码": "Üretim İsmi/Üretim Kodu",
    "商品品质": "Ürüntü kalitesi",
    "商品性质": "Produkt doğası",
    "商品数量": "Mal miktarı",
    "商品管理": "Produkt Yönetimi",
    "商品编号": "Üretim Kodu",
    "商品编码": "Üretim kodu",
    "商品编辑成功！": "Üretim düzenlemesi başarılı!",
    "商品评价": "Product evaluation",
    "商城": "Satış Merkezi",
    "商城审核": "Merkez görüntüsü",
    "商城首页": "Merkez ev sayfası",
    "商家同意后，系统将退款给您": "Ticaret kabul ettikten sonra, sistem sizi geri ödeyecek.",
    "商家已同意": "Merkez kabul etti.",
    "商家已同意，钱款原路退回": "Şirketçi parayı orijinal yöntemle geri ödemeye kabul etti.",
    "商家已同意，钱款原路退回！": "Ticaret orijinal ödemeyi ödemeye kabul etti!",
    "商家已拒绝": "Merkez reddedildi",
    "商家已拒绝，请联系商家协商！": "Ticaret reddetti, lütfen müzakereler için ticaret ile iletişim kuralım!",
    "商家操作时间": "Merkez operasyonu zamanı",
    "商户": "MerchantName",
    "商户名称": "Merkez İsmi",
    "国产车系": "Ev araba serisi",
    "图片": "fotoğraf",
    "图片/视频": "Resim/Video",
    "图片大小不大于 2MB!": "Resim boyutu 2MB'den fazla olmamalı!",
    "图片设置成功！": "Resim ayarları başarılı!",
    "在线库存": "Çevrimiçi inventörü",
    "在线支付": "Online ödeme",
    "地址": "adres",
    "地址信息": "Adres bilgisi",
    "地址：": "Adres",
    "型号": "model",
    "城市": "Şehir",
    "基本信息": "temel bilgi",
    "基础模版": "Basit Şablon",
    "填写地址与收货人信息": "Adresi ve alıcı bilgisini doldur",
    "增值税专用发票点数": "Veri özel hesap noktaları eklendiği değer",
    "备注": "Remarks",
    "备注信息": "Remarks",
    "复制": "kopyalama",
    "复制成功！": "Başarılı anladım!",
    "外地代收": "Şehir koleksiyonunun dışında",
    "外询统计": "Dışarı soruşturma istatistikleri",
    "外贸团队": "Dışişleri ticaret ekibi",
    "大牌正品": "Yetki etiketli ürünler",
    "头像": "AvatarName",
    "奔驰": "Benz",
    "奔驰专区": "Mercedes Benz Zonu",
    "奥迪": "Audi",
    "奥迪专区": "Audi Zonu",
    "如果有现货，我们可以提供样品，但是客户需要支付样品费和快递费。": "Eğer kaynaklar varsa örnekler sağlayabiliriz, ama müşterinin örnek ücreti ve kurye ücreti için ödemesi gerekiyor.",
    "姓名": "Tam isim",
    "威克特": "Wickett",
    "威可特": "Wickett",
    "完成率": "Tamamlama hızı",
    "完成金额 (万)": "Tamamlanmış miktar (10000'da)",
    "完成金额(万)": "Tamamlanmış miktar (10000'da)",
    "官网": "Resmi web sitesi",
    "定价": "fiyat fiyatı",
    "定价列表": "Ödül Listesi",
    "定价规则": "Ödül Kuralları",
    "宝马": "BMW",
    "宝马专区": "BMW bölgesi",
    "实付金额": "Aslında ödeme miktarı",
    "实到货款": "Gerçek ödeme alındı.",
    "实到采购款": "Aslında satın ödemesi alındı.",
    "实退": "Gerçekten çekilme",
    "实退金额": "Gerçek ödeme miktarı",
    "审核": "incelemek için",
    "审核中": "Gözlemde",
    "审核状态": "Görüntüle durumu",
    "客户": "müşteri",
    "客户ID": "Müşteri Kimliği",
    "客户名称": "Müşteriler İsmi",
    "客户备注": "Müşteriler notları",
    "客户审核待通过": "Müşteriler tarafı beklenen onaylama",
    "客户审核被拒绝": "Müşteriler denetimi reddedildi",
    "客户状态": "Müşteriler durumu",
    "客户管理": "müşteri yönetimi",
    "客户类型": "Müşteriler türü",
    "客户详细地址": "Müşteriler detaylı adresi",
    "客服": "Müşteri hizmeti",
    "宽": "geniş",
    "密码": "parola",
    "密码登录": "Parola giriş",
    "密码输入不一致！": "Parola girdi uyumsuz!",
    "对账单": "Hesap Görüntüsü",
    "导出": "dışarı aktar",
    "小程序": "Küçük program ı",
    "小程序商城": "Mini Program Mall",
    "小计": "Alt toplam",
    "展开": "launch",
    "属性": "atribut",
    "岗位": "posta",
    "工作时间": "çalışma saatleri",
    "工程编号": "Proje numarası",
    "已上架": "Zaten listede",
    "已下架": "Kaldırıldı",
    "已关联": "Zaten bağlantılı",
    "已删除": "Kaldırıldı",
    "已发货": "Zaten gönderildi.",
    "已取消": "Canceled",
    "已取消售后申请！": "Sonra satış uygulaması iptal edildi!",
    "已取消，订单恢复正常": "Vazgeçildi, normal düzenleme",
    "已同意": "Anlaşıldı.",
    "已售后": "Satış hizmetlerinden sonra",
    "已完成": "Tamamlandı",
    "已成功发起售后申请，请耐心等待商家处理!": "Sonra satış uygulaması başarıyla başlatıldı, lütfen sabırlı olun ve ticaret işlemesini bekleyin!",
    "已成功发起售后申请，请耐心等待商家处理！": "Sonra satış uygulaması başarıyla başlatıldı, lütfen sabırlı olun ve ticaret işlemesini bekleyin!",
    "已成功发送短信": "SMS başarıyla gönderildi",
    "已成功批量生成催收单": "Çoğunlukla koleksiyon notları başarıyla üretildi",
    "已成功设置审核状态": "Denetim durumu başarıyla ayarlandı",
    "已报价": "ReplyForward",
    "已报价-已锁定": "Ziyaret - Kilitli",
    "已报价-待确认": "Ziyaret - doğrulamayı bekliyor",
    "已拒绝": "Red edildi",
    "已拒绝，订单恢复正常": "Red, order returned to normal",
    "已有催收单无法生成": "Zaten oluşturulmaz bir koleksiyon düzeni var.",
    "已添加备注": "Eklenmiş notlar",
    "已添加标签": "Eklenmiş etiketler",
    "已添加至购物车": "Alışveriş arabasına eklendi",
    "已禁用": "kapatıldı",
    "已结算": "Ayarlandı",
    "已认证": "Kullanılmış",
    "已选": "Seçili",
    "已选个数": "Seçili sayı",
    "已通过": "Geçildi",
    "市内代收": "Şehirdeki koleksiyon",
    "市场": "pazar",
    "常用零件": "Ortak parçalar",
    "常规件": "Normal parçalar",
    "平台": "platform",
    "平均": "ortalama",
    "年份": "Yıl",
    "广州市白云区景泰街白云大道南685号101号自编1009房": "Oda 1009, No. 101, No. 685 Baiyun Avenue South, Jingtai Street, Baiyun District, Guangzhou",
    "广州沃玛拉国际贸易有限公司": "Guangzhou Womala International Trade Co., Ltd",
    "广州沃玛拉国际贸易有限公司成立于2012年。公司位于中国广州，交通便利，占地面积2500多平方米。是一家专业销售沃尔沃汽车零部件的公司，主要经营沃尔沃XC系列、V系列、S系列、C系列的进口零部件，与车主、维修店、经销商、代理商、生产厂家长期合作，我们在全球市场建立了销售网络。我们以卓越的品质、一流的服务、专业负责的形象，致力于为客户带来最优质的产品和服务，成为国内外有名的汽车零部件经销商。": "Guangzhou Womala International Trade Co., Ltd. 2012 yılında kuruldu. Şirket, Çin'deki Guangzhou'da, uygun taşıma ve 2500 metreden fazla kare metre alanı ile yer alıyor. We are a professional company that sells Volvo car parts, mainly engaged in the import of Volvo XC series, V series, S series, and C series parts. We have long-term cooperation with car owners, repair shops, dealers, agents, and manufacturers, and have established a sales network in the global market. Müşterilerin en yüksek kaliteli ürünleri ve hizmetleri müşterilere mükemmel kaliteli, ilk sınıf hizmetleri ve profesyonel ve sorumlu görüntüleri sağlamaya karar verildik ve evcil ve uluslararası olarak bilinen otomatik bölgeleri olduk.",
    "广州沃玛拉国际贸易有限公司成立于中国广州。": "Guangzhou Womala International Trade Co., Ltd. Çin Guangzhou'da kuruldu.",
    "广州沃玛拉零部件有限公司成立于2012年。公司位于中国广州，交通便利，占地面积2500多平方米。是一家专业销售沃尔沃汽车零部件的公司，主要经营沃尔沃XC系列、V系列、S系列、C系列的进口零部件，与车主、维修店、经销商、代理商、生产厂家长期合作，我们在全球市场建立了销售网络。我们以卓越的品质、一流的服务、专业负责的形象，致力于为客户带来最优质的产品和服务，成为国内外有名的汽车零部件经销商。": "Guangzhou Womala Parts Co., Ltd. 2012 yılında kuruldu. Şirket, Çin'deki Guangzhou'da, uygun taşıma ve 2500 metreden fazla kare metre alanı ile yer alıyor. We are a professional company that sells Volvo car parts, mainly engaged in the import of Volvo XC series, V series, S series, and C series parts. We have long-term cooperation with car owners, repair shops, dealers, agents, and manufacturers, and have established a sales network in the global market. Müşterilerin en yüksek kaliteli ürünleri ve hizmetleri müşterilere mükemmel kaliteli, ilk sınıf hizmetleri ve profesyonel ve sorumlu görüntüleri sağlamaya karar verildik ve evcil ve uluslararası olarak bilinen otomatik bölgeleri olduk.",
    "序号": "Number",
    "库存": "inventör",
    "库存价": "Hesap fiyatı",
    "库存价格": "Hesap fiyatı",
    "库存价格设置成功！": "Çeviri fiyatı ayarlaması başarılı!",
    "库存充足": "büyük malları",
    "库存列表": "Inventory List",
    "库存导入": "Inventory import",
    "库存数量": "Inventory quantity",
    "库存数量：": "Hesap miktarı",
    "库存紧张": "Inventory shortage",
    "应付单": "Hesaplar Paylayabilir",
    "应付货款(￥)": "Ödemeli hesaplar (￥)",
    "应付金额": "Ödeyebilir miktar",
    "底盘": "chassis",
    "底盘悬挂": "Chassis suspension",
    "底盘系统": "Chassis sistemi",
    "店长": "Dükkan Yöneticisi",
    "开具发票": "Issuing invoices",
    "开单": "Faturalama ifadesi",
    "开单成功": "Başarılı hesap",
    "开始扩大业务范围，销售宝马、奔驰、保时捷、路虎的汽车配件。": "Bizim iş alanımızı genişletiyoruz ve BMW, Mercedes Benz, Porsche ve Land Rover için otomatik parçaları satıyoruz.",
    "开户银行": "Opening Bank",
    "张女士": "Ms. Zhang",
    "当前定价": "Şimdiki fiyat",
    "当前报价单": "Ağımdaki sitasyon",
    "当前操作人": "Şimdiki operatör",
    "当前状态": "8待收货",
    "当前输入的模版名称已存在！": "Şu anda girdiği örnek ismi zaten mevcut!",
    "当前选中": "Şu anda seçildi",
    "待分配": "Bölünecek",
    "待发货": "To be shipped",
    "待商家处理": "Bekleyen ticaret işleme",
    "待处理": "Bekleyen işlem",
    "待审核": "Bekleyen görüntüler",
    "待报价": "To be quoted",
    "待报价-待认领": "Bekleyen sitasyon - Bekleyen iddia",
    "待支付": "Ödemek için.",
    "待收货": "Alılacak",
    "待确认": "Doğrulanacak",
    "待认领": "İtiraz edilecek.",
    "待译码": "To be decoded",
    "微信": "WeChat",
    "微信头像": "WeChat avatar ı",
    "微信扫码上传": "WeChat'daki QR kodunu yüklemek için tarayın",
    "微信扫码支付": "WeChat QR kodu ödemesi",
    "微信抓单": "WeChat emri almak",
    "微信支付": "WeChat ödemesi",
    "微信昵称": "WeChat lağabı",
    "忘记密码": "Forgot password",
    "急件": "Acil madde",
    "总业绩": "Toplam performans",
    "总共": "tamamen",
    "总共付款": "Toplam ödeme",
    "总共数量": "Toplam miktarda",
    "悬挂系统": "Suspensyon sistemi",
    "成为博世、海拉、马瑞利授权经销商。": "Bosch, Hella ve Marelli'nin yetkili dağıtıcısı olun.",
    "成交率": "Transaksyon hızı",
    "成交订单": "Transaksyon düzeni",
    "成功设置计划金额": "Planlanmış miktarı başarıyla ayarlandı",
    "我们开始向电子商务转型，成立外贸部并创立新品牌Womala开设两家阿里巴巴网店和一个国外网站。": "We are starting to transition towards e-commerce, establishing a foreign trade department and a new brand called Womala, opening two Alibaba online stores and a foreign website.",
    "我们沃玛拉的质量保证期是18个月。": "Womara için kalite güvenlik dönemimiz 18 ay.",
    "我们的产品种类丰富，涵盖了发动机零件、制动系统、悬挂系统等多个类别，满足不同客户的多样化需求。我们始终坚持质量第一的原则，严格控制产品的生产流程，确保每一件产品都达到国际标准。": "Bizim ürün menzilimiz zengindir, motor parçaları, frenme sistemleri, baskı sistemleri ve benzeri müşterilerin farklı ihtiyaçlarını yerine getirmek için birçok kategoriyi kaplıyor. Önce her zaman kalite prensipine uyuyoruz, ürünlerimizin üretimi sürecini kesinlikle kontrol ediyoruz ve her ürünin uluslararası standartlarına uymasını sağlıyoruz.",
    "我们的仓库库存已接近1000万件，成为最具优势的零部件行业之一。沃尔沃集团的供应链": "Depolarımız inşaatçısı 10 milyon parçaya yaklaştı, en faydalı bölümlerden birini yaptı. Volvo Group's Supply Chain",
    "我们的成长历程": "Büyüme yolculuğumuz",
    "我的": "Benim.",
    "或": "Mor",
    "所在区域": "Bölge",
    "所在地址": "Adres",
    "所在城市": "Yer Şehri",
    "手机号": "Cep telefonu numarası",
    "手机号码": "Cep telefonu numarası",
    "手续费": "Hizmet Şarj",
    "打印": "Printing",
    "打印发货单": "Yazım notu",
    "打印时间": "Yazım zamanı",
    "托收": "koleksiyon",
    "执行": "uygulama",
    "执行规则?": "İşleme kuralları mı?",
    "扫描二维码支付": "QR kodunu ödemek için tarayın",
    "批量上架": "Toplu listesi",
    "批量下架": "Toplu tatlı.",
    "批量价格模版": "Toplu fiyat şablonu",
    "批量修改价格": "Toplu fiyatları değiştirir",
    "批量修改标签": "Toplu etiketleri değiştir",
    "批量删除": "Toplu silme",
    "批量启用/禁用": "Toplu etkinleştir/etkinleştir",
    "批量新增标签": "Toplu etiketleri ekle",
    "批量标签": "Batch etiketi",
    "批量生成催收单": "Toplu koleksiyon notları oluşturur",
    "批量询价": "Toplu soruşturma",
    "投诉电话": "Sıkıntılar Hotline",
    "折扣": "diskont",
    "报价": "teklif",
    "报价中": "İçinde",
    "报价列表": "Quotasyon Listesi",
    "报价单数": "Quotasyon numarası",
    "报价员": "Quotasyon Memuru",
    "报价品质": "Quotasyon kalitesi",
    "报价回写失败": "Tekrar yazma başarısız oldu",
    "报价回写成功": "Quotasyon cevabı başarılı",
    "报价待认领": "Kabul edilecek kvotasyon",
    "报价时间": "Quotasyon zamanı",
    "报价模版": "Kurtarma Şablonu",
    "拒绝": "reddetme",
    "拒绝售后": "Satış hizmeti reddedin",
    "拒绝理由": "İtiraz etmenin sebebi",
    "拖拽工单图片到这里，或点击上传": "Çalışma sıralaması resmini buraya sürükleyin veya yüklemeyi tıklayın",
    "拖拽新图片到框内，可替换图片重新识别": "Drag a new image into the box to replace the image and recognize it again",
    "招商负责人-张女士": "Yatırım Yöneticisi - Bayan Zhang",
    "招商负责人-杨先生": "Bay Yang, yatırım terfi için sorumlu kişi",
    "挂帐并发货": "Charge and ship",
    "挂帐支付": "Bekleyen ödeme",
    "挂账": "Hesap bekliyor",
    "挂账支付": "Bekleyen ödeme",
    "挂账金额": "Kredi miktarı",
    "挂账金额(万)": "Kredi sayısı (10000'da)",
    "挂账金额已退回！": "Büyük miktar ödedildi!",
    "挂账额度设置": "Hesap kredi sınırı ayarlaması",
    "按所选日期批量生成": "Seçili tarihlere göre gruplarda oluştur",
    "按比例定价商品": "Yapılar proporsyonel olarak fiyatlı",
    "挑选产品并加入购物车": "Ürüntüleri seç ve onları alışveriş arabasına ekle",
    "授信挂账额度": "Kredi kredi sınırı",
    "授信额度": "Kredi sınırı",
    "排量": "değiştirme",
    "排量(发动机代码)": "Taşıma (motor kodu)",
    "接听人": "Cevap veren kişi",
    "接听时间": "Cevap verme zamanı",
    "推广专区": "Promotion Zone",
    "推荐图片": "Anlaşılan resimler",
    "推荐排序": "Recommended sorting",
    "提交时间": "Gönderme zamanı",
    "提交申请": "Uygulama gönder",
    "提示": "prompt",
    "提示 ": "如需订单帮助，请发送电子邮件至",
    "搜索": "arama",
    "搜索零件名称": "Bölüm isimleri arayın",
    "操作": "operation",
    "操作人": "Operatör",
    "操作成功": "Operasyon başarılı",
    "操作成功！": "Operasyon başarılı!",
    "操作时间": "Operasyon zamanı",
    "支付宝": "Alipay",
    "支付宝扫码支付": "Alipay kodu tarama ödemesi",
    "支付成功": "Ödeme başarılı.",
    "支付方式": "Ödeme yöntemi",
    "支付金额": "Payment amount",
    "支持输入负数": "Negatif sayıları girmenizi destekle",
    "收款": "Ödemeleri toplama",
    "收货人": "gönderin",
    "收货人信息": "Bilgi gönderin",
    "收货人姓名": "İsmi gönderin",
    "收货付款": "Alış ve ödeme",
    "收货凭证": "Receipt voucher",
    "收货地址": "Adresi alıyor",
    "收货地址管理": "Gemi adresi yönetimi",
    "收货成功！": "Başarılı alındı!",
    "收起": "Çıkar onu.",
    "改装车系": "Değiştirilmiş araba serisi",
    "放心启动": "Aklın barışıyla başla.",
    "放心好油": "Merak etme, iyi petrol.",
    "敞篷车": "dönüştürebilir",
    "数据录入": "Veri girdi",
    "数量": "miktarda",
    "数量/单位": "Kıymet/Birim",
    "整车配件一站式解决方案 · 沃尔沃汽车零部件专家": "Tüm araç aygıtları için bir durak çözüm - Volvo Automotive Parts Expert",
    "整车配件一站式解决方案·沃尔沃汽车零部件专家": "Tüm araç aygıtları için bir durak çözüm - Volvo Automotive Parts Expert",
    "文本": "metin",
    "新品发布": "Yeni ürün başlatıcı",
    "新增": "Yeni ekleme",
    "新增发票": "Fakat ekle",
    "新增地址": "Adresi ekle",
    "新增成功！": "Yeni başarıyla eklendi!",
    "新增标签": "Etiketleri ekle",
    "新增模版": "Şablon Ekle",
    "新建客户": "Yeni Müşteri",
    "无": "Hiçbir şey yok.",
    "无价格": "Fiyat yok.",
    "无图": "Resim yok",
    "无意向": "Niyetsiz",
    "无替换件": "No replacement parts",
    "无模版": "Şablon mevcut değil",
    "无法生成催收单！": "Koleksyon düzeni oluşturulamadı!",
    "日期": "tarih",
    "易碎品": "Fragile ürünler",
    "是": "Evet.",
    "是否移出购物车？": "Alışveriş arabadan çıkarın mı?",
    "是的，我们在发货前有100%的测试。我们注重质量。": "Evet, göndermeden önce %100 testimiz var. We value quality.",
    "昵称": "lağap adı",
    "显示其他属性": "Diğer özellikleri göster",
    "普票": "Genel bilet",
    "普通发票": "Normal hesap",
    "普通发票开票点数": "Düzenli hesap çıkarması için noktalar sayısı",
    "普通员工": "Normal çalışanlar",
    "智能识别": "Akıllı tanıma",
    "暂不支持此支付方式": "Bu ödeme metodu şu anda desteklenmiyor",
    "暂无": "Şu anda ulaşılabilir",
    "暂无发票": "Şu anda hesap kullanılmaz",
    "暂无发票，请添加发票": "Şu anda fatura yok, lütfen bir fatura ekleyin",
    "暂无地址": "Şu anda adres mevcut değil",
    "暂无地址，新增地址": "Şu anda adres yok, yeni adres eklendi",
    "暂无数据": "Şu anda hiçbir veri ulaşılabilir",
    "暂无跟进记录": "Şu anda izleme kayıtları yok",
    "更多": "Daha fazla",
    "更新成功": "Güncelleme başarılı",
    "更新成功！": "Güncelleme başarılı!",
    "更新时间": "Güncelleme zamanı",
    "替换件": "Replacement parts",
    "替换家": "Evden Değiştir",
    "最后一次询价时间": "Son sorgulama zamanı",
    "最多3张": "3 sayfa kadar",
    "最多可退": "Maksimum tercih edilebilir",
    "最多可退件数": "Maksimum tercih edilebilir öğelerin sayısı",
    "最大（默认）": "Maksimum (öntanımlı)",
    "最小": "minimum",
    "最新报价时间": "Son kutlama zamanı",
    "最近跟进人": "Son takipçiler",
    "最近跟进内容": "Son günlerde izleme içerisi",
    "有": "ve",
    "有价格": "Bir fiyat var.",
    "有原盒": "Orijinal kutu mevcut",
    "有图": "Resimler var.",
    "未关联": "İlişkisiz",
    "未合作原因": "İşbirliği olmayan sebepler",
    "未查询到商品信息，请前往ERP库存补充此产品编码对应的适用OE，生成商品编码后，即可进行定价！": "Ürüntü bilgileri bulunamadı. Lütfen bu ürün koduna uygun OE'yi eklemek için ERP inventörüne git. Ürüntü kodu oluşturulduğunda fiyat ayarlanabilir!",
    "未活跃天数": "Etkin günler",
    "未结算": "Ayarlanmış",
    "未认证": "Kimlik doğrulamadı",
    "未过滤": "Süzgülmemiş",
    "未通过": "Geçmedi.",
    "本期应付货款": "Mevcut hesaplar ödeyebilir",
    "材质": "materyal kalitesi",
    "条": "strip",
    "来源": "kaynak",
    "杨先生": "Bay Yang",
    "极致优选，国际大牌配套": "Son Seçim, Uluslararası Marka Eşleşmesi",
    "查看": "kontrol",
    "查看更多": "View more",
    "查询": "sorgulama",
    "查询次数": "Sorgulama frekansı",
    "查询结果": "Sorgulama Sonuçları",
    "标签": "etiket",
    "标签(可多选)": "Etiketler (Çoklu Seçim)",
    "标签名称": "Etiket İsmi",
    "标签类型": "Etiket türü",
    "标签设置成功！": "Etiket ayarlaması başarılı!",
    "模版名称": "Şablon Adı",
    "正常": "normal",
    "比价": "Fiyatları karşılaştır",
    "汇款": "Remittance",
    "汽灵灵云汽配，一站式交易平台。": "Qi Ling Ling Yun Auto Parts, bir durak ticaret platformu.",
    "汽灵灵自2018年成立以来，便专注于为汽车后市场提供供应链产业协同服务。致力于打造高效供应链服务平台，实现资源的优化配置与信息共享，其服务内容涵盖了汽车配件的采购、销售、物流配送、技术支持以及平台运营等多个环节，为汽配行业提供全方位、一站式的供应链解决方案。": "2018 yılında kurulduğundan beri, Qilingling otomatik pazarı için sağlam zincir endüstri işbirliği hizmetleri sağlamak için bağlı. Etkileşimli bir temin zinciri hizmet platformu inşa etmeye ve iyileştirilmiş kaynaklar ve bilgi paylaşmaya ulaşmaya karar verildi, hizmet içeriği otomatik parçaları satış, satış, lojik dağıtım, teknik destek ve platformu operasyonu, otomatik parçaları endüstri için büyük ve tek durak temin zinciri çözümü sağlayan birçok bağlantı kaplıyor.",
    "汽车售后产业平台，提供一站式服务支持": "Bir durak servis desteği sağlayan sonra satış endüstri platformu otomotörü",
    "汽车配件在线交易平台": "Otomatik parçalar için internet ticaret platformu",
    "汽配商": "Otomatik parçalar teminatçısı",
    "沃尔沃": "Volvo",
    "沃玛拉国际贸易有限公司": "Womara International Trade Co., Ltd",
    "油品": "Oylar",
    "油品1": "Yağ Üreti 1",
    "注册": "kayıt",
    "注册公司名称": "Kayıtlı şirket ismi",
    "注册成功！": "başarıyla kaydedildi",
    "注册时间": "Registration time",
    "注释": "annotation",
    "活跃用户": "Etkin kullanıcılar",
    "测试中心": "Testing Center",
    "添加": "ekle",
    "添加人员": "Kişiyi ekle",
    "添加商品": "Ürünü ekle",
    "添加成功": "Başarılı eklenmiş",
    "添加抬头": "Başlığı ekle",
    "温馨提示": "hatırlatma",
    "点击打开小程序支付": "Ödemek için küçük program ı açmak için tıklayın",
    "点击打开网页支付": "Web ödemesini açmak için tıklayın",
    "点击查看价格": "Fiyatları görmek için tıklayın",
    "点火系统": "ignition system",
    "热销商品": "Sıcak satış ürünleri",
    "热门商品": "Popüler ürünler",
    "照明系统": "ışık sistemi",
    "燃油供给系": "Yangın temsil sistemi",
    "燃油类型": "Fuel tipi",
    "物流": "logistik",
    "物流单号": "Logistik izleme numarası",
    "物流名称": "Mantık ismi",
    "特价专区": "Özel teklif",
    "特价商品": "Özel teklif ürünleri",
    "状态": "Durum",
    "玛莎拉蒂": "Maserati",
    "现有货源充足，小订单交货期1周以内，普通订单交货期15天左右，定制订单交货期1-2个月。": "Mevcut hedefler, küçük emirler için 1 haftadan az bir süre süre süre süre süre süre süre 15 günü ve 1-2 ay özellendirilmiş emirler için yeterli.",
    "现货": "Spot malları",
    "现货充足": "Yeterli stok",
    "现货紧张": "Kısa nokta malları",
    "现金": "Para",
    "生成催收单": "Koleksyon notu oluştur",
    "用户": "kullanıcı",
    "用户列表": "Kullanıcı Listesi",
    "用户名": "kullanıcı adı",
    "用户名称": "Kullanıcı İsmi",
    "用户状态": "Kullanıcı durumu",
    "用户管理": "kullanıcı yönetimi",
    "申请人姓名": "İstemci İsmi",
    "申请售后": "Sonra satış hizmeti için uygulayın",
    "申请时间": "Uygulama zamanı",
    "申请金额": "Uygulama miktarı",
    "电气系统": "elektrik sistemi",
    "电话": "telefon",
    "电话号码": "Telefon numarası",
    "疑问解答": "Q&A",
    "登录": "İçeri gir",
    "直接定价": "Doğru fiyat",
    "直接替换": "Doğru değiştirme",
    "省/市/区": "Province/City/District",
    "省市区": "Eyalet, şehir, bölge",
    "知名品牌": "Famous brands",
    "确定": "confirm",
    "确定收货": "Kabul onaylayın",
    "确定要修改吗？": "Değişiklikler yapmak istediğinden emin misin?",
    "确认": "confirm",
    "确认下单": "Düzeni yerleştirmeyi doğrula",
    "确认付款": "Ödemeyi doğrula",
    "确认删除?": "Silecek misin?",
    "确认删除订单?": "Emri silmeye emin misiniz?",
    "确认密码": "Confirm password",
    "确认支付": "Ödemeyi doğrula",
    "确认收货": "Kabul onaylayın",
    "确认解绑": "Bağlanmadığını doğrula",
    "确认购买": "Confirm purchase",
    "禁用": "Devre dışı",
    "离合器泵类": "Clutch pumpları",
    "积分兑换区": "Noktalar kurtarma alanı",
    "积分商城": "Points Mall",
    "税号": "Tax ID",
    "税点": "Tax points",
    "税额": "Ödeme miktarı",
    "税额设置": "Ödeme miktarı ayarlaması",
    "空": "empty",
    "空调系统": "hava kondiciyonu sistemi",
    "空闲": "özgür",
    "立即购买": "Alın şimdi.",
    "管": "Tüp",
    "管理员": "yöneticiler",
    "管理收货地址": "Gönderme adreslerini yönet",
    "管路总成类": "Pipeline toplama sınıfı",
    "米": "rice",
    "类型": "tür",
    "粘贴信息、自动拆分姓名、电话和地址": "Bilgileri yap, otomatik ayrı isimler, telefon numaraları ve adresleri",
    "粘贴信息，自动拆分姓名、电话和地址": "Bilgileri yap, otomatik ayrı isimler, telefon numaraları ve adresleri",
    "粘贴并识别": "Paste and recognize",
    "粘贴或拖动文件到此区域以进行识别": "Dosyaları tanımak için bu alana yapıştır ya da sürükleyin",
    "系统标签": "Sistem etiketi",
    "系统预置": "Sistem ön ayarlaması",
    "繁忙": "meşgul",
    "纳税人识别号": "Öğrenci kimlik numarası",
    "线上已支付": "Online ödeme",
    "线下已支付": "Devre dışı ödeme",
    "线下已支付待确认": "Kontrol edilecek devre ödemesi",
    "线下门店": "Devre dışı dükkanlar",
    "组": "grup",
    "绑定关系": "Binding relationship",
    "结算": "balance",
    "维修保养件": "Yedek parçaları",
    "维修方案": "Yedek plan ı",
    "编号查询": "Number inquiry",
    "编辑": "edit",
    "编辑岗位": "Görevi düzenleme",
    "编辑机洗规则": "Makine yıkama kurallarını düzenle",
    "编辑标签": "Etiketleri düzenle",
    "编辑模版": "Şablon Düzenle",
    "老板": "Patron.",
    "耐博德": "Naibode",
    "联系人": "Kontaktlar",
    "联系我们": "Bizimle iletişim kur",
    "联系电话": "Contact phone number",
    "自动通过": "Otomatik Geçiş",
    "自定义": "Özel",
    "自定义替换OE": "Özel Değiştirme OE",
    "自定义替换OE编辑成功！": "Özel değiştirme OE düzenlemesi başarılı!",
    "自定义替换件": "Özel Değiştirme Bölümleri",
    "自定义标签": "Özel etiketler",
    "自定义配件": "Özel Accessories",
    "自营产品": "Kendi çalışan ürünler",
    "英文": "English",
    "获取价格": "Fiyat alın.",
    "获取验证码": "Denetim kodu alın",
    "营业员": "Sales Clerk",
    "营业执照": "İş lisansı",
    "萨肯SHAKOLN，专注于研发和销售高性能的汽车零部件。": "Sakon Shakolin yüksek performanslı otomatik komponentlerin araştırmalarına ve satmalarına odaklanıyor.",
    "行驶系统": "Araç sistemi",
    "解放": "serbest bırakın",
    "解析错误，请上传清晰图片！": "Analiz hatası, lütfen temiz resimleri yükleyin!",
    "解绑": "Bağlama",
    "解绑后，将不再是公司成员！": "Bağlantılmadan sonra artık şirketin üyesi olmayacaksın!",
    "计划金额 (万)": "Planlanmış miktar (10000'da)",
    "订单": "sıra formu",
    "订单信息": "Sıra bilgileri",
    "订单分配": "Sıra bölümü",
    "订单列表": "Sıra Listesi",
    "订单号": "Sıra numarası",
    "订单号/VIN码搜索": "Sıra numarası/VIN kodu araması",
    "订单回写中，请等待": "Tercih ediyor, lütfen bekleyin.",
    "订单备注": "Düzenlemeler",
    "订单数": "Emirler sayısı",
    "订单数量": "Sıra miktarı",
    "订单管理": "Ordu yönetimi",
    "订单详情": "Order details",
    "订单金额": "Sıra miktarı",
    "认证中": "Certification in progress",
    "认证公司名称": "Şifreleme şirketi ismi",
    "认证公司后显示价格": "Şirketi sertifik ettikten sonra fiyatı göster",
    "认证后可获得更多，商品查询次数": "After authentication, you can obtain more product queries",
    "认证失败": "Kimlik sahihlenmesi başarısız oldu",
    "认证状态": "Sahihlenme durumu",
    "认领": "İstem ve iddia et",
    "让配件交易更简单": "Aksesör ticareti kolaylaştırmak",
    "让配件查询更简单": "Erişilebilir arama kolaylaştırın",
    "设为默认": "Öntanımlı olarak ayarlayın",
    "设备": "ekipmanlar",
    "设备1": "Aygıt 1",
    "设置": "ayarlandır",
    "设置为收货地址": "Gönderme adresi olarak ayarlayın",
    "设置为默认发票": "Set as default invoice",
    "设置为默认收货地址": "Öntanımlı gönderme adresi olarak ayarlayın",
    "译码": "kodlama",
    "译码列表": "Dekodlama Listesi",
    "译码员": "Dekoder",
    "译码时间": "Dekodlama vakti",
    "试装过": "Teste",
    "诚邀全国合作代理商": "Doğrusu, ülkede işbirliği ajanları davet et",
    "询价": "inquiry",
    "询价人": "soruşturucu",
    "询价列表": "Sorgulama Listesi",
    "询价成功": "Sorgulama başarılı.",
    "询价成功，可在订单列表中查看！": "Sorgulama başarılı, sıralama listesinde görülebilir!",
    "该公司已存在，您将自动申请加入此公司": "Şirket zaten mevcut, bu şirkete katılmak için otomatik olarak uygulayacaksınız.",
    "该商品尚未定价": "Bu ürün henüz fiyat almadı.",
    "该日期内已有生成的催收单，将无法生成可取消或客户付款后再生成！": "Bu tarih içinde oluşturduğu koleksiyon notları iptal edilmeden ve müşteri ödemesinden sonra üretilmeyecek!",
    "该日期内已有生成的催收单，请先取消或客户付款后再生成！": "Bu tarih içinde zaten oluşturulmuş koleksiyon emirleri var. Lütfen onları ilk iptal edin ya da müşterisinin yeniden üretilmeden ödemesini bekleyin!",
    "该用户曾绑定多家公司": "Bu kullanıcı daha önce birçok şirketlere bağlantılı oldu.",
    "详情": "detaylar",
    "详细信息": "detaylı bilgi",
    "详细地址": "Ayrıntılı adres",
    "说明": "explain",
    "请使用微信扫一扫": "Please use WeChat to scan",
    "请使用微信扫一扫，扫描二维码支付": "Lütfen QR kodunu tararak ve ödemek için WeChat kullanın",
    "请使用零零汽APP扫码登录": "Lütfen Sıfır Sıfır Otomotif APP'ni taramak ve girmek için kullanın",
    "请先填写内容": "Lütfen ilk içeriğini doldurun",
    "请先填写必填信息!": "Please fill in the required information first!",
    "请先点击同意《用户注册协议》和《用户隐私协议》再注册！": "Lütfen kayıt almadan önce Kullanıcı Kayıt Anlaşması ve Kullanıcı Gizlilik Anlaşması'na katılmak için tıklayın!",
    "请先点击同意《用户注册协议》和《用户隐私协议》再登录！": "Lütfen girmeden önce Kullanıcı Kayıt Anlaşması ve Kullanıcı Gizlilik Anlaşması'na katılmak için tıklayın!",
    "请先登录": "Please log in first",
    "请先输入手机号!": "Lütfen önce telefonunuzu girin!",
    "请先输入邮箱!": "Please enter your email first!",
    "请填写件数": "Lütfen öğelerin sayısını doldurun",
    "请填写发货单备注": "Lütfen teslim notundaki remarları doldurun.",
    "请填写物流单号": "Lütfen logistik izleme numarasını doldurun.",
    "请填写物流名称": "Lütfen logistik adını doldurun",
    "请填写物流备注": "Lütfen lojistik ifadeleri doldurun.",
    "请填写送货骑手姓名": "Lütfen teslim sürücünün adını doldurun",
    "请最少选择一个商品发货！": "Lütfen yolculuk için en azından bir ürün seçin!",
    "请最少选择一项！": "Lütfen en azından bir seçenek seçin!",
    "请正确填写参数": "Lütfen parametreleri doğru doldurun",
    "请添加发票": "Please add invoice",
    "请添加商品到购物车": "Lütfen alışveriş arabanıza ürünler ekleyin",
    "请登录": "Lütfen girin",
    "请确认 ERP 系统用户名": "Lütfen ERP sistem kullanıcı adını doğrulayın",
    "请确认删除?": "Lütfen silmeni doğrulayın.",
    "请确认收货": "Lütfen alıntı onaylayın",
    "请认证公司，获取查询次数！": "Lütfen şirketi doğrulayın ve sorguların sayısını alın!",
    "请输入": "Lütfen girin.",
    "请输入OE或厂家编码": "Lütfen OE ya da üretici kodu girin",
    "请输入VIN码": "Lütfen VIN kodunu girin",
    "请输入VIN码或拖拽VIN码图片到此处": "Lütfen VIN kodu girin ya da VIN kodu resmini buraya sürün",
    "请输入oe": "Lütfen OE girin",
    "请输入公司税号": "Lütfen şirketin vergi numarasını girin.",
    "请输入发件人姓名": "Lütfen göndericinin adını girin",
    "请输入发件人联系方式": "Lütfen göndericinin temas bilgisini girin",
    "请输入发件人详细地址": "Lütfen göndericinin detaylı adresini girin",
    "请输入增值税专用发票点数": "Lütfen PVN özel hesap noktalarının sayısını girin",
    "请输入备注": "Lütfen bir notu girin",
    "请输入备注内容": "Lütfen not içeriğini girin",
    "请输入完整的收发货信息！": "Lütfen tamamen gönderme ve bilgi alın!",
    "请输入客户ID": "Lütfen müşteri kimliğini girin",
    "请输入客户名字或者简拼": "Lütfen müşterisinin adını ya da basitleştirilmiş imzalamayı girin",
    "请输入客户名称": "Lütfen müşteri adını girin",
    "请输入抬头名称": "Lütfen başlık ismini girin",
    "请输入授信金额": "Lütfen kredi miktarını girin",
    "请输入授信金额(￥)": "Lütfen kredi miktarını girin (￥)",
    "请输入收件人姓名": "Lütfen alıcı adını girin",
    "请输入收件人联系方式": "Lütfen alıcının temas bilgisini girin",
    "请输入收件人详细地址": "Lütfen alıcının detaylı adresini girin",
    "请输入数值": "Lütfen sayısal bir değer girin",
    "请输入普通发票开票点数": "Lütfen düzenli hesaplar için hesap noktalarının sayısını girin",
    "请输入材质": "Lütfen materyali girin",
    "请输入模版名称": "Lütfen örnek ismini girin",
    "请输入正确的数字并保留两位小数": "Lütfen doğru numarayı iki on yer ile girin",
    "请输入物流名称！": "Lütfen logistik adını girin!",
    "请输入纳税人识别号": "Lütfen vergi ödemeci kimliğini girin.",
    "请输入联系人": "Lütfen temas kişisini girin",
    "请输入联系电话": "Lütfen temas telefonunuzu girin",
    "请输入详细地址": "Lütfen detaylı adresi girin",
    "请输入详细地址信息，如道路、门牌号等信息": "Lütfen yol, ev numarası gibi detaylı adres bilgilerini girin.",
    "请输入送货骑手联系方式": "Lütfen teslim sürücünün temas bilgisini girin",
    "请输入零件号查询，多零件请换行": "Sorgulamak için lütfen parça numarasını girin. For multiple parts, please line up",
    "请输入零件名称或零件号": "Lütfen parça ismini ya da parça numarasını girin",
    "请输入需要添加的标签": "Lütfen eklenmeli etiketleri girin",
    "请选择": "Lütfen seçin",
    "请选择 ERP 系统用户名": "Lütfen ERP sistem kullanıcı adını seçin",
    "请选择仓库": "Lütfen depoyu seçin",
    "请选择价格模版": "Lütfen bir fiyat şablonu seçin",
    "请选择公司": "Lütfen bir şirket seçin",
    "请选择地址": "Lütfen bir adresi seçin",
    "请选择基础模版": "Lütfen temel şablonu seçin",
    "请选择客户": "Lütfen müşteri seçin",
    "请选择客户类型": "Lütfen müşteri türünü seçin",
    "请选择开具发票信息": "Lütfen çıkarmak için hesap bilgisini seçin",
    "请选择或者输入必填项！": "Lütfen gerekli bir alan seçin ya da girin!",
    "请选择支付方式": "Lütfen bir ödeme yöntemi seçin",
    "请选择收货地址": "Please select a shipping address",
    "请选择需要购买的商品": "Please select the product you want to purchase",
    "调比例": "Ölçünü ayarla",
    "账号": "Hesap",
    "账号/公司/标签": "Hesap/Şirketi/Etiket",
    "账号/公司名称": "Hesap/Şirketi İsmi",
    "账号/公司搜索": "Hesap/Şirketi Arama",
    "账号状态": "Hesap durumu",
    "账户余额": "Hesap dengesi",
    "货到付款": "Teslimat parası",
    "货款": "mal ödemesi",
    "货款已结算": "Ödeme tamamlandı.",
    "货款未结算": "Mallar için ayarlanmamış ödeme",
    "货款状态": "Ödeme durumu",
    "质保": "Warranty",
    "质保政策": "kalite güvenlik politikası",
    "质保时间": "Garanti dönemi",
    "质保时间管理": "kalite güvenlik zamanı yönetimi",
    "购买数量：": "Purchase quantity",
    "购买流程": "Alış süreci",
    "购物车": "alışveriş arabası",
    "购货单位": "Alış birimi",
    "购货收货人": "Alış alıcı",
    "购货日期": "Alış tarihi",
    "起订量": "Minimum order quantity",
    "距离二维码过期还剩x秒，过期后请刷新页面重新获取二维码": "There are x seconds left until the QR code expires. After it expires, please refresh the page to retrieve the QR code again",
    "路虎捷豹": "Land Rover ve Jaguar",
    "跳过": "atla",
    "车型": "Vehicle model",
    "车型(底盘)": "Araç modeli (şasis)",
    "车型查询": "Araç modeli soruşturma",
    "车架号查询": "Vehicle frame number inquiry",
    "车润保是一家专注于整合配件商与修理厂的汽车配件交易平台公司。我们的使命是为汽车维修行业提供高效、便捷的配件采购和交易解决方案。 作为汽车配件交易平台，我们与全国各地的优秀配件供应商、配件生产厂家建立了紧密的合作关系。通过我们的平台，修理厂可以轻松浏览和购买各类汽车配件，包括The engine零件、制动系统、悬挂部件、电子设备等。我们提供多样化的产品选择，以满足不同车型和维修需求。": "Cherunbao, parçalar teminatçıları ve tamir dükkanlarına odaklanan bir otomatik parçası satış platformu şirketi. Görevimiz, otomatik tamir endüstriyesi için etkileyici ve uygun yardımcı alışveriş ve işlem çözümlerini sağlamak. As an automotive parts trading platform, we have established close cooperative relationships with excellent parts suppliers and manufacturers from all over the country. Platformumuzdan tamir dükkanları, motor parçaları, fren sistemleri, suspension komponentleri, elektronik aygıtlar ve benzer olarak kolayca araştırabilir ve satın alabilir. We offer a diverse range of product choices to meet different vehicle models and maintenance needs.",
    "车身": "body",
    "车辆信息": "Araç bilgisi",
    "车辆确认": "Araç doğrulaması",
    "转向系统": "yönetme sistemi",
    "转向节总成类": "Yönetici piç toplantısı",
    "转让后，该账号将成为店长！": "Transferden sonra, hesap dükkan yöneticisi olacak!",
    "转让管理员": "Transfer yöneticisi",
    "输入OE号或17位车架号(支持拖拽或粘贴图片识别)": "OE numarasını ya da 17 rakam şases numarasını girin (resim tanımasını sürükleyen ve düşürmeyi destekler)",
    "输入VIN码，快速查询零件": "Biraz aramak için VIN kodunu girin",
    "输入公司名称": "Şirket ismini girin",
    "输入内容…": "İçeri gir",
    "输入名称": "İsmi girin",
    "输入商品名称/编码": "Ürük ismi/kodu girin",
    "输入备注": "İşaretler girin",
    "输入奔驰后8位或17位车架号": "Arka 8 rakam ya da 17 rakam Mercedes Benz şasi numarasını girin",
    "输入宝马后7位或17位车架号": "BMW'in arka 7 rakamı ya da 17 rakam şases numarasını girin",
    "输入岗位名称": "Konum ismini girin",
    "输入手机号": "Telefon numarasını girin",
    "输入拒绝理由": "Kaçırma sebebini girin",
    "输入标签名称": "Etiket ismini gir",
    "输入玛莎拉蒂后7位或17位车架号": "Maserati şasi numarasının son 7 veya 17 rakamı girin",
    "输入用户名称或账号": "Kullanıcı adı ya da hesap girin",
    "输入申请人姓名": "İstemcisinin adını girin",
    "输入税号": "Veri numarasını girin",
    "输入街道、门牌号、写字楼、商圈": "Sokak, ev numarası, ofis binası ve reklam bölgesine girin.",
    "输入解放后8位或17位车架号": "Özgürlükten sonra 8 rakam veya 17 rakam şasi numarasını girin",
    "输入账号/名称搜索": "Aramak için hesap/isim girin",
    "输入账号/姓名搜索": "Aramak için hesap/isim girin",
    "输入路虎捷豹后8位或17位车架号": "Enter the rear 8-digit or 17-digit chassis number for Land Rover and Jaguar",
    "输入重汽后8位或17位车架号": "Çin Ulusal Ağırlı Uzay Aracı Şirketinin arka 8 veya 17 rakam çerçevesini girin",
    "输入金额": "Ekran girin",
    "输入陕汽后8位或17位车架号": "Arka 8 rakam ya da 17 rakam Shaanxi Otomobil numarasını girin",
    "输入零件原厂名／零件号": "Enter the original factory name/part number of the part",
    "过滤": "filtr",
    "迈巴赫专区": "Maybach Bölümü",
    "返回": "return",
    "返回商城登录": "Merkez merkezine dön ve gir",
    "返回搜索": "Arama geri dön",
    "返回登录": "Girişe dön",
    "返回首页": "Ev sayfasına dön",
    "返点比例": "Tekrar başlat",
    "返点金额 (万)": "Tekrar başlat miktarı (10000)",
    "进价(含税)": "Alış fiyatı (vergi dahil)",
    "进价(未含税)": "Alış fiyatı (vergi dışında)",
    "进口品牌": "İtal etiketler",
    "进货价(含税)": "Purchase price (including tax)",
    "退出": "imzalayın",
    "退出登录": "Çıkış ve gir",
    "退款": "geri ödeme",
    "退款/售后": "Satışlardan sonra",
    "退款金额": "ödeme miktarı",
    "退货退款": "Geri döner ve geri ödemeler",
    "送及经手人": "Delivery and handling personnel",
    "送货骑手": "Delivery sürücü",
    "适用OE": "Uygulaştırılabilir OE",
    "适用车型": "Applicable vehicle models",
    "适配OE": "OE'ye uygulayın",
    "适配车型": "Adapted models",
    "选择": "choice",
    "选择价格类型": "Fiyat türünü seç",
    "选择品牌": "Bir marka seç",
    "选择商户": "Satıcı Seç",
    "选择图像": "Resim Seç",
    "选择岗位": "Konum seç",
    "选择年份": "Yılı seç",
    "选择排量": "Değiştirmeyi seç",
    "选择省/市/区": "Eyalet/Şehir/Bölge Seçin",
    "选择类型": "Türü seç",
    "选择要查询的内容": "Sorulacak içeriğini seç",
    "选择账号": "Bir hesap seç",
    "选择车型": "Select vehicle model",
    "选择配置信息": "Yapılandırma bilgisini seç",
    "邮箱": "posta kutusu",
    "部分发货": "Bölümcü gemi",
    "配件品牌": "Accessory marka",
    "配件总成类": "İkinci toplantı kategorisi",
    "配置": "bölüm",
    "配置详情": "Ayarlama detayları",
    "配送方式": "Delivery method",
    "采埃孚": "ZF",
    "采购商": "Alıcı",
    "采购款": "Para alın",
    "采购款已结算": "Alış ödemesi tamamlandı.",
    "采购款未结算": "Alış fonları düzenlenmedi",
    "采购款状态": "Alış durumu",
    "重": "ağır",
    "重新上传": "Yükleme",
    "重新申请": "Tekrar uygulayın",
    "重汽": "Sinotruk",
    "金额": "money",
    "银行账户": "banka hesabı",
    "销售价": "Sales price",
    "销售价(含税)": "Satış fiyatı (vergi dahil)",
    "销售清单": "Satış Listesi",
    "锁定": "kilitleme",
    "锁定上下架状态?": "Durumu kilitleyin mi?",
    "锁定上架状态?": "Liste durumunu kilitleyin mi?",
    "锁定下架状态?": "Durum kilitli mi?",
    "长": "uzun",
    "长度不超过25个字符": "25 karakterin en büyük uzunluğu",
    "门店照片": "Fotoğrafları depola",
    "间接替换": "Yanlış yerine",
    "陕汽": "Shaanxi Otomobil",
    "雨刮": "Wiper",
    "雨刮系统": "Wiper sistemi",
    "零件": "part",
    "零件信息": "Part information",
    "零件分类": "Bölüm klasifikasyonu",
    "零件参数": "Parametrler",
    "零件号查询": "Bölüm numaralı soruşturma",
    "零件名": "Bölüm İsmi",
    "零件名称": "Bölüm İsmi",
    "零件名称/OE": "Bölüm İsmi/OE",
    "零件图": "Bölüm çizimi",
    "零件类型：": "Bölüm türü",
    "零件识别": "Bölüm kimliği",
    "零零汽APP扫码登录": "Sıfır Otomatik APP'ye girmek için QR kodunu tarayın",
    "零零汽二维码登录": "Zero Zero Auto QR Code Login",
    "零零汽账号": "Sıfır Otomobil Hesabı",
    "零零汽账号登录": "Log in with a zero zero zero car account",
    "非危险品": "Tehlikeli olmayan materyaller",
    "非易碎品": "Kırık olmayan öğeler",
    "预计购买后3-7个工作日发货": "Alışmalardan sonra 3-7 çalışma gününde gönderileceğini bekliyordu.",
    "风险": "risk",
    "风险用户提示": "Risk kullanıcı uyarı",
    "驱动": "sürücü",
    "驱动方式": "Araç yöntemi",
    "驾安配": "Güvenlik ekipmanlarını kullanmak",
    "验证码": "Verification code",
    "验证码登录": "Verification code login",
    "高": "high",
    "高质量货源，全平台覆盖，自带销量": "High quality supply, full platform coverage, and built-in sales volume",
    "默认": "öntanımlı",
    "鼓式制动器": "Drum brake",
    "下单成功": "kontrol başarısı",
    "浏览记录": "Ver el historial",
    "共{count}家店": "Toplamda {count} mağaza bulunmaktadır"
  }
}


export default tr