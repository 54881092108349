const zh = {
  locale: 'zh-CN',
  messages: {
    "共{count}家店": "共 {count} 家店",
    "应付货款({unit})": "应付货款({unit})",
    "约{unit}{money}": "约{unit}{money}"
  }
}


export default zh