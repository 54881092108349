import React, { useContext, useEffect, useReducer, useState, useRef, useMemo, useCallback } from "react";
import { Modal, Switch, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import Header from "./Header";
import Footer from "./Footer";
import Sider from './Sider';
import styles from './index.module.scss'
import Router from "../../Routes"
import MainContext from '../../stores/stores'
import accountApi from "../../api/accountApi";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AliveScope } from 'react-activation'
import axios from "axios";
import { useIntl } from 'react-intl'

// import SDK from '@yxim/nim-web-sdk'

import {
  Provider, // 全局上下文
} from '@/YXUIKit/im-kit-ui/src'
//UIKit 国际化
import en from '@/components/IMApp/locales/en'
import zh from '@/components/IMApp/locales/zh'
import ru from '@/components/IMApp/locales/ru'
import cht from '@/components/IMApp/locales/cht';
import tr from '@/components/IMApp/locales/tr';
import ara from '@/components/IMApp/locales/ara';
import pt from '@/components/IMApp/locales/pt'
import vie from '@/components/IMApp/locales/vie';
import { LocalOptions } from '@xkit-yx/im-store'


type MainContentProps = {
  pageType: string,
  market: number
}

const reducer = (state: any, action: any) => {
  switch(action.type) {
    case 'userInfo':
      return {
        ...state,
        userInfo: action.value
      };
    case 'areaOptions':
      return {
        ...state,
        areaOptions: action.value
      };
    case 'cartNum':
      return {
        ...state,
        cartNum: action.value
      }
    case 'lang': 
      return {
        ...state,
        lang: action.value
      }
    case 'pageType': 
      return {
        ...state,
        pageType: action.value
      }
    case 'logo': 
      return {
        ...state,
        logo: action.value
      }
    case 'isWomala': 
      return {
        ...state,
        isWomala: action.value
      }
    case 'webData': 
      return {
        ...state,
        webData: action.value
      }
    case 'host': 
      return {
        ...state,
        host: action.value
      }
    case 'crumb':
      return {
        ...state,
        crumb: action.value
      }
    case 'vinData':
      return {
        ...state,
        vinData: action.value
      }
    case 'colorPrimary': 
      return {
        ...state,
        colorPrimary: action.value
      }
    case 'canLang': 
      return {
        ...state,
        canLang: action.value
      }
		case 'open_new': 
			return {
				...state,
				open_new: action.value
			}
		case 'isCst':
			return {
				...state,
				isCst: action.value
			}
    case 'market':
      return {
        ...state,
				market: action.value
      }
    case 'currency_data':
      return {
        ...state,
        currency_data: action.value
      }
    case 'countrie_data':
      return {
        ...state,
        countrie_data: action.value
      }
    case 'exrate':
      return {
        ...state,
        exrate: action.value
      }
		case 'distributorInfo':
			return {
				...state,
				distributorInfo: action.value
			}
    case 'imChatData':
      return {
        ...state,
        imChatData: action.value
      }
    case 'supplierOnline':
      return {
        ...state,
        supplierOnline: action.value
      }
    case 'unreadMsgCount':
      return {
        ...state,
        unreadMsgCount: action.value
      }
    case 'msgNoticeData': 
      return {
        ...state,
        msgNoticeData: action.value
      }
    case 'isOperate': 
      return {
        ...state,
        isOperate: action.value
      }
    case 'siteName': 
      return {
        ...state,
        siteName: action.value
      }
    case 'hasFreeVin':
      return {
        ...state,
        hasFreeVin: action.value
      }
    default:
      return state
  }
}
let runLayui = true
let online = false
let activeItem: any = {}
let companyData: any = {}
const Main: React.FC<any> = (props) => {
  const intl = useIntl()
  // const [api, contextHolder] = notification.useNotification();
  const imChatData = JSON.parse(localStorage.getItem('imChatData') || '{}')
  const navigate = useNavigate()
  const [commonStore, setCommonStore] = useReducer(reducer, {
    userInfo: {},
    areaOptions: [],
    pageType: localStorage.getItem("style") || '2',
    logo: '',
    isWomala: false,
    webData: {},
    host: '',
    crumb: JSON.parse(window.sessionStorage.getItem('crumb') || '[]'),
    vinData: {},
    colorPrimary: localStorage.getItem("colorPrimary") || '#434343',
    canLang: [],
		open_new: '1',
		isCst: 0,
    market: 1,
    currency_data: {
      code: "CNY",
      name: intl.formatMessage({id: '人民币'}),
      unit: "￥"
    },
    countrie_data: {
      code: "CNY",
      unit: "￥"
    },
    exrate: 1,
		distributorInfo: {},
    imChatData: {
      appKey: imChatData.appKey,
      account: imChatData.account,
      token: imChatData.token
    },
    supplierOnline: [],
    unreadMsgCount: 0,
    msgNoticeData: {},
    isOperate: false,
    siteName: intl.formatMessage({id: '商城'}),
    hasFreeVin: false
  })
  const { pageType, colorPrimary } = commonStore

  const { pathname } = useLocation()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const [ uid, setUid ] = useState<string>('')

  let _nim = useRef<any>(null)

  let vinkey = searchParams.get('vinkey') || ""

  const token = localStorage.getItem("token") || ""
  let other = ['/class', '/vin', '/code', '/carsearch/list', '/morepage']
  useEffect(() => {

    let host = window.location.host
    setCommonStore({ value: host, type: 'host' })
    if(host.includes('womala')) {
      setCommonStore({ value: true, type: 'isWomala' })
    }
    getSysiting()
    getHomeData()
    getIsCst()
    if(token) {
      getUserInfo()
			getDistributorInfo()
      fetch('https://xqpstatics.007vin.com/pca.json')
      .then(response => response.json())
      .then(data => {
        setCommonStore({value: data, type: 'areaOptions'})
      })
    }
  },[])
  useEffect(() => {
    if(commonStore.currency_data && commonStore.currency_data.code && commonStore.countrie_data && commonStore.countrie_data.code) {
      if(commonStore.currency_data.code !== commonStore.countrie_data.code) {
        getExchangeRate()
      }
    }
  }, [commonStore.currency_data, commonStore.countrie_data])
  // useEffect(() => {
  //   if(token && uid) {
  //     getIm2(uid)
  //   }
  // }, [uid])

	const getDistributorInfo = () => {
		accountApi.getDistributorInfo().then((res: any) => {
			if(res.code === 1) {
				setCommonStore({value: res.data, type: 'distributorInfo'})
			}
		})
	}
	
	const getIsCst = () => {
	  accountApi.getIsCst().then((res: any) => {
	    if(res.code === 1) {
				setCommonStore({value: res.data.is_cst, type: 'isCst'})
        let _market = res.data && res.data.market ? res.data.market : 1
        let currency_data = res.data && res.data.currency_data && res.data.currency_data.code ? res.data.currency_data : {
          code: "CNY",
          name: intl.formatMessage({id: '人民币'}),
          unit: "￥"
        }
        let countrie_data = res.data && res.data.user_currency && res.data.user_currency.countrie_monetary_en ? {
          code: res.data.user_currency.countrie_monetary_en,
          unit: res.data.user_currency.countrie_symbol ? res.data.user_currency.countrie_symbol : ''
        } : {
          code: 'CNY',
          unit: "￥"
        }
        currency_data.countryCode = res.data.countryCode || 'CN'
        setCommonStore({value: _market, type: 'market'})
        setCommonStore({
          value: currency_data,
          type: 'currency_data'
        })
        setCommonStore({
          value: countrie_data,
          type: 'countrie_data'
        })
	    }
	  })
	}
  const getExchangeRate = () => {
    accountApi.getCurrencyRate({
      from_currency: commonStore.currency_data.code,
      to_currency: commonStore.countrie_data.code
    }).then((res: any) => {
      if(res.code === 1) {
        let _exrate = res.data
        let isNumber = isNaN(parseFloat(_exrate)) ? false : true
        if(typeof _exrate === 'string' && isNumber) {
          setCommonStore({value: parseFloat(_exrate), type: 'exrate'})
        }else if(typeof _exrate === 'number') {
          setCommonStore({value: _exrate, type: 'exrate'})
        }
      }
    })
  }
	const getHomeData = () => {
    let lang = localStorage.getItem('lang') || undefined
    accountApi.getHomeDataThree({ 
      domain: window.location.host,
      lang
    }).then((res: any) => {
      if(res.code === 1) {
        let dataVin = res.data && res.data.vin ? res.data.vin : []
        let contract2 = res.data && res.data.contract2 ? res.data.contract2 : {}
        if(lang) {
          let item = contract2[lang] ? contract2[lang] : {}
          setCommonStore({ value: item, type: 'webData' })
        }
        setCommonStore({ value: dataVin.length ? true : false, type: 'hasFreeVin'})
        setCommonStore({ value: res.data.name, type: 'siteName' })
      }
    })
  }
	
  const getSysiting = () => {
    let params = {
      lang: localStorage.getItem('lang') || undefined,
      domain: window.location.host
    }
    accountApi.getSysiting(params).then((res: any) => {
      if(res.code === 1) {
        let localstyle = localStorage.getItem("style")
        if(!localstyle || (res.data.style !== localstyle)) {
          localStorage.setItem('style', res.data.style || '2')
          setCommonStore({ value: res.data.style || '2', type: 'pageType' })
        }
        setCommonStore({ value: res.data.logo, type: 'logo' })
        document.title = res.data.name || ''
        if(res.data.icon) {
          let icon = document.createElement("link")
          icon.rel = 'icon'
          icon.href = res.data.icon
          document.getElementsByTagName('head')[0].appendChild(icon)
        }
				setCommonStore({ value: res.data.open_new ? res.data.open_new : '1', type: 'open_new' })
        // setCommonStore({ value: res.data, type: 'webData' })
        setCommonStore({ value: res.data.languages, type: 'canLang' })
        
        setCommonStore({ value: res.data.color || '#434343', type: 'colorPrimary' })
        localStorage.setItem("colorPrimary", res.data.color || '#434343')
        // document.getElementsByTagName("body")[0].style.setProperty('--test', '#898734')
      }
    })
  }
  const getUserInfo = () => {
    accountApi.getUserInfo().then((res: any) => {
      if(res.code === 1) {
        setCommonStore({value: res.data, type: 'userInfo'})
        res.data.company = res.data.reg_company_name
        // getIm2(res.data.uid)
        setUid(res.data.uid)
        localStorage.setItem('uid', res.data.uid)
        
      }
    })
  }


	
  /* const openNotification = (data: any) => {
		if(data.notice_type === 'refund') {
		  const key = `open${Date.now()}`;
		  const btn = (
		    <Button 
					type="primary" 
					onClick={() => {
						api.destroy();
						if(pathname === '/mine/aftersales/detail') {
							window.location.reload()
						}else {
							navigate(`/mine/aftersales/detail?merchant_id=${data.merchant_id}&inquiry_id=${data.inquiry_id}&refund_id=${data.refund_id}`)
						}
					}}>
					{intl.formatMessage({ id: '查看详情' })}
				</Button>
		  );
		  let refund_status = data.refund_status
		  let message;
		  if(refund_status === 1) {
		    message = intl.formatMessage({ id: '售后订单商家已同意！' })
		  }else {
		    message = intl.formatMessage({ id: '售后订单商家已拒绝！' })
		  }
		  api.open({
		    message: message,
		    duration: null,
		    description:(
		      <div>
		        <div className="mt10">
							{intl.formatMessage({ id: '创建时间' })}:{data.create_time}
						</div>
						<div className="mt10">
              {intl.formatMessage({ id: '处理时间' })}:{data.send_time}
						</div>
		      </div>
		    ),
		    btn,
		    key,
		    // onClose: close,
		  });
		}
    if(data.notice_type === 'order_quote_finished' || data.notice_type === 'order_wait_send') {
      const key = `open${Date.now()}`;
      const btn = (
        <Button type="primary" onClick={
					() => {
						if(window.location.pathname === '/mine/order/detail') {
						  window.location.reload()
						}else {
						  api.destroy(); 
							navigate(`/mine/order/detail?merchant_id=${data.merchant_id}&inquiry_id=${data.enquiry_info.enquiry_id}`)
						}
					}
				}
				>{intl.formatMessage({ id: '查看详情' })}</Button>
      );
      let inquiry_status = data.inquiry_status
      let car_info = data.car_info 
      let message;
      if(inquiry_status === 5) {
        message = intl.formatMessage({ id: '订单已报价，请尽快处理！' })
      }else {
        message = intl.formatMessage({ id: '订单已发货，请注意查收！' })
      }
      api.open({
        message: message,
        duration: null,
        description:(
          <div>
            {
              car_info ? (
                <div className="flex-s-c">
                  {car_info.brand_logo ? <img style={{ height: 20, border: '1px solid #f6f6f6' }} src={car_info.brand_logo} /> : ''}
                  {car_info.market_name}
                </div>
              ): null
            }
            <div className="mt20">{intl.formatMessage({ id: '创建时间' })}：{data.order_create_time}</div>
            {
              inquiry_status === 5 ? (
                <div className="mt10">{intl.formatMessage({ id: '报价时间' })}：{data.quote_time}</div>
              ): inquiry_status === 8 ? (
                <div className="mt10">{intl.formatMessage({ id: '发货时间' })}：{data.send_time}</div>
              ): null
            }
          </div>
        ),
        btn,
        key,
        // onClose: close,
      });
    }
    if(data.notice_type === 'user_verify') {
      const key = `open${Date.now()}`;
      const btn = (
        <Button type="primary" onClick={() => {
          if(window.location.pathname === '/mine/company') {
            window.location.reload()
          }else {
            api.destroy(); navigate(`/mine/company`)
          }
        }
        }>{intl.formatMessage({ id: '前往处理' })}</Button>
      );
      let phone = data.phone
      let _phone = phone.slice(-4)
      let message = `尾号${_phone}用户，提交了认证审核！`
      let time = data.create_time
      api.open({
        message: intl.formatMessage({ id: '审核单' }),
        duration: null,
        description:(
          <div>
            <div style={{color: '#333333', fontSize: 14}}>{message}</div>
            <div style={{marginTop: 8, color: '#888888', fontSize: 14}}>{time}</div>
          </div>
        ),
        btn,
        key,
        // onClose: close,
      });
    }
  }; */

  /* const getIm = (uid: string) => {
    let params = {
      uid
    }
    accountApi.getIm(params).then((res: any) => {
      if(res.code === 1) {
        let itemData = res.data
        setCommonStore({
          type: 'imChatData',
          value: {
            appKey: itemData.app_key,
            account: itemData.accid,
            token: itemData.token,
          }
        })
        let _window: any = window
        _nim.current = _window.SDK.NIM.getInstance({
          appKey: itemData.app_key,
          account: itemData.accid,
          token: itemData.token,
          onsessions: function(sessions: any){
            console.log('sessions:', sessions)
          },
          onconnect: function () {
            console.log('SDK 连接成功')
            if(runLayui){
              layui()
              runLayui = false
            }
            online = true
          },
          ondisconnect: function(obj: any) {
            console.log('SDK 连接断开', obj)
            online = false
            if(obj.code !== "kicked"){
              setTimeout(()=>{
                console.log('重新连接1')
                getIm(uid)
              }, 1000 * 5)
            }else {
              // obj.code === 417 || 302
            }
          },
          onerror: function(error: any) {

            console.log('SDK 连接失败', error)
            setTimeout(()=>{
              console.log('重新连接2')
              getIm(uid)
            }, 1000 * 5)
          },
          onmsg: function(msg: any) {
            console.log('onmsg msg:', msg)
            console.log('msg.text:', JSON.parse(msg.text))
            let msgTextObj = msg.text ? JSON.parse(msg.text) : {}
            if(msg.from === itemData.accid) { 
              let _window: any = window
              let local = _window.layui.data('layim')[itemData.accid] || {};
              if(local && local.chatlog && local.chatlog['friend' + msg.to].length){
                let toUser = local.chatlog['friend' + msg.to][0]
                if(msg.type === 'image'){
                  msg.text = 'img['+ msg.file.url +']'
                }
                local.chatlog['friend' + msg.to].push({
                  username: toUser.username,
                  avatar: toUser.avatar,
                  id: toUser.id,
                  type: toUser.type,
                  content: msg.text,
                  timestamp: msg.time,
                  mine: true
                })
                console.info('=======123=======')
              }
              _window.layui.data('layim', {
                key: itemData.accid,
                value: local
              });
              _window.layim.syncRecord()
            }
            if((msg.type === "text" || msg.type === 'image') && msg.from !== itemData.accid){
              let _window: any = window
              let content = {}
              try {
                content = JSON.parse(msg.text)
              }catch(err){

              }
              let isFromOrder = msgTextObj.notice_type === 'refund' || msgTextObj.notice_type === 'order_quote_finished' || msgTextObj.notice_type === 'order_wait_send' || msgTextObj.notice_type === 'user_verify' ? true : false
              if(isFromOrder) {
                openNotification(JSON.parse(msg.text))
              }else {
                _nim.current.subscribeEvent({
                  type: 1,
                  accounts: [msg.from],
                  subscribeTime: 70,
                  sync: true,
                  done: (error: any, obj: any)=>{
                    console.log('取消订阅事件' + (!error?'成功':'失败'), error, obj);
                  }
                });
                _nim.current.getUser({
                  account: msg.from,
                  done: (error: any, obj: any)=>{
                    console.log('取消订阅事件' + (!error?'成功':'失败'), error, obj);
                    let param = {
                      username: msg.fromNick,
                      id: msg.from,
                      avatar: '/webshop/layim/images/sellerphone.png',
                      type: "friend",
                      content: msg.text,
                      timestamp: msg.time
                    }
                    if(!error && obj.avatar){
                      param.avatar = obj.avatar
                    }
                    if(msg.type === 'image'){
                      param.content = 'img['+ msg.file.url +']'
                    }
                    _window.layim.getMessage(param)
                    _window.jQuery('.layim-list-history li').each(function(index: number, element: any){
                      let _this: any = element
                      if(('friend' + param.id) == _window.jQuery(_this).attr('data-index') && (('friend'+ _window.activeImWindowId) != _window.jQuery(_this).attr('data-index') || _window.activeImWindowStatus)){
                        _window.jQuery('#imTips').addClass('im-tips-ani')
                        if(!_window.jQuery(_this).find('em')[0]){
                          let em = _window.jQuery("<em>1</em>")
                          _window.jQuery(_this).append(em)
                        }else {
                          let i = parseInt(_window.jQuery(_this).find('em').text())
                          i++
                          if(i > 99){
                            i = 99
                          }
                          _window.jQuery(_this).find('em').text(i)
                        }
                      }
                    })
                  }
                })
              }
            }
          },
          onRoamingMsgs: function(obj: any) {
            console.log('收到漫游消息', obj);
          },
          onofflinemsgs: function(obj: any) {
            console.log('收到离线消息', obj);
            let _window: any = window
            obj.msgs.forEach((item: any)=>{
              let msgTextObj = item.text ? JSON.parse(item.text) : {}
              if(item.from === itemData.accid){
                let local = _window.layui.data('layim')[itemData.accid] || {};
                // local.history = {}
                if(local && local.chatlog && local.chatlog['friend' + item.to].length){
                  let toUser = local.chatlog['friend' + item.to][0]
                  if(item.type === 'image'){
                    item.text = 'img['+ item.file.url +']'
                  }
                  local.chatlog['friend' + item.to].push({
                    username: toUser.username,
                    avatar: toUser.avatar,
                    id: toUser.id,
                    type: toUser.type,
                    content: item.text,
                    timestamp: item.time,
                    mine: true
                  })
                }
                _window.layui.data('layim', {
                  key: itemData.accid
                  ,value: local
                });
                _window.layim.syncRecord && _window.layim.syncRecord()
              }
              if((item.type === "text" || item.type === 'image') && item.from !== itemData.accid){
                let content = {}
                try {
                  content = JSON.parse(item.text)
                }catch(err){}
                let isFromOrder = msgTextObj.notice_type === 'refund' || msgTextObj.notice_type === 'order_quote_finished' || msgTextObj.notice_type === 'order_wait_send' || msgTextObj.notice_type === 'user_verify' ? true : false
                if(isFromOrder) {
                  openNotification(JSON.parse(item.text))
                }else {
                  _nim.current.getUser({
                    account: item.from,
                    done: (error: any, obj: any)=>{
                      console.log('取消订阅事件' + (!error?'成功':'失败'), error, obj);
                      let param = {
                        username: item.fromNick,
                        id: item.from,
                        avatar: '/webshop/layim/images/sellerphone.png',
                        type: "friend",
                        content: item.text,
                        timestamp: item.time
                      }
                      if(!error && obj.avatar){
                        param.avatar = obj.avatar
                      }
                      if(item.type === 'image'){
                        param.content = 'img['+ item.file.url +']'
                      }
                      _window.layim.getMessage(param)
                      _window.jQuery('.layim-list-history li').each(function(index: number, element: any){
                        let _this: any = element
                        if(('friend'+param.id) == _window.jQuery(_this).attr('data-index') && (('friend'+ _window.activeImWindowId) != _window.jQuery(_this).attr('data-index') || _window.activeImWindowStatus)){
                          _window.jQuery('#imTips').addClass('im-tips-ani')
                          if(!_window.jQuery(_this).find('em')[0]){
                            let em = _window.jQuery("<em>1</em>")
                            _window.jQuery(_this).append(em)
                          }else {
                            let i = parseInt(_window.jQuery(_this).find('em').text())
                            i++
                            if(i > 99){
                              i = 99
                            }
                            _window.jQuery(_this).find('em').text(i)
                          }
                        }
                      })
                    }
                  })
                  
                }
              }
              
            })
          },
          onpushevents: function(param: any) {
            console.log('订阅事件', param)
            let _window: any = window
            if(param && param.msgEvents){
              let _online: any[] = []
              param.msgEvents.forEach((item: any)=>{
                let serverConfig = JSON.parse(item.serverConfig)
                if(serverConfig && serverConfig.online && serverConfig.online.length){
                  _online.push(item.account)
                  _window.jQuery('.layim-list-history li').each(function(index: number, element: any){
                    let _this: any = element
                    if(('friend'+item.account) == _window.jQuery(_this).attr('data-index')){
                      _window.jQuery(_this).find('p').html('<i></i>' + '在线')
                    }
                  })
                }
              })
              setCommonStore({
                type: 'supplierOnline',
                value: _online
              })
            }
          }
        })
        
      }
    })
  }
  const layui = () => {
    let _window: any = window
    let layui: any = _window.layui
    let userInfo = commonStore.userInfo
    let companyDataTips: any = {}
    let statusList = []
    let companyAcitveId = ''
    
    layui.use('layim', function(layim: any){
      _window.layim = layim

      var wsSend = (data: any) => {
        if(data.content.indexOf('img[') !== 0){
          _nim.current.sendText({
            scene: 'p2p',
            to: data.toId,
            text: data.content,
            fromNick: data.username,
            done: function(error: any, msg: any) {
              console.info(msg)
            }
          })
        }
        
      }
      
      let accid = commonStore.imChatData && commonStore.imChatData.account ? commonStore.imChatData.account : ''
      //基础配置
      layim.config({
        init: {
          mine: {
            "username": userInfo.wechat_nick || userInfo.phone //我的昵称
            ,"id": accid //我的ID
            ,"status": "online" //在线状态 online：在线、hide：隐身
            ,"remark": "" //我的签名
            ,"avatar": userInfo.avatar || '/webshop/layim/images/phone.png' //我的头像
            ,'userInfo': userInfo
          }
          ,friend: []
          ,group: []
        },
        isfriend: true,
        isgroup: false,
        title: '在线沟通',
        notice: true //是否开启桌面消息提醒，默认false
        // ,chatLog: layui.cache.dir + 'css/modules/layim/html/chatlog.html' 
        // ,chatLog: process.env.NODE_ENV === 'development' ? '/chatlog.html' : '/chat/log'
      });
    
      let local = _window.layui.data('layim')[accid]
      local = local || {}
      if(local.history){
        let idArr = []
        for(let key in local.history){
          idArr.push(local.history[key].id)
        }
        _nim.current.subscribeEvent({
          type: 1,
          accounts: idArr,
          subscribeTime: 70,
          sync: true,
          done: (error: any, obj: any)=>{
            console.log('取消订阅事件' + (!error?'成功':'失败'), error, obj);
          }
        });
      }
      console.info('local=======')
      console.info(local)

      //监听在线状态的切换事件
      layim.on('online', function(data: any){
        //console.log(data);
      });
      
      //监听签名修改
      layim.on('sign', function(value: any){
        //console.log(value);
      });
    
      
      //监听layim建立就绪
      // layim.on('ready', function(res){
      //   console.info(res)
      // });
    
      //监听发送消息
      layim.on('sendMessage', function(data: any){
        console.info(data)
        if(online){
          wsSend({
            username: data.mine.username,
            id: data.mine.id,
            toId: data.to.id,
            type: data.to.type,
            content: data.mine.content,
            avatar: data.mine.avatar,
            tel: data.mine.userInfo.phone || '暂无'
          })
        }else {
          antdMessage.warning('消息发送失败，请稍后重试！')
        }
      });
    
      //监听查看群员
      layim.on('members', function(data: any){
        console.log('members:', data);
      });
      

      //监听聊天窗口的切换
      layim.on('chatChange', function(res: any){
        let type = res.data.type;
        console.info('res=======')
        activeItem = res
        if(type === 'friend'){
          companyAcitveId = res.data.id

          _window.jQuery('.layim-list-history li').each(function(index: number, element: any){
            let _this: any = element
            if(('friend'+res.data.id) == _window.jQuery(_this).attr('data-index')){
              _window.jQuery('#imTips').removeClass('im-tips-ani')
              if(_window.jQuery(_this).find('em')[0]){
                _window.jQuery(_this).find('em').remove()
              }
            }
          })
          console.info(res.data)

          companyData = res.data || {}
          let info = ''
          
          
          if(res.data.companyData){
            companyDataTips[res.data.id] = res.data
          }
          if(commonStore.supplierOnline.indexOf(companyAcitveId) !== -1 ){
            _window.jQuery('.layim-chat-status-icon').html('<i></i>' + '在线')
          }

          let imContact = JSON.parse(window.localStorage.getItem("imContact") || '{}')
          
          if(imContact[res.data.id]){
            let contact = imContact[res.data.id]
            info ='<p class="copyOn" title="'+ contact.phone +'">电话: ' + contact.phone + '<span class="font_family icon-copy im-copy" />' +'</p>'
            if(contact.wechat){
              info += ('<p class="copyOn" title="'+ contact.qq +'">' + 'Q Q: ' + contact.qq + '<span class="font_family icon-copy im-copy" />' + '</p>')
            }
            if(contact.qq){
              info += ('<p class="copyOn" title="'+ contact.wechat +'">' + '微信: ' + contact.wechat + '<span class="font_family icon-copy im-copy" />' + '</p>')
            }
            layim.setChatStatus(info);
          }else {
            commonApi.getImUserInfo({yc_id: res.data.id}).then((result: any)=>{
              if(result.code === 1 && result.data.contact){
                let contact = result.data.contact
                imContact[res.data.id] = contact
                info ='<p class="copyOn" title="'+ contact.phone +'">电话: ' + contact.phone + '<span class="font_family icon-copy im-copy" />' +'</p>'
                if(contact.wechat){
                  info += ('<p class="copyOn" title="'+ contact.qq +'">' + 'Q Q: ' + contact.qq + '<span class="font_family icon-copy im-copy" />' + '</p>')
                }
                if(contact.qq){
                  info += ('<p class="copyOn" title="'+ contact.wechat +'">' + '微信: ' + contact.wechat + '<span class="font_family icon-copy im-copy" />' + '</p>')
                }
                localStorage.setItem('imContact', JSON.stringify(imContact));
              }
              layim.setChatStatus(info);
            })
          }
          

          
        }
      })
    })
    function fileReaderupdateImg(file: any){
      let _window: any = window
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e: any) => {
        let img: any = new Image()
        img.src = e.target.result
        img.onload = () => {
          let data = compress(img)
          let blob = dataURItoBlob(data)
          let formData = new FormData()
          formData.append('img', blob)
          console.info(blob)
          _window.jQuery('#updateImgTips').show()
          _nim.current.previewFile({
            type: 'image',
            blob: blob,
            done: function(error: any, file: any) {
              _window.jQuery('#updateImgTips').hide()
              if(!error){
                _window.layim.chat('send', 'img['+ file.url +']')
                if(activeItem.data.id){
                  _nim.current.sendFile({
                    scene: 'p2p',
                    to: activeItem.data.id,
                    blob: blob,
                    type: 'image',
                    done: function (error: any, msg: any) {
                      console.info(msg)
                    }
                  })
                }
              }
            }
          })
          
        }
      }

    }
    
    _window.jQuery('.updateImg').live('paste', function(event: any){
      let clipboardData = event.originalEvent.clipboardData
      if ( !(clipboardData && clipboardData.items) ) {
        return;
      }
      for (let i = 0, len = clipboardData.items.length; i < len; i++) {
        let item = clipboardData.items[i];
        if (item.type.indexOf('image') !== -1) {
          let f = item.getAsFile();
          fileReaderupdateImg(f)
        }
      }
    })
    
    _window.jQuery('.updateImg').live('drop', function(event: any){
      event.preventDefault()
      let f = event.originalEvent.dataTransfer.files[0];
      if(f.type.indexOf('image') !== -1 ){
        fileReaderupdateImg(f)
      }
    })

    _window.jQuery('.copyOn').live('click', function(event: any){
      event.preventDefault()
      let _this: any = event.target
      copy(_window.$(_this).attr('title'))
    })
  }

  const compress = (img: any) => {
    let canvas = document.createElement('canvas')
    let ctx: any = canvas.getContext('2d')
    let width = img.width
    let height = img.height
    canvas.width = width
    canvas.height = height
    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.drawImage(img, 0, 0, width, height)
    let ndata = canvas.toDataURL('image/jpeg', 0.5)
    return ndata
  }
  const dataURItoBlob = (base64Data: any) => {
    let byteString
    if (base64Data.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(base64Data.split(',')[1])
    } else {
      byteString = unescape(base64Data.split(',')[1])
    }
    let mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0]
    let ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ia], { type: mimeString })
  }
  const copy = (text: string) => {
    let oDiv = document.createElement('textarea')
    oDiv.value = text
    oDiv.innerHTML = text
    document.body.appendChild(oDiv)
    oDiv.select()
    document.execCommand("Copy")
    document.body.removeChild(oDiv)
    antdMessage.success('复制成功！')
  } */
  // 使用NIM  nim-web-sdk 网易云信9.x版本
  const getIm2 = async (uid: string) => {
    let params = {
      uid
    }
    let res = await accountApi.getIm(params)
    if(res.code === 1) {
      let itemData = res.data
      setCommonStore({
        type: 'imChatData',
        value: {
          appKey: itemData.app_key,
          account: itemData.accid,
          token: itemData.token,
        }
      })
      /* _nim.current = SDK.NIM.getInstance({
        debug: true,
        appKey: itemData.app_key,
        account: itemData.accid,
        token: itemData.token,
        onconnect: function () {
          console.log('SDK 连接成功')
        },
        ondisconnect: function(obj: any) {
          console.log('SDK 连接断开', obj)
          if(obj.code !== "kicked"){
            setTimeout(()=>{
              console.log('重新连接1')
              getIm2(uid)
            }, 1000 * 5)
          }else {
            // obj.code === 417 || 302
          }
        },
        onerror: function(error: any) {

          console.log('SDK 连接失败', error)
          setTimeout(()=>{
            console.log('重新连接2')
            getIm2(uid)
          }, 1000 * 5)
        },
        onmsg: function(msg: any) {
          console.log('onmsg msg:', msg)
          if(msg.from === itemData.accid) {
            console.log('msg.text:', JSON.parse(msg.text))
            let msgTextObj = msg.text ? JSON.parse(msg.text) : {}
            let isFromOrder = msgTextObj.notice_type === 'refund' || msgTextObj.notice_type === 'order_quote_finished' || msgTextObj.notice_type === 'order_wait_send' || msgTextObj.notice_type === 'user_verify' ? true : false
            if(isFromOrder) {
              openNotification(JSON.parse(msg.text))
            }
          }
        },
        onroamingmsgs: function(obj: any) {
          console.log('收到漫游消息', obj);
        },
        onofflinemsgs: function(obj: any) {
          console.log('收到离线消息', obj);
          obj.msgs.forEach((item: any)=>{
            if(item.from === itemData.accid) {
              let msgTextObj = item.text ? JSON.parse(item.text) : {}
              let isFromOrder = msgTextObj.notice_type === 'refund' || msgTextObj.notice_type === 'order_quote_finished' || msgTextObj.notice_type === 'order_wait_send' || msgTextObj.notice_type === 'user_verify' ? true : false
              if(isFromOrder) {
                openNotification(JSON.parse(item.text))
              }
            }
          })
        },
        onsysmsgunread: function(obj: any) {
          console.log('未读系统消息', obj)
          let total = obj.total
          setCommonStore({
            type: 'unreadMsgCount',
            value: total
          })
        }
      }) */
      
    }
  }

  const [curLanguage, setCurLanguage] = useState<"zh" | "en" | "ru" | "cht" | "tr" | "ara" | "pt" | "vie">('zh')
    // sdk版本
    const urlParams = new URLSearchParams(window.location.search)
    // 添加好友是否需要验证
    const [addFriendNeedVerify, setAddFriendNeedVerify] = useState<boolean>(false)
    //单聊消息是否显示已读未读
    const [p2pMsgReceiptVisible, setP2pMsgReceiptVisible] =
      useState<boolean>(true)
    //群聊消息是否显示已读未读
    const [teamMsgReceiptVisible, setTeamMsgReceiptVisible] =
      useState<boolean>(true)
    // 是否需要@消息
    const [needMention, setNeedMention] = useState<boolean>(true)
    // 是否开启群管理员功能
    const [teamManagerVisible, setTeamManagerVisible] = useState<boolean>(true)
    const languageMap = { zh, en, ru, cht, tr, ara, pt, vie }
    // 初始化参数
    const initOptions = useMemo(() => {
      if(commonStore.imChatData.appKey) {
        return {
          appkey: commonStore.imChatData.appKey,
          account: commonStore.imChatData.account,
          token: commonStore.imChatData.token,
          lbsUrls: ['https://lbs.netease.im/lbs/webconf.jsp'],
          linkUrl: 'weblink.netease.im',
          needReconnect: true,
          reconnectionAttempts: 5,
        }
      }else {
        return {
          appkey: '',
          account: '',
          token: '',
          lbsUrls: ['https://lbs.netease.im/lbs/webconf.jsp'],
          linkUrl: 'weblink.netease.im',
          needReconnect: true,
          reconnectionAttempts: 5,
        }
      }
      
    }, [commonStore.imChatData.appKey, commonStore.imChatData.account, commonStore.imChatData.token])
    // 本地默认行为参数
    const localOptions: Partial<LocalOptions> = {
      // 添加好友模式，默认需要验证
      addFriendNeedVerify,
      // 群组被邀请模式，默认不需要验证
      teamBeInviteMode: 'noVerify',
      // 单聊消息是否显示已读未读
      p2pMsgReceiptVisible,
      // 群聊消息是否显示已读未读
      teamMsgReceiptVisible,
      // 是否需要@消息
      needMention,
      // 是否开启群管理员
      teamManagerVisible,
      // 是否显示在线离线状态
      loginStateVisible: true,
      // 是否允许转让群主
      allowTransferTeamOwner: true,
    }
    
    useEffect(() => {
      const _languageType = localStorage.getItem('lang') as "zh" | "en" | "ru" | "cht" | "tr" | "ara" | "pt" | "vie"
      const _addFriendNeedVerify = sessionStorage.getItem('addFriendNeedVerify')
      const _p2pMsgReceiptVisible = sessionStorage.getItem('p2pMsgReceiptVisible')
      const _teamMsgReceiptVisible = sessionStorage.getItem(
        'teamMsgReceiptVisible'
      )
      const _needMention = sessionStorage.getItem('needMention')
      const _teamManagerVisible = sessionStorage.getItem('teamManagerVisible')
      setCurLanguage(_languageType || 'zh')
      if (_p2pMsgReceiptVisible) {
        setP2pMsgReceiptVisible(_p2pMsgReceiptVisible === 'true')
      }
      if (_teamMsgReceiptVisible) {
        setTeamMsgReceiptVisible(_teamMsgReceiptVisible === 'true')
      }
      if (_addFriendNeedVerify) {
        setAddFriendNeedVerify(_addFriendNeedVerify === 'true')
      }
      if (_needMention) {
        setNeedMention(_needMention === 'true')
      }
      if (_teamManagerVisible) {
        setTeamManagerVisible(_teamManagerVisible === 'true')
      }
    }, [])
    const renderImDisConnected = useCallback(() => {
      let minHeight = `calc(100vh - ${pageType === '1' || pageType === '3' ? '150px' : '70px'})`
      return (
        <>
          <Header/>
          <div style={{ minHeight }}>
            <AliveScope>
              {
                other.includes(pathname) || pathname.includes("/morepage") ? (
                  <Router key={pathname + vinkey} />
                ): <Router  />
              }
            </AliveScope>
          </div>
          {
            token && pageType !== '1' ? <Sider/> : null
          }
          {
            pathname !== '/epc/vin/detail' ? (
              <Footer/>
            ): null
          }
        </>
      )
    }, [token, pageType, pathname, vinkey])
  
  return (
    <div className={styles['main']}>
      {/* {contextHolder} */}
      <MainContext.Provider value={{commonStore, setCommonStore}}>
        {/* <ConfigProvider
          theme={{
            token: {
              colorPrimary
            }
          }}
        > */}
          {
            commonStore.imChatData.appKey ? <Provider
              sdkVersion={1}
              localeConfig={languageMap[curLanguage]}
              initOptions={initOptions}
              localOptions={localOptions}
              singleton={true}
              renderImDisConnected={renderImDisConnected}
            >
              <MainContent pageType={pageType} market={commonStore.market}/>
            </Provider> : <MainContent pageType={pageType} market={commonStore.market}/>
          }
        {/* </ConfigProvider> */}
      </MainContext.Provider>
    </div>
  )
}

const MainContent: React.FC<MainContentProps> = (props) => {
  const { pageType } = props
  const { commonStore, setCommonStore } = useContext(MainContext)
  let minHeight = `calc(100vh - ${pageType === '1' || pageType === '3' ? '150px' : '70px'})`
  const token = localStorage.getItem("token") || ""
  let other = ['/class', '/vin', '/code', '/oe', '/carsearch/list', '/morepage']
  const { pathname } = useLocation()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const [ cookieShow, setCookieShow] = useState<boolean>(false)
  let vinkey = searchParams.get('vinkey') || ""
  useEffect(() => {
    if(commonStore.market !== 1) {
      if(!localStorage.getItem('cookie_privacy_pop')) {
        setCookieShow(true)
      }else {
        setCookieShow(false)
      }
    }else {
      setCookieShow(false)
    }
  }, [commonStore.market])
  return (
    <>
      <Header/>
      <div style={{ minHeight }}>
        <AliveScope>
          {
            other.includes(pathname) || pathname.includes("/morepage") ? (
              <Router key={pathname + vinkey} />
            ): <Router  />
          }
        </AliveScope>
      </div>
      {
        token && pageType !== '1' ? <Sider/> : null
      }
      {
        pathname !== '/epc/vin/detail' ? (
          <Footer/>
        ): null
      }
      {
        commonStore.market !== 1 && cookieShow ? <CookiePrivacy onCancel={setCookieShow}/> : null
      }
    </>
  )
}

const CookiePrivacy: React.FC<any> = (props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { onCancel } = props
  const [open, setOpen] = useState<boolean>(false)
  const [switchInfo, setSwitchInfo] = useState<any>({
    "xn": 1,
    "gn": 1,
    "dx": 1
  })
  const closeModal = () => {
    setOpen(true)
  }
  const acceptAll = () => {
    let _switchInfo = {
      "xn": 1,
      "gn": 1,
      "dx": 1
    }
    setSwitchInfo(_switchInfo)
    localStorage.setItem('cookie_privacy_pop', '1')
    localStorage.setItem('cookie_switch', JSON.stringify(_switchInfo))
    setOpen(false)
    if(onCancel) {
      onCancel(false)
    }
  }
  const declineAll = () => {
    let _switchInfo = {
      "xn": 0,
      "gn": 0,
      "dx": 0
    }
    setSwitchInfo(_switchInfo)
    localStorage.setItem('cookie_privacy_pop', '1')
    localStorage.setItem('cookie_switch', JSON.stringify(_switchInfo))
    setOpen(false)
    if(onCancel) {
      onCancel(false)
    }
  }
  const acceptMy = () => {
    localStorage.setItem('cookie_privacy_pop', '1')
    localStorage.setItem('cookie_switch', JSON.stringify(switchInfo))
    setOpen(false)
    if(onCancel) {
      onCancel(false)
    }
  }
  const switchChange = (checked: boolean, type: string) => {
    let _switchInfo = JSON.parse(JSON.stringify(switchInfo))
    _switchInfo[type] = checked ? 1 : 0
    setSwitchInfo(_switchInfo)
  }
  return (
    <>
      <div className={styles['privacy-wrap']}>
        <div className={styles['privacy-pop']}>{intl.formatMessage({id: '我们使用Cookie以允许我们网站的正常工作、个性化设计内容和广告、提供社交媒体功能并分析流量。我们还同社交媒体、广告和分析合作伙伴并分享有关您使用我们网站的信息'})}</div>
        <div className={styles['privacy-bottom']}>
          <div className={styles['set-btn']} onClick={() => setOpen(true)}>{intl.formatMessage({id: 'Cookie设置'})}</div>
          <div className={styles['primary-btn']} onClick={acceptAll}>{intl.formatMessage({id: '接受所有Cookie'})}</div>
        </div>
      </div>
      {
        open ? <Modal
          title=" "
          open={open}
          width={560}
          footer={null}
          onCancel={closeModal}
        >
          <div className={styles['privacy-modal']}>
            <div className={styles['privacy-title']}>{intl.formatMessage({id: '隐私偏好中心'})}</div>
            <div className={styles['privacy-content']}>{intl.formatMessage({id: '您访问任何网站时，网站都可能在您的浏览器上存储或检索信息，大多数是以Cookie的形式进行。此信息可能与您、您的偏好、您的设备相关，或者该信息被用于使网站按照您期望的方式工作。这些信息通常不会直接识别您，但它可为您提供更多个性化的Web体验。您可以选择不允许使用某些类型的Cookie。单击不同类型标题以了解更多信息并更改默认设置。但是，您应该知道，阻止某些类型的Cookie可能会影响您的网站体验和我们能够提供的服务。'})}</div>
            {/* <div className={styles['to-more']} onClick={() => navigate("/protocol/privacy")}>{intl.formatMessage({id: '更多信息'})}</div> */}
            <div className={styles['primary-btn']} onClick={acceptAll}>{intl.formatMessage({id: '全部允许'})}</div>
            <div className={styles['privacy-title']} style={{marginTop: 20, marginBottom: 20}}>{intl.formatMessage({id: '管理许可偏好'})}</div>
            <div className={styles['switch-group']}>
              <div className={styles['switch-item']}>
                <div className={styles['switch-lf']}>
                  <PlusOutlined style={{fontSize: 14, color: '#5C5C5C'}}/>
                  <span className={styles['switch-label']}>{intl.formatMessage({id: '绝对必要的Cookie'})}</span>
                </div>
                <div className={styles['switch-rt']}>
                  <div className={styles['blue-btn']} onClick={acceptAll}>{intl.formatMessage({id: '始终处于活动状态'})}</div>
                </div>
              </div>
              <div className={styles['switch-item']}>
                <div className={styles['switch-lf']}>
                  <PlusOutlined style={{fontSize: 14, color: '#5C5C5C'}}/>
                  <span className={styles['switch-label']}>{intl.formatMessage({id: '性能Cookie'})}</span>
                </div>
                <div className={styles['switch-rt']}>
                  <Switch checked={switchInfo.xn ? true : false} onChange={(checked) => switchChange(checked, 'xn')}/>
                </div>
              </div>
              <div className={styles['switch-item']}>
                <div className={styles['switch-lf']}>
                  <PlusOutlined style={{fontSize: 14, color: '#5C5C5C'}}/>
                  <span className={styles['switch-label']}>{intl.formatMessage({id: '功能Cookie'})}</span>
                </div>
                <div className={styles['switch-rt']}>
                  <Switch checked={switchInfo.gn ? true : false} onChange={(checked) => switchChange(checked, 'gn')}/>
                </div>
              </div>
              <div className={styles['switch-item']}>
                <div className={styles['switch-lf']}>
                  <PlusOutlined style={{fontSize: 14, color: '#5C5C5C'}}/>
                  <span className={styles['switch-label']}>{intl.formatMessage({id: '定向Cookie'})}</span>
                </div>
                <div className={styles['switch-rt']}>
                  <Switch checked={switchInfo.dx ? true : false} onChange={(checked) => switchChange(checked, 'dx')}/>
                </div>
              </div>
            </div>
            <div className={styles['privacy-bottom']}>
              <div className={styles['default-btn']} onClick={declineAll}>{intl.formatMessage({id: '全部拒绝'})}</div>
              <div className={styles['primary-btn']} onClick={acceptMy}>{intl.formatMessage({id: '确认我的选择'})}</div>
            </div>
          </div>
        </Modal> : null
      }
    </>
  )
}
export default Main